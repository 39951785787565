import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import { showError } from "../AppMessage";
import TableColumnCheckBox from "./TableColumnCheckBox";
import TableColumnString from "./TableColumnString";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

/*
  Data - Tipo Sumula
  Numero - Posicao

  associado - Total
*/

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      su_sumula: PropTypes.number.isRequired,
      tsu_tipoSumula: PropTypes.number.isRequired,
      tsu_nome: PropTypes.string.isRequired,
      tsu_tipoSumula_string: PropTypes.string.isRequired,
      su_data: PropTypes.string.isRequired,
      su_numero: PropTypes.number.isRequired,
    }).isRequired,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;

    this.state = {
      ...this.state,
      parameterMap: parameterMap,
      record: {
        as_associado: null,
        as_associado_descricao: null,
        as_total: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);

    this.onActivate = this.onActivate.bind(this);
    this.onNenhum = this.onNenhum.bind(this);
    this.onTodos = this.onTodos.bind(this);
    this.onSoma = this.onSoma.bind(this);
    this.onSomaGeral = this.onSomaGeral.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const parameterMap = state.parameterMap;
      const record = state.record;

      let recordArrayNew = [];

      let isu_inscricaoSumula = null;
      let isu_resultado = null;

      if (record.as_associado !== null) {
        const response = await this.fetch("/inscricaoSumulaResultado/editar/carregar", { ...parameterMap, ...record });
        const responseRecord = response.record;
        const su_tipoSumula = responseRecord.su_tipoSumula;

        isu_inscricaoSumula = responseRecord.isu_inscricaoSumula;
        isu_resultado = responseRecord.isu_resultado;

        let i = 0;
        let j = 0;

        const linhaArray = [];

        if (su_tipoSumula === 1) {
          for (i = 0; i < 4; i++) {
            const linha = {};

            for (j = 0; j < 25; j++) {
              linha["col" + (j + 1)] = isu_resultado.substring(i * 25 + j, i * 25 + j + 1) === "A";
            }

            linhaArray.push(linha);
          }
        } else if (su_tipoSumula === 2) {
          for (i = 0; i < 4; i = i + 2) {
            const linha1 = {};
            const linha2 = {};

            for (j = 0; j < 50; j = j + 2) {
              linha1["col" + (j / 2 + 1)] = isu_resultado.substring(i * 25 + j, i * 25 + j + 1) === "A";
              linha2["col" + (j / 2 + 1)] = isu_resultado.substring(i * 25 + j + 1, i * 25 + j + 2) === "A";
            }

            linhaArray.push(linha1);
            linhaArray.push(linha2);
          }
        } else if (su_tipoSumula === 3) {
          for (i = 0; i < 2; i++) {
            const linha = {};

            for (j = 0; j < 25; j++) {
              linha["col" + (j + 1)] = isu_resultado.substring(i * 25 + j, i * 25 + j + 1) === "A";
            }

            linhaArray.push(linha);
          }
        } else if (su_tipoSumula === 4) {
          for (i = 0; i < 2; i++) {
            const linha = {};

            for (j = 0; j < 25; j++) {
              linha["col" + (j + 1)] = isu_resultado.substring(i * 25 + j, i * 25 + j + 1) === "A";
            }

            linhaArray.push(linha);
          }
        } else if (su_tipoSumula === 5) {
          for (i = 0; i < 1; i++) {
            const linha = {};

            for (j = 0; j < 15; j++) {
              linha["col" + (j + 1)] = isu_resultado.substring(i * 15 + j, i * 15 + j + 1) === "A";
            }

            linhaArray.push(linha);
          }
        }

        recordArrayNew = linhaArray;
      }

      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,
            as_total: this.onSomaGeral(recordArrayNew),
            isu_inscricaoSumula: isu_inscricaoSumula,
            isu_resultado: isu_resultado,
          },
          recordArray: this.onSoma(recordArrayNew),
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onEditar() {
    try {
      const state = this.state;
      const parameterMap = state.parameterMap;
      const tsu_tipoSumula = parameterMap.tsu_tipoSumula;
      const record = state.record;
      const recordArray = state.recordArray;
      const isu_inscricaoSumula = record.isu_inscricaoSumula;

      if (isu_inscricaoSumula !== null) {
        let i = 0;
        let j = 0;
        let isu_resultado = "";

        if (tsu_tipoSumula === 1) {
          for (i = 0; i < 4; i++) {
            for (j = 0; j < 25; j++) {
              isu_resultado = isu_resultado + (recordArray[i]["col" + (j + 1)] === true ? "A" : "E");
            }
          }
        } else if (tsu_tipoSumula === 2) {
          for (i = 0; i < 4; i = i + 2) {
            for (j = 0; j < 50; j = j + 2) {
              isu_resultado = isu_resultado + (recordArray[i + 0]["col" + (j / 2 + 1)] === true ? "A" : "E");
              isu_resultado = isu_resultado + (recordArray[i + 1]["col" + (j / 2 + 1)] === true ? "A" : "E");
            }
          }
        } else if (tsu_tipoSumula === 3) {
          for (i = 0; i < 2; i++) {
            for (j = 0; j < 25; j++) {
              isu_resultado = isu_resultado + (recordArray[i]["col" + (j + 1)] === true ? "A" : "E");
            }
          }
        } else if (tsu_tipoSumula === 4) {
          for (i = 0; i < 2; i++) {
            for (j = 0; j < 25; j++) {
              isu_resultado = isu_resultado + (recordArray[i]["col" + (j + 1)] === true ? "A" : "E");
            }
          }
        } else if (tsu_tipoSumula === 5) {
          for (i = 0; i < 1; i++) {
            for (j = 0; j < 15; j++) {
              isu_resultado = isu_resultado + (recordArray[i]["col" + (j + 1)] === true ? "A" : "E");
            }
          }
        }

        await this.fetch("/inscricaoSumulaResultado/editar/alterar", {
          isu_inscricaoSumula: isu_inscricaoSumula,
          isu_resultado: isu_resultado,
        });

        this.showSnack("Resultado salvo com sucesso.");
      } else {
        throw new Error("Selecione um associado da Súmula.");
      }
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/inscricaoSumulaResultado/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  onActivate() {
    const table = this.getComponent("table");
    const tableState = table.state;
    let rowIndex = tableState.cursorRowIndex;
    let columnIndex = tableState.cursorColumnIndex;
    let column = columnIndex;

    const state = this.state;
    const recordArray = state.recordArray;
    const parameterMap = state.parameterMap;
    const tsu_tipoSumula = parameterMap.tsu_tipoSumula;

    if (rowIndex > 0) {
      if (tsu_tipoSumula !== 5) {
        switch (column) {
          case 0:
            break;
          case 1:
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            break;
          case 5:
            break;
          case 6:
            columnIndex = 0;
            break;
          case 7:
            columnIndex = columnIndex - 1;
            break;
          case 8:
            columnIndex = columnIndex - 1;
            break;
          case 9:
            columnIndex = columnIndex - 1;
            break;
          case 10:
            columnIndex = columnIndex - 1;
            break;
          case 11:
            columnIndex = columnIndex - 1;
            break;
          case 12:
            columnIndex = 0;
            break;
          case 13:
            columnIndex = columnIndex - 2;
            break;
          case 14:
            columnIndex = columnIndex - 2;
            break;
          case 15:
            columnIndex = columnIndex - 2;
            break;
          case 16:
            columnIndex = columnIndex - 2;
            break;
          case 17:
            columnIndex = columnIndex - 2;
            break;
          case 18:
            columnIndex = 0;
            break;
          case 19:
            columnIndex = columnIndex - 3;
            break;
          case 20:
            columnIndex = columnIndex - 3;
            break;
          case 21:
            columnIndex = columnIndex - 3;
            break;
          case 22:
            columnIndex = columnIndex - 3;
            break;
          case 23:
            columnIndex = columnIndex - 3;
            break;
          case 24:
            columnIndex = 0;
            break;
          case 25:
            columnIndex = columnIndex - 4;
            break;
          case 26:
            columnIndex = columnIndex - 4;
            break;
          case 27:
            columnIndex = columnIndex - 4;
            break;
          case 28:
            columnIndex = columnIndex - 4;
            break;
          case 29:
            columnIndex = columnIndex - 4;
            break;
          default:
            columnIndex = 0;
            break;
        }
      } else {
        switch (column) {
          case 0:
            break;
          case 1:
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            columnIndex = 0;
            break;
          case 5:
            columnIndex = columnIndex - 1;
            break;
          case 6:
            columnIndex = columnIndex - 1;
            break;
          case 7:
            columnIndex = columnIndex - 1;
            break;
          case 8:
            columnIndex = 0;
            break;
          case 9:
            columnIndex = columnIndex - 2;
            break;
          case 10:
            columnIndex = columnIndex - 2;
            break;
          case 11:
            columnIndex = columnIndex - 2;
            break;
          case 12:
            columnIndex = 0;
            break;
          case 13:
            columnIndex = columnIndex - 3;
            break;
          case 14:
            columnIndex = columnIndex - 3;
            break;
          case 15:
            columnIndex = columnIndex - 3;
            break;
          case 16:
            columnIndex = 0;
            break;
          case 17:
            columnIndex = columnIndex - 4;
            break;
          case 18:
            columnIndex = columnIndex - 4;
            break;
          case 19:
            columnIndex = columnIndex - 4;
            break;
          default:
            columnIndex = 0;
            break;
        }
      }

      if ("col" + columnIndex in recordArray[rowIndex - 1]) {
        recordArray[rowIndex - 1]["col" + columnIndex] = recordArray[rowIndex - 1]["col" + columnIndex] === false ? true : false;

        this.setState((state, props) => {
          return {
            ...state,
            record: {
              ...state.record,
              as_total: this.onSomaGeral(recordArray),
            },
            recordArray: this.onSoma(recordArray),
          };
        });
      }
    }
  }

  onNenhum() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;
      const parameterMap = state.parameterMap;
      const tsu_tipoSumula = parameterMap.tsu_tipoSumula;

      let i = 0;
      let j = 0;

      if (tsu_tipoSumula !== 5) {
        for (i = 0; i < recordArray.length; i++) {
          for (j = 1; j <= 25; j++) {
            recordArray[i]["col" + j] = false;
          }
        }
      } else {
        for (i = 0; i < recordArray.length; i++) {
          for (j = 1; j <= 15; j++) {
            recordArray[i]["col" + j] = false;
          }
        }
      }

      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,
            as_total: this.onSomaGeral(recordArray),
          },
          recordArray: this.onSoma(recordArray),
        };
      });
    } catch (error) {}
  }

  onTodos() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;
      const parameterMap = state.parameterMap;
      const tsu_tipoSumula = parameterMap.tsu_tipoSumula;

      let i = 0;
      let j = 0;

      if (tsu_tipoSumula !== 5) {
        for (i = 0; i < recordArray.length; i++) {
          for (j = 1; j <= 25; j++) {
            recordArray[i]["col" + j] = true;
          }
        }
      } else {
        for (i = 0; i < recordArray.length; i++) {
          for (j = 1; j <= 15; j++) {
            recordArray[i]["col" + j] = true;
          }
        }
      }

      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,
            as_total: this.onSomaGeral(recordArray),
          },
          recordArray: this.onSoma(recordArray),
        };
      });
    } catch (error) {}
  }

  onSoma(recordArray) {
    const state = this.state;
    const parameterMap = state.parameterMap;
    const tsu_tipoSumula = parameterMap.tsu_tipoSumula;

    let i = 0;
    let j = 0;

    for (i = 0; i < recordArray.length; i++) {
      let soma = 0;

      if (tsu_tipoSumula !== 5) {
        for (j = 1; j <= 25; j++) {
          soma = soma + (recordArray[i]["col" + j] === true ? 1 : 0);
        }
      } else {
        for (j = 1; j <= 15; j++) {
          soma = soma + (recordArray[i]["col" + j] === true ? 1 : 0);
        }
      }

      recordArray[i]["soma"] = soma;
    }

    return recordArray;
  }

  onSomaGeral(recordArray) {
    const state = this.state;
    const parameterMap = state.parameterMap;
    const tsu_tipoSumula = parameterMap.tsu_tipoSumula;

    let i = 0;
    let j = 0;
    let somaGeral = 0;

    for (i = 0; i < recordArray.length; i++) {
      let soma = 0;

      if (tsu_tipoSumula !== 5) {
        for (j = 1; j <= 25; j++) {
          soma = soma + (recordArray[i]["col" + j] === true ? 1 : 0);
        }
      } else {
        for (j = 1; j <= 15; j++) {
          soma = soma + (recordArray[i]["col" + j] === true ? 1 : 0);
        }
      }

      somaGeral = somaGeral + soma;
    }

    return somaGeral;
  }

  render() {
    const state = this.state;
    const parameterMap = state.parameterMap;
    const record = state.record;
    const recordArray = state.recordArray;
    const tsu_tipoSumula = parameterMap.tsu_tipoSumula;

    let columnArray = [];

    if (tsu_tipoSumula !== 5) {
      columnArray = [
        <TableColumnString name="sep0" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col1" label={tsu_tipoSumula === 2 ? "1\n2" : "1"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col2" label={tsu_tipoSumula === 2 ? "3\n4" : "2"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col3" label={tsu_tipoSumula === 2 ? "5\n6" : "3"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col4" label={tsu_tipoSumula === 2 ? "7\n8" : "4"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col5" label={tsu_tipoSumula === 2 ? "9\n10" : "5"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep1" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col6" label={tsu_tipoSumula === 2 ? "11\n12" : "6"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col7" label={tsu_tipoSumula === 2 ? "13\n14" : "7"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col8" label={tsu_tipoSumula === 2 ? "15\n16" : "8"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col9" label={tsu_tipoSumula === 2 ? "17\n18" : "9"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col10" label={tsu_tipoSumula === 2 ? "19\n20" : "10"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep2" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col11" label={tsu_tipoSumula === 2 ? "21\n22" : "11"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col12" label={tsu_tipoSumula === 2 ? "23\n24" : "12"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col13" label={tsu_tipoSumula === 2 ? "25\n26" : "13"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col14" label={tsu_tipoSumula === 2 ? "27\n28" : "14"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col15" label={tsu_tipoSumula === 2 ? "29\n30" : "15"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep3" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col16" label={tsu_tipoSumula === 2 ? "31\n32" : "16"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col17" label={tsu_tipoSumula === 2 ? "33\n34" : "17"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col18" label={tsu_tipoSumula === 2 ? "35\n36" : "18"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col19" label={tsu_tipoSumula === 2 ? "37\n38" : "19"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col20" label={tsu_tipoSumula === 2 ? "39\n40" : "20"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep4" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col21" label={tsu_tipoSumula === 2 ? "41\n42" : "21"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col22" label={tsu_tipoSumula === 2 ? "43\n44" : "22"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col23" label={tsu_tipoSumula === 2 ? "45\n46" : "23"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col24" label={tsu_tipoSumula === 2 ? "47\n48" : "24"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col25" label={tsu_tipoSumula === 2 ? "49\n50" : "25"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep5" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <Table.ColumnNumber name="soma" label="Soma" expanded={true} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
      ];
    } else {
      columnArray = [
        <TableColumnString name="sep0" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col1" label={tsu_tipoSumula === 2 ? "1\n2" : "1"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col2" label={tsu_tipoSumula === 2 ? "3\n4" : "2"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col3" label={tsu_tipoSumula === 2 ? "5\n6" : "3"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep1" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col4" label={tsu_tipoSumula === 2 ? "11\n12" : "4"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col5" label={tsu_tipoSumula === 2 ? "13\n14" : "5"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col6" label={tsu_tipoSumula === 2 ? "15\n16" : "6"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep2" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col7" label={tsu_tipoSumula === 2 ? "21\n22" : "7"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col8" label={tsu_tipoSumula === 2 ? "23\n24" : "8"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col9" label={tsu_tipoSumula === 2 ? "25\n26" : "9"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep3" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col10" label={tsu_tipoSumula === 2 ? "31\n32" : "10"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col11" label={tsu_tipoSumula === 2 ? "33\n34" : "11"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col12" label={tsu_tipoSumula === 2 ? "35\n36" : "12"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep4" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col13" label={tsu_tipoSumula === 2 ? "41\n42" : "13"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col14" label={tsu_tipoSumula === 2 ? "43\n44" : "14"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnCheckBox name="col15" label={tsu_tipoSumula === 2 ? "45\n46" : "15"} expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <TableColumnString name="sep5" label="" expanded={false} cellWidth={30} cellHeight={40} fixedCellWidth={30} fixedCellHeight={56} />,
        <Table.ColumnNumber name="soma" label="Soma" expanded={true} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
      ];
    }

    return (
      <ProgramLayout program={this} width="1100px" height="maximum">
        <Header avatar="E" caption={"Cadastro de Resultado da Súmula"} description={"Adiciona, edita e exclui os Resultados da Súmula"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="600px" expanded={false}>
            <Group name="filtro" caption="Identificação da Súmula" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.String name="tsu_tipoSumula_string" record={parameterMap} label="Tipo" disabled={true} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Date name="su_data" record={parameterMap} label="Data" disabled={true} />
                  <Field.Number name="su_numero" record={parameterMap} label="Número" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Group name="filtro" caption="Identificação do associado" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="as_associado"
                    caption="as_associado_descricao"
                    record={record}
                    url="/inscricaoSumulaResultado/referenciar/associado"
                    urlParameterMap={{
                      su_sumula: parameterMap.su_sumula,
                    }}
                    label="associado"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Number name="as_total" record={record} label="Total" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
        </ProgramRow>
        <Table
          name="table"
          caption="Resultados do associado"
          collapsable={false}
          collapsed={false}
          expanded={true}
          recordArray={recordArray}
          selectionType="none"
          selectionColumnName="bSelected"
          toolbar={
            <React.Fragment>
              <Menu.IconButton name="nenhum" icon={<CheckBoxOutlineBlankIcon />} text="Nenhum" onClick={this.onNenhum} />
              <Menu.IconButton name="todos" icon={<CheckBoxIcon />} text="Todos" onClick={this.onTodos} />
              <Menu.IconButton name="salvar" icon={<SaveIcon />} text="Salvar" onClick={this.onEditar} />
            </React.Fragment>
          }
          menu={null}
          columnArray={columnArray}
          fixedRowCount={1}
          fixedColumnCount={0}
          onActivate={this.onActivate}
        />
      </ProgramLayout>
    );
  }
}

export default Listar;
