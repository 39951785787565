import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";
import format from "date-fns/format";
import addMonths from "date-fns/addMonths";
import print from "print-js";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const dataFinal = format(new Date(), "yyyy-MM-dd");
    const dataInicial = format(addMonths(new Date(), -12), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        ha_entidade: this.context.app.state.vp_entidade,
        ha_entidade_nome: this.context.app.state.vp_entidadeNome,
        ha_habitualidade: null,
        ha_associado: null,
        ha_associado_nome: null,
        ha_evento: null,
        ha_tipoEvento: null,
        ha_dataEventoInicial: dataInicial,
        ha_dataEventoFinal: dataFinal,
        ha_incluirParticipacaoCampeonato: "S",
        ha_incluirParticipacaoCampeonato_descricao: "Sim",
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      //      if (record.ha_associado !== null ) {
      recordArrayNew = await this.getRecordArray(record);
      //      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      // const record = state.record;
      const recordArray = state.recordArray;

      //      if (record.ha_associado == null ) {
      //        showError(this, "Favor Selecione um associado");
      //        return
      //      }

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const recordArraySelecionados = recordArray.filter((record, recordIndex) => {
          return record.bSelected === true;
        });

        const response = await this.fetch("/listaRegistroHabitualidade/listar/carregarPDF", { recordList: recordArraySelecionados });
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Nenhum registro selecionado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/listaRegistroHabitualidade/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout
        program={this}
        width="1280px"
        height="maximum"
      >
        <Header
          avatar="P"
          caption={"Lista Registros para Habitualidade"}
          description={"Lista Registros para Habitualidade"}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="filtro"
              caption="Filtro de pesquisa"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.ha_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="ha_associado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="ha_associado_nome" record={record} label="associado "/>
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name='ha_dataEventoInicial' record={record} label='Data Inicial' onChange={this.onAtualizar}/>
                  <Field.Input.Date name='ha_dataEventoFinal' record={record} label='Data Final' onChange={this.onAtualizar}/>
                </Field.Range>
              </Field.List.Row>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="ha_evento"
                    caption="ha_tipoEvento"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "habitualidade",
                      flg_campo: "ha_evento",
                    }}
                    label='Evento'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="ha_incluirParticipacaoCampeonato"
                    caption="ha_incluirParticipacaoCampeonato_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "todas",
                      flg_campo: "simNao",
                    }}
                    label='Incluir Participações em Campeonatos'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de habitualidade"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="multiple"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>

                </React.Fragment>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnString name="ha_associado_nome" label="Associado" expanded={true} cellWidth={160} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="ha_localEvento" label="Local do Evento" expanded={true} cellWidth={160} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="ha_dataEvento" label="Data" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="ha_tipoEvento" label="Evento" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="ha_quantidadeMunicao" label="Qtde" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
