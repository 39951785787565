import React from 'react';
import TableCellHeaderCheckBox from './TableCellHeaderCheckBox';
import TableCellCheckBox from './TableCellCheckBox';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';

const cellRenderer = ((props) => {
  return (
    <TableCellCheckBox align='center' {...props}/>
  );
});

const fixedCellRenderer = ((props) => {
  return (
    <TableCellHeaderCheckBox align='center' {...props}/>
  );
});

const sort = null;

const search = null;

class TableColumnCheckBox extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    cellWidth: PropTypes.number.isRequired,
    cellHeight: PropTypes.number.isRequired,
    cellRenderer: PropTypes.func.isRequired,
    fixedCellWidth: PropTypes.number.isRequired,
    fixedCellHeight: PropTypes.number.isRequired,
    fixedCellRenderer: PropTypes.func.isRequired,
    sort: PropTypes.func,
    search: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    label: null,
    expanded: null,
    cellWidth: null,
    cellHeight: null,
    cellRenderer: cellRenderer,
    fixedCellWidth: null,
    fixedCellHeight: null,
    fixedCellRenderer: fixedCellRenderer,
    sort: sort,
    search: search,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  render() {
    return null;
  }

}

export default TableColumnCheckBox;
