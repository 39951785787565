import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";
import print from "print-js";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        du_entidade: this.context.app.state.vp_entidade,
        du_entidade_nome: this.context.app.state.vp_entidadeNome,
        du_associado: this.context.app.state.vp_associado,
        du_associado_nome: this.context.app.state.vp_associadoNome,
        as_ativo: "S",
        as_ativo_descricao: "Sim",
        du_dataEmissaoInicial: null,
        du_dataEmissaoFinal: null,
        du_dataVencimentoInicial: null,
        du_dataVencimentoFinal: null,
        du_valorInicial: null,
        du_valorFinal: null,
        du_saldoInicial: null,
        du_saldoFinal: null,
        du_exibirDuplicataQuitada: "N",
        du_exibirDuplicataQuitada_descricao: "Não",
      },
      recordArray: [],
      recordArrayResumo: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];
      let recordArrayResumoNew = [];

      const response = await this.fetch("/relatorioContasReceber/listar/carregar", record);
      recordArrayNew = response.recordArray;
      recordArrayResumoNew = response.recordArrayResumo;

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
          recordArrayResumo: recordArrayResumoNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/relatorioContasReceber/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecione um Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/relatorioContasReceber/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const recordArrayResumo = state.recordArrayResumo;
    const recordResumo = recordArrayResumo.length > 0 ? recordArrayResumo[0] : [];

    return (
      <ProgramLayout program={this} width="maximum" height="maximum">
        <Header avatar="P" caption={"Relatorio Contas Receber"} description={"Relatorio Contas Receber"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="as_ativo"
                    caption="as_ativo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "associado",
                      flg_campo: "as_ativo",
                    }}
                    label="Associado Ativo"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="du_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={false} />
                    <Field.Input.String name="du_associado_nome" record={record} label="associado " disabled={false} />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name="du_dataEmissaoInicial" record={record} label="Emissao Inicial" onChange={this.onAtualizar} />
                  <Field.Input.Date name="du_dataEmissaoFinal" record={record} label="Emissao Final" onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name="du_dataVencimentoInicial" record={record} label="Vencto Inicial" onChange={this.onAtualizar} />
                  <Field.Input.Date name="du_dataVencimentoFinal" record={record} label="Vencto Final" onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Number record={record} name="du_valorInicial" label="Valor Inicial" prefix="" thousandSeparator="" decimalScale={2} disabled={false} onChange={this.onAtualizar} />
                  <Field.Input.Number record={record} name="du_valorFinal" label="Valor Final" prefix="" thousandSeparator="" decimalScale={2} disabled={false} onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Number record={record} name="du_saldoInicial" label="Saldo Inicial" prefix="" thousandSeparator="" decimalScale={2} disabled={false} onChange={this.onAtualizar} />
                  <Field.Input.Number record={record} name="du_saldoFinal" label="Saldo Final" prefix="" thousandSeparator="" decimalScale={2} disabled={false} onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="du_exibirDuplicataQuitada"
                    caption="du_exibirDuplicataQuitada_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "todas",
                      flg_campo: "simNao",
                    }}
                    label="Exibe Duplicata Quitada e/ou Cancelada"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
            <Group name="filtro" caption="Resumo" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number record={recordResumo} name="totalVencida" label="Total Vencido" prefix="" thousandSeparator="." decimalScale={2} disabled={true} />
                    <Field.Input.Number record={recordResumo} name="quantidadeVencida" label="Quantidade" prefix="" thousandSeparator="." decimalScale={0} disabled={true} />
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number record={recordResumo} name="totalAvencer" label="Total a Vencer" prefix="" thousandSeparator="." decimalScale={2} disabled={true} />
                    <Field.Input.Number record={recordResumo} name="quantidadeAvencer" label="Quantidade" prefix="" thousandSeparator="." decimalScale={0} disabled={true} />
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number record={recordResumo} name="totalGeral" label="Total Geral" prefix="" thousandSeparator="." decimalScale={2} disabled={true} />
                    <Field.Input.Number record={recordResumo} name="quantidadeTotal" label="Quantidade" prefix="" thousandSeparator="." decimalScale={0} disabled={true} />
                  </Field.Range>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Relatorio Contas Receber"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="as_nome" label="Nome Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={150} fixedCellHeight={56} />,
                <Table.ColumnString name="duplicata" label="Duplicata" expanded={true} cellWidth={60} cellHeight={40} fixedCellWidth={60} fixedCellHeight={56} />,
                <Table.ColumnDate name="du_dataEmissao" label="Emissão" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnDate name="du_dataVencimento" label="Vencimento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                <Table.ColumnNumber name="saldo" label="Saldo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                <Table.ColumnString name="du_situacaoDuplicata" label="Situacão" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="du_banco" label="Banco" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnString name="du_banco_nome" label="Nome Banco" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="du_observacao" label="Observação" expanded={true} cellWidth={180} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
