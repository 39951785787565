import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      record_number: PropTypes.number,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.sx_sexo) ? false : true;
    const componentFocused = "sx_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onRemover = this.onRemover.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/sexo/editar/criar" : "/sexo/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/sexo/editar/inserir" : "/sexo/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onRemover() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/sexo/editar/remover";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/sexo/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="S" caption={recordIsNew ? "Adicionar Sexo" : "Editar Sexo"} description={"Editor de Sexo"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="sexo" caption="Sexo" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Number record={record} name="sx_sexo" label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="sx_nome" label="Nome" />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="sx_abreviacao" label="Abreviação" />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="sx_ativo"
                caption="sx_ativo_description"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "sexo",
                  flg_campo: "sx_ativo",
                }}
                label="Ativo"
                onChange={this.onAtualizar}
              />
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="remover" variant="contained" size="small" onClick={this.onRemover} disabled={recordIsNew === true}>
              Remover
            </Button>,
          ]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
