import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import EditIcon from "@mui/icons-material/Edit";
import { showError } from "../AppMessage";
import print from "print-js";
import Validation from "../../mui-v4/validation/Validation";
// import parse from "date-fns/parse";
// import isBefore from "date-fns/isBefore";
// import isAfter from "date-fns/isAfter";
import PropTypes from "prop-types";
import format from "date-fns/format";
// import addMonths from "date-fns/addMonths";
import Editar from "./Editar";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.object,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const data = format(new Date(), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        en_entidade: this.context.app.state.vp_entidade,
        en_entidade_nome: this.context.app.state.vp_entidadeNome,
        us_usuario: this.context.app.state.vp_usuario,
        vm_data: data,
        tsu_tipoSumula: null,
        tsu_tipoSumula_nome: null,
        vm_numeroInicial: null,
        vm_numeroFinal: null,
        vm_observacao: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (this.state.record.en_entidade !== null && this.state.record.tsu_tipoSumula !== null && this.state.record.vm_data !== null && this.state.record.vm_numeroInicial !== null && this.state.record.vm_numeroFinal !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const record = tableSelectionArray[0];

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;
              const editarResult = editarState.result;

              if (editarSuccess === true) {
                const state = this.state;
                const recordArray = state.recordArray;
                let recordArrayNew = [];

                if (editarResult === null) {
                  recordArrayNew = recordArray.filter((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record !== recordArrayRecord;
                  });
                } else {
                  recordArrayNew = recordArray.map((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record === recordArrayRecord ? editarResult : recordArrayRecord;
                  });
                }

                this.setState((state, props) => {
                  return {
                    ...state,
                    recordArray: recordArrayNew,
                  };
                });
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  async onImprimir() {
    try {
      const table = this.getComponent("table");
      const tableSelectionArray = table.getSelectionArray();

      if (tableSelectionArray.length === 1) {
        const record = tableSelectionArray[0];
        // const recordNumeroInicial = record.vm_numeroInicial;

        const state = this.state;
        const recordList = state.recordArray;

        const recordListFiltered = recordList.filter((recordListRecord, recordListIndex, recordList) => {
          return record.vm_numeroInicial === recordListRecord.vm_numeroInicial;
        });

        if (recordListFiltered.length > 0) {
          const parameterMap = { recordList: recordListFiltered };
          const response = await this.fetch("/sumulaAvulsa/imprimir/carregarPDF", parameterMap);
          const pdfContent = response.pdfContent;

          print({
            printable: pdfContent,
            type: "pdf",
            base64: true,
          });
        }
      }
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimirORIGINAL() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/sumulaAvulsa/imprimir/carregarPDF", recordArray);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecionar Sumula");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/sumulaAvulsa/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    //let isOpen = false;

    //if (Validation.isArray(recordArray) && !Validation.isEmpty(recordArray)) {
    //const record = recordArray[0];
    //const today = parse(
    //  record.vm_dataAtual + " " + record.vm_horaAtural,
    //  "yyyy-MM-dd HH:mm",
    //  new Date()
    //);
    //const et_abertura = parse(
    //  record.et_abertura + " " + record.et_horaAbertura,
    //  "yyyy-MM-dd HH:mm",
    //  new Date()
    //);
    //const et_encerramento = parse(
    //  record.et_encerramento + " 23:59",
    //  "yyyy-MM-dd HH:mm",
    //  new Date()
    //);
    //const isInTheFuture = isBefore(today, et_abertura);
    //const isInThePast = isAfter(today, et_encerramento);
    //isOpen = !isInTheFuture && !isInThePast;
    //}

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="S" caption={"Inscrição na Súmula"} description={"Adiciona, edita e exclui Inscrição "} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Date name="vm_data" record={record} label="Data" prefix="" onChange={this.onAtualizar} disabled={true} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tipoSumula/referenciar"}
                    urlParameterMap={() => {
                      return {};
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="tsu_tipoSumula" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="tsu_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="tsu_tipoSumula" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="tsu_tipoSumula_nome" record={record} label="Tipo da Sumula" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number name="vm_numeroInicial" record={record} label="Numero Inicial" prefix="" thousandSeparator="." decimalScale={0} onChange={this.onAtualizar} />
                    <Field.Input.Number name="vm_numeroFinal" record={record} label="Numero Final" prefix="" thousandSeparator="." decimalScale={0} onChange={this.onAtualizar} />
                  </Field.Range>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de Súmula "
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                  <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[<Table.ColumnString name="vm_numeroPosicao" label="Posição" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />, <Table.ColumnString name="vm_associado_nome" label="Nome Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />, <Table.ColumnString name="vm_observacao" label="Observações" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
