import React from "react";
// import AirbnbPropTypes from 'airbnb-prop-types';
import AppBar from "@mui/material/AppBar";
import Header from "../header/Header";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Context from "../context/Context";
import Validation from "../validation/Validation";

/*
const styles = ((theme) => {
  return {
    appBar: {
      backgroundColor: theme.palette.primary.dark,
    },
    appBarPanel: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  };
});
*/

class AppHeader extends React.Component {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    const context = this.context;
    const app = context.app;
    const appState = app.state;
    const appName = appState.name;
    const appLetter = ("" + appState.name).substring(0, 1);
    const appVersion = appState.version;
    const recordUser = appState.recordUser;
    const user = ((Validation.isDefined(recordUser) && Validation.isDefined(recordUser.se_usuario) && Validation.isString(recordUser.se_usuario)) ? recordUser.se_usuario : null);

    return (
      <AppBar
        /* className={classes.appBar} */
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.dark,
        })}
        position='static'
        {...otherProps}
      >
        <Box
          /* className={classes.appBarPanel} */
          sx={(theme) => ({
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),      
          })}
        >
          <Header avatar={appLetter} title={(user === null ? '' : user + ' - ') + appName + ' ' + appVersion}/>
        </Box>
      </AppBar>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(AppHeader);
export default AppHeader;
