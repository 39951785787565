import React from 'react';
import FieldInput from './FieldInput';
import Context from '../context/Context';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';
import TextField from '@mui/material/TextField';

class FieldInputString extends FieldInput {

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
    InputLabelProps: PropTypes.object,
  }

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    children: null,
    InputLabelProps: null,
  }

  constructor(props, context) {
    super(props, context);

    this.reference = {
      textField: null,
    };

    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.onKeyDownEnter = this.onKeyDownEnter.bind(this);
    this.render = this.render.bind(this);
  }

  focus() {
    const reference = this.reference;
    const textField = reference.textField;

    /* setTimeout is required as a fix to a problem in react-number-format.
     * See: https://github.com/s-yadav/react-number-format/issues/213
     */
    setTimeout(() => {
        textField.select();
        textField.focus();
      },
      0
    );
  }

  getPosition() {
    const reference = this.reference;
    const textField = reference.textField;
    const position = textField.getBoundingClientRect();

    return position;
  }

  async onKeyDownEnter(event) {
    if (this.props.multiline === true) {
      return;
    } else {
      super.onKeyDownEnter(event);
    }
  }

  render() {
    const {
      theme,
      classes,
      name,
      record,
      label,
      helperText: propHelperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      children,
      InputLabelProps,
      ...otherProps
    } = this.props;

    const {
      value,
      error,
      /* helperText, */
    } = this.state;

    return (
      <TextField
        size="small"

        label={label}
        value={value}
        error={error}
        helperText={null /* helperText */}
        variant='outlined'
        margin='dense'
        autoComplete='off'
        style={{
          flex: '1 1 auto',
          margin: '0px',
        }}
        InputLabelProps={{
          ...(InputLabelProps === null ? {} : InputLabelProps),
          style: {
            maxWidth: 'calc(100% - 32px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          },
        }}
        inputRef={(textField) => {
          const reference = this.reference;

          reference.textField = textField;
        }}
        onChange={(event) => {this.onChange(event);}}
        onFocus={(event) => {this.onFocus(event);}}
        onBlur={(event) => {this.onBlur(event);}}
        onKeyDown={(event) => {this.onKeyDown(event);}}
        {...otherProps}
      />
    );
  }

}

export default FieldInputString;