import React from "react";
// import AirbnbPropTypes from 'airbnb-prop-types';
import PropTypes from "prop-types";
import Program from "../program/Program";
import Context from "../context/Context";
import ProgramLayout from "../program/ProgramLayout";
import Header from "../header/Header";
import Table from "../table/Table";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';
import Button from "../button/Button";
import Validation from "../validation/Validation";

/*
const styles = ((theme) => {
  return {
    programPanel: {
    },
  };
});
*/

class FieldReferenceProgram extends Program {
  static contextType = Context;

  static propTypes = {
    state: PropTypes.object,
    onClose: PropTypes.func,
    url: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    columnArray: PropTypes.array.isRequired,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    state: null,
    onClose: null,
    url: null,
    filter: null,
    columnArray: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      programWidth: "800px",
      programHeight: "480px",
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.result = null;

    this.onOpen = this.onOpen.bind(this);
    this.getRecordList = this.getRecordList.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.render = this.render.bind(this);
  }

  onOpen() {
    super.onOpen();
    this.getRecordList();
  }

  async getRecordList() {
    try {
      // const record = this.props.filter;

      /* Rename the Refence fields */
      var urlParameterMap = this.props.urlParameterMap;
      const urlParameterMapIsFunction = Validation.isFunction(urlParameterMap);
      const urlParameterMapIsDefined = Validation.isDefined(urlParameterMap);

      urlParameterMap = urlParameterMapIsDefined ? (urlParameterMapIsFunction ? urlParameterMap() : urlParameterMap) : {};
      //urlParameterMap = (urlParameterMapIsDefined ? urlParameterMap : {});

      urlParameterMap = {
        ...urlParameterMap,
        value: this.props.parametersFieldIsFieldCode ? this.props.parametersValue : null,
        description: this.props.parametersFieldIsFieldCode ? null : this.props.parametersValue,
      };

      const record = urlParameterMap;
      /* Rename the Refence fields */

      /*
      const fetchUrl = this.props.url;
      const fetchMethod = 'POST';
      const fetchHeaders = {};
      const fetchParameters = record;
      const fetchResponse = await window.fetch(fetchUrl, {
        method: fetchMethod,
        headers: fetchHeaders,
        body: JSON.stringify(fetchParameters),
      });
      // console.log(fetchParameters);
      const fetchRecordArray = await fetchResponse.json();
      */
      const context = this.context;
      const app = context.app;
      const fetchRecordArray = await app.fetch(this.props.url, record);

      const recordArray = fetchRecordArray.recordArray;
      // console.log(recordArray);
      // const recordArrayLength = recordArray.length;
      // console.log(recordArrayLength);

      //const fetchUrl = this.props.url;
      //const fetchResponse = await fetch(fetchUrl);

      //const recordArray = await fetchResponse.json();

      recordArray.forEach((record) => {
        /* Rename the Refence fields */
        record[this.props.fieldCodeName] = record.value;
        record[this.props.fieldDescriptionName] = record.description;
        /* Rename the Refence fields */
      });

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArray,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  onSelect() {
    const recordArray = this.state.recordArray.filter((record) => {
      return record["bSelected"] === true;
    });

    if (recordArray.length === 1) {
      const record = recordArray[0];

      this.result = record;
    }

    const context = this.context;
    const programManager = context.programManager;

    programManager.close();
  }

  onCancel() {
    const context = this.context;
    const programManager = context.programManager;

    programManager.close();
  }

  /* prettier-ignore */
  render() {
    const {
      programWidth,
      programHeight,
      recordArray,
    } = this.state;

    return (
      <ProgramLayout
        program={this}
        width={programWidth}
        height={programHeight}
      >
        <Header
          avatar='S'
          caption='Selecione um registro'
          description={null}
          toolbar={null}
        />
        <Table
          name='table'
          caption='Lista de Registros'
          collapsable={false}
          collapsed={false}
          expanded={true}
          recordArray={recordArray}
          selectionType='single'
          selectionColumnName='bSelected'
          toolbar={null}
          menu={null}
          columnArray={this.props.columnArray}
          fixedRowCount={1}
          fixedColumnCount={1}
        />
        <div style={{display: 'flex', flexFlow: 'row wrap'}}>
          <div style={{flex: '1 1 auto'}}/>
          <Button name='open' variant="contained" size='small' style={{margin: '8px'}} onClick={this.onCancel}>Cancelar</Button>
          <Button name='close' variant="contained" size='small' style={{margin: '8px'}} onClick={this.onSelect}>Selecionar</Button>
        </div>
      </ProgramLayout>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(FieldReferenceProgram);
export default FieldReferenceProgram;
