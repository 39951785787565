import AppLogin from "./AppLogin";
import Validation from "../mui-v4/validation/Validation";
import React from "react";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Program from "../mui-v4/program/Program";
import Context from "../mui-v4/context/Context";
import AppMenuUsuario from "./AppMenuUsuario";
import AppMenuAssociado from "./AppMenuAssociado";

class AppMenu extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      programaList: [],
      campeonatoRecordList: [],
      etapaRecordList: [],
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.render = this.render.bind(this);
  }

  onOpen() {
    this.openDialog(
      <AppLogin
        onClose={(appLogin) => {
          const appLoginState = appLogin.state;
          const entidade = appLoginState.entidade;
          const programaList = appLoginState.programaList;
          const campeonatoList = appLoginState.campeonatoList;
          const etapaList = appLoginState.etapaList;

          const context = this.context;
          const app = context.app;

          app.setState(
            (state, props) => {
              return {
                ...state,
                ...entidade,
              };
            },
            () => {
              this.setState(
                (state, props) => {
                  return {
                    ...state,
                    programaList: programaList,
                    campeonatoRecordList: campeonatoList,
                    etapaRecordList: etapaList,
                  };
                },
                () => {
                  const appState = app.state;
                  const vp_usuario = appState.vp_usuario;
                  const vp_associado = appState.vp_associado;

                  if (Validation.isDefined(vp_associado)) {
                    if (Validation.isArray(this.state.campeonatoRecordList) && !Validation.isEmpty(this.state.campeonatoRecordList)) {
                      this.open(<AppMenuAssociado parameterMap={this.state} />);
                    } else {
                      this.open(<AppMenuUsuario parameterMap={this.state} />);
                    }
                  } else if (Validation.isDefined(vp_usuario)) {
                    this.open(<AppMenuUsuario parameterMap={this.state} />);
                  } else {
                    // return null;
                  }
                }
              );
            }
          );
        }}
      />
    );
  }

  render() {
    return null;
  }
}

export default AppMenu;
