import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import { showError } from "../AppMessage";
import print from "print-js";
import Validation from "../../mui-v4/validation/Validation";
import parse from "date-fns/parse";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import PropTypes from "prop-types";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.object,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: {
      su_campeonato: null,
      su_campeonato_nome: null,
      et_etapa: null,
      et_etapa_nome: null,
      data: null,
    },
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        isu_entidade: this.context.app.state.vp_entidade,
        isu_entidade_nome: this.context.app.state.vp_entidadeNome,
        us_usuario: this.context.app.state.vp_usuario,
        isu_campeonato: props.parameterMap.ca_campeonato,
        isu_campeonato_nome: props.parameterMap.ca_campeonato_nome,
        isu_etapa: props.parameterMap.et_etapa,
        isu_etapa_nome: props.parameterMap.et_etapa_nome,
        isu_sumula: null,
        isu_data: props.parameterMap.data,
        isu_tipoSumula: null,
        isu_tipoSumula_nome: null,
        isu_associado: null,
        isu_associado_nome: null,
        isu_numero: null,
        isu_impressa: null,
        isu_impressa_descricao: null,
        isu_ativo: null,
        vm_dataAtual: null,
        vm_horaAtual: null,
        et_abertura: null,
        et_horaAbertura: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (this.state.record.isu_entidade !== null && this.state.record.isu_campeonato !== null && this.state.record.isu_etapa !== null && this.state.record.isu_tipoSumula !== null && this.state.record.isu_data !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const recordList = state.recordArray;
      const recordSelectedList = recordList.filter((record) => {
        return record["bSelected"] === true;
      });

      if (recordSelectedList.length === 1) {
        const recordSelected = recordSelectedList[0];
        const response = await this.fetch("/inscricaoSumula/imprimir/carregarPDF", recordSelected);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      }
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimirORIGINAL() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/inscricaoSumula/imprimir/carregarPDF", recordArray);
        //        const response = await this.fetch("/inscricaoSumula/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecionar Sumula");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const state = this.state;
      const stateRecord = state.record;

      const record = {
        ...tableSelectionArray[0],
        isu_entidade: stateRecord.isu_entidade,
        isu_entidade_nome: stateRecord.isu_entidade_nome,
        isu_campeonato: stateRecord.isu_campeonato,
        isu_campeonato_nome: stateRecord.isu_campeonato_nome,
        isu_etapa: stateRecord.isu_etapa,
      };

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;

              if (editarSuccess === true) {
                this.onAtualizar();
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/inscricaoSumula/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    /*
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    let inscricaoAberta = true;
    if (recordArray.vm_dataAtual  recordArray.et_abertura ) {
        inscricaoAberta = false;
      } else {
        inscricaoAberta = true;
      }
    */
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    let isOpen = false;

    if (Validation.isArray(recordArray) && !Validation.isEmpty(recordArray)) {
      const record = recordArray[0];
      const today = parse(record.vm_dataAtual + " " + record.vm_horaAtural, "yyyy-MM-dd HH:mm", new Date());
      const et_abertura = parse(record.et_abertura + " " + record.et_horaAbertura, "yyyy-MM-dd HH:mm", new Date());
      const et_encerramento = parse(record.et_encerramento + " 23:59", "yyyy-MM-dd HH:mm", new Date());

      const isInTheFuture = isBefore(today, et_abertura);
      const isInThePast = isAfter(today, et_encerramento);

      isOpen = !isInTheFuture && !isInThePast;
    }

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="S" caption={"Inscrição na Súmula"} description={"Adiciona, edita e exclui Inscrição "} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonato/referenciar"}
                    urlParameterMap={() => {
                      return {
                        ca_entidade: this.context.app.state.vp_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="isu_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="isu_campeonato_nome" record={record} label="Campeonato " />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/etapa/referenciarEtapaAberta"}
                    urlParameterMap={() => {
                      return {
                        et_campeonato: this.state.record.isu_campeonato,
                        et_usuario: this.state.record.us_usuario,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="isu_etapa" record={record} label="Etapa" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="isu_etapa_nome" record={record} label="Nome Etapa " />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Date name="isu_data" record={record} label="Data" prefix="" onChange={this.onAtualizar} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tipoSumula/referenciarPorCampeonato"}
                    urlParameterMap={() => {
                      return {
                        tsu_campeonato: this.state.record.isu_campeonato,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="tsu_tipoSumula" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="tsu_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="isu_tipoSumula" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="isu_tipoSumula_nome" record={record} label="Tipo da Sumula" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.isu_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Nome Associado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="isu_associado" record={record} label="Associado" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="isu_associado_nome" record={record} label="Nome Associado" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Number name="isu_numero" record={record} label="Súmula" prefix="" thousandSeparator="." decimalScale={0} onChange={this.onAtualizar} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Combo
                    name="isu_impressa"
                    caption="isu_impressa_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "sumula",
                      flg_campo: "isu_impressa",
                    }}
                    label="Impressa"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de Súmula "
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  {isOpen && <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} disabled={!isOpen} />}
                  <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="isu_numeroPosicao" label="Súmula" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="isu_associado_nome" label="Nome Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="isu_impressa_descricao" label="Impressa" expanded={false} cellWidth={90} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="isu_treino_descricao" label="Treino" expanded={false} cellWidth={90} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
