import React from 'react';
import FieldInput from './FieldInput';
import Context from '../context/Context';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Validation from '../validation/Validation';

class FieldInputBoolean extends FieldInput {

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  }

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    children: null,
  }

  constructor(props, context) {
    super(props, context);

    this.reference = {
      checkbox: null,
    };

    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDownEnter = this.onKeyDownEnter.bind(this);
    this.onKeyDownEsc = this.onKeyDownEsc.bind(this);
    this.onKeyDownLeft = this.onKeyDownLeft.bind(this);
    this.onKeyDownRight = this.onKeyDownRight.bind(this);
    this.onKeyDownUp = this.onKeyDownUp.bind(this);
    this.onKeyDownDown = this.onKeyDownDown.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.parseValue = this.parseValue.bind(this);
    this.render = this.render.bind(this);
  }

  focus() {
    const reference = this.reference;
    const checkbox = reference.checkbox;

    checkbox.focus();
  }

  getPosition() {
    const reference = this.reference;
    const checkbox = reference.checkbox;
    const position = checkbox.getBoundingClientRect();

    return position;
  }

  async onBlur(event) {
  }

  async onKeyDownEnter(event) {
    this.onChange(event);
    super.onKeyDownEnter(event);
  }

  async onKeyDownEsc(event) {
    const context = this.context;
    const program = context.program;
    const programFocusLeftResult = program.focusLeft(this);
    const programFocusLeftFailed = (programFocusLeftResult === false);

    if (programFocusLeftFailed) {
      const programFocusUpResult = program.focusUp(this);
      const programFocusUpFailed = (programFocusUpResult === false);

      if (programFocusUpFailed) {
        const programManager = context.programManager;

        programManager.close();
      }
    }
  }

  onKeyDownLeft(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusLeft(this);
  }

  onKeyDownRight(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusRight(this);
  }

  onKeyDownUp(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusUp(this);
  }

  onKeyDownDown(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusDown(this);
  }

  formatValue(value) {
    const valueIsDefined = Validation.isDefined(value);
    const valueString = (valueIsDefined ? (value === true ? "true" : "false") : "null");

    return valueString;
  }

  parseValue(value) {
    const valueIsDefined = Validation.isDefined(value);
    const valueString = (valueIsDefined ? (value === "null" ? null : (value === "true" ? true : false)) : null);

    return valueString;
  }

  render() {
    const {
      theme,
      classes,
      name,
      record,
      label,
      helperText: propHelperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      children,
      ...otherProps
    } = this.props;

    const {
      value,
    } = this.state;

    const checked = {}
    const indeterminate = {}

    if (value === "null") {
      indeterminate.indeterminate = true;
      checked.checked = false;
    } else {
      checked.checked = (value === "true");
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            // checked={value === "true"}

            {...checked}
            {...indeterminate}

            inputRef={(checkbox) => {
             const reference = this.reference;

             reference.checkbox = checkbox;
           }}
           onChange={(event) => {
             const target = event.target;

             target.value = (value === "true" ? "false" : "true");

             this.onKeyDownEnter(event);
           }}
           onFocus={(event) => {this.onFocus(event);}}
           onBlur={(event) => {this.onBlur(event);}}
           onKeyDown={(event) => {
               const keyCode = event.keyCode;

               if (keyCode === 13) {
                 event.target.value = (value === "true" ? "false" : "true");
               }

               this.onKeyDown(event);
             }
           }
           {...otherProps}
        />
       }
       label={label}
     />
    );
  }

}

export default FieldInputBoolean;
