import FieldList from './FieldList';
import FieldLayout from './FieldLayout';
import FieldWrapper from './FieldWrapper';
import FieldInputDate from './FieldInputDate';
import FieldInputFormatted from './FieldInputFormatted';
import FieldInputNumber from './FieldInputNumber';
import FieldInputPassword from './FieldInputPassword';
import FieldInputString from './FieldInputString';
import FieldInputBoolean from './FieldInputBoolean';
import FieldInputCombo from './FieldInputCombo';
import FieldDate from './FieldDate'
import FieldFormatted from './FieldFormatted'
import FieldNumber from './FieldNumber'
import FieldPassword from './FieldPassword'
import FieldRange from './FieldRange';
import FieldReference from './FieldReference';
import FieldString from './FieldString';
import FieldBoolean from './FieldBoolean';
import FieldCombo from './FieldCombo';

const Field = {
  List: FieldList,
  Layout: FieldLayout,
  Wrapper: FieldWrapper,
  Input: {
    Date: FieldInputDate,
    Formatted: FieldInputFormatted,
    Number: FieldInputNumber,
    Password: FieldInputPassword,
    String: FieldInputString,
    Boolean: FieldInputBoolean,
    Combo: FieldInputCombo,
  },
  Date: FieldDate,
  Formatted: FieldFormatted,
  Number: FieldNumber,
  Password: FieldPassword,
  Range: FieldRange,
  Reference: FieldReference,
  String: FieldString,
  Boolean: FieldBoolean,
  Combo: FieldCombo,
};

export default Field;
