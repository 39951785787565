import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface NumericFormatCustomProps extends NumericFormatProps {
  ref: (instance: typeof NumericFormat<any> | null) => void;
}

const NumericFormatCustom = React.forwardRef(function NumberFormatCustom(props: NumericFormatCustomProps, ref): React.ReactElement {
  return <NumericFormat {...props} getInputRef={ref} />;
});

NumericFormatCustom.defaultProps = {
  thousandSeparator: ".",
  decimalSeparator: ",",
  allowNegative: true,
};

export default NumericFormatCustom;
