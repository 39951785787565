import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
import { showError } from "../AppMessage";
import print from "print-js";
import Validation from "../../mui-v4/validation/Validation";
import format from "date-fns/format";
import addMonths from "date-fns/addMonths";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const dataInicial = format(new Date(), "yyyy-MM-dd");
    const dataFinal = format(addMonths(new Date(), +90), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        as_entidade: this.context.app.state.vp_entidade,
        as_entidade_nome: this.context.app.state.vp_entidadeNome,
        as_associado: null,
        as_nome: null,
        as_tipoAssociado: null,
        as_tipoAssociado_descricao: null,
        as_tabelaContribuicao: null,
        as_tabelaContribuicao_descricao: null,
        as_dataValidadeCRInicial: dataInicial,
        as_dataValidadeCRFinal: dataFinal,
        as_ativo: "S",
        as_ativo_descricao: "Sim",
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getResponse = this.getResponse.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArray = [];

      if (record.as_entidade !== null) {
        const response = await this.getResponse();

        recordArray = response.recordArray;
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArray,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/listaCR/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecionar Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getResponse() {
    const state = this.state;
    const record = state.record;
    const response = await this.fetch("/listaCR/listar/carregar", record);

    return response;
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;


    return (
      <ProgramLayout
        program={this}
        width="1280px"
        height="maximum"
      >
        <Header
          avatar="P"
          caption={"Lista de Certificado Registro CR"}
          description={"Listagem de Certificado Registro - CR"}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="filtro"
              caption="Filtro de pesquisa"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.String name="as_nome" record={record} label="Nome Associado" onChange={this.onAtualizar}/>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Date name='as_dataValidadeCRInicial' record={record} label='Data Inicial' onChange={this.onAtualizar} disabled={false}/>
                    <Field.Input.Date name='as_dataValidadeCRFinal' record={record} label='Data Final' onChange={this.onAtualizar} disabled={false}/>
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tipoAssociado/referenciar"}
                    urlParameterMap={{}}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="tas_tipoAssociado" label="Tipo do Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="tas_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="as_tipoAssociado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="as_tipoAssociado_descricao" record={record} label="Tipo do Associado"/>
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="as_ativo"
                    caption="as_ativo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "associado",
                      flg_campo: "as_ativo",
                    }}
                    label='Ativo'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de associado "
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>
                </React.Fragment>
              }
              menu={null}

              columnArray={[
                (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnString name="as_nome" label="Nome" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_matricula" label="Matricula" expanded={true} cellWidth={48} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="as_CR" label="CR" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnDate name="as_validadeCR" label="Validade" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_tipoAssociado_string" label="Tipo Associado " expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_ativo_descricao" label="Ativo" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
