import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import format from "date-fns/format";

import { showError } from "../AppMessage";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const dataFinal = format(new Date(), "yyyy-MM-dd");
    // const parameterMap = props.parameterMap;

    this.state = {
      ...this.state,
      record: {
        du_duplicata: null,
        du_entidade: this.context.app.state.vp_entidade,
        du_entidade_nome: this.context.app.state.vp_entidadeNome,
        du_usuario: this.context.app.state.vp_usuario,
        du_usuario_nome: this.context.app.state.vp_usuarioNome,
        du_associado: null,
        du_associado_nome: null,
        du_dataVencimentoInicial: null,
        du_dataVencimentoFinal: null,
        du_valorInicial: null,
        du_valorFinal: null,
        du_loteCobranca: null,
        du_loteCobranca_nome: null,
        du_dataPagamento: dataFinal,
        du_numero: null,
        du_parcela: null,
      },
      recordArray: [],
      recordArray2: [],
      recordArrayTotais: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];
      let recordArray2New = [];
      let recordArrayTotaisNew = [];

      if (record.du_entidade !== null && record.du_loteCobranca !== null) {
        const response = await this.fetch("/recebimentoDuplicata/listar/carregar", record);
        recordArrayNew = response.recordArray;
        recordArray2New = response.recordArray2;
        recordArrayTotaisNew = response.recordArrayTotais;
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
          recordArray2: recordArray2New,
          recordArrayTotais: recordArrayTotaisNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const tableRecord = tableSelectionArray[0];
      const record = {
        ...tableRecord,
        du_dataPagamento: this.state.record.du_dataPagamento,
        lcob_loteCobranca: this.state.record.du_loteCobranca,
      };

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;
              // const editarResult = editarState.result;
              /*
              const editarFilter = {
                ...this.state.record,
                ...editarResult,
              };
              */

              if (editarSuccess === true) {
                this.onAtualizar();
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/recebimentoDuplicata/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length === 1) {
      const record = recordArray[0];

      return record;
    } else {
      if (recordArray.length > 0) {
        throw new Error("Foi encontrado mais de 1 registro");
      } else {
        throw new Error("Não foi possível carregar o registro");
      }
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const recordArray2 = state.recordArray2;
    const recordArrayTotais = state.recordArrayTotais;
    const recordTotais = recordArrayTotais.length > 0 ? recordArrayTotais[0] : [];

    return (
      /* prettier-ignore */
      <ProgramLayout
        program={this}
        width="maximum"
        height="maximum"
      >
        <Header
          avatar="P"
          caption={"Recebimento de Duplicata"}
          description={"Adiciona, edita e exclui Duplicata "}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="filtro"
              caption="Filtro de pesquisa"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/usuario/referenciar"}
                    urlParameterMap={() => {
                      return {
                        us_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="us_usuario" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="us_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="du_usuario" record={record} label='Usuário' prefix="" thousandSeparator="" decimalScale={0} disabled = {true}/>
                    <Field.Input.String name="du_usuario_nome" record={record} label="Nome Usuário " disabled = {true}/>
                  </Field.Reference>
                </Field.List.Row>

                <Field.List>
                  <Field.List.Row>
                    <Field.Reference
                      url={"/loteCobranca/referenciarLoteUsuarioPendente"}
                      urlParameterMap={() => {
                        return {
                          lcob_entidade: record.du_entidade,
                          lcob_usuario: record.du_usuario,
                        };
                      }}
                      onChange={this.onAtualizar}
                      columnArray={[
                        (<Table.ColumnNumber name="lcob_loteCobranca" label="Lote" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                        (<Table.ColumnString name="lcob_usuario_nome" label="Nome Usuário" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                      ]}
                      >
                      <Field.Input.Number name="du_loteCobranca" record={record} label='Lote' prefix="" thousandSeparator="" decimalScale={0}  disabled = {false}/>
                      <Field.Input.String name="du_loteCobranca_nome" record={record} label="Nome Usuário"  disabled = {false}/>
                    </Field.Reference>
                  </Field.List.Row>
                </Field.List>
                <Field.List.Row>
                  <Field.Date name="du_dataPagamento" record={record} label='Sugestão para Data do Recebimento' prefix="" onChange={this.onAtualizar}/>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="as_associado" label="Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="as_nome" label="Nome Associado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="du_associado" record={record} label='Associado' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="du_associado_nome" record={record} label="Nome Associado"/>
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Date name='du_dataVencimentoInicial' record={record} label='Vencto Inicial' onChange={this.onAtualizar} />
                    <Field.Input.Date name='du_dataVencimentoFinal' record={record} label='Vencto Final' onChange={this.onAtualizar} />
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number name="du_valorInicial" record={record} label='Valor Inicial' prefix="" thousandSeparator="." decimalScale={2} onChange={this.onAtualizar}/>
                    <Field.Input.Number name="du_valorFinal" record={record} label='Valor Final' prefix="" thousandSeparator="." decimalScale={2} onChange={this.onAtualizar}/>
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number name="du_numero" record={record} label='Dupicata' prefix="" thousandSeparator="." decimalScale={0}
                        onChange={
                          () => {
                            this.setState((state, props) => {
                              return ({
                                ...state,
                                record: {
                                  ...state.record,
                                  du_associado: null,
                                  du_associado_nome: null,
                                  du_dataVencimentoInicial: null,
                                  du_dataVencimentoFinal: null,
                                  du_valorInicial: null,
                                  du_valorFinal: null,
                                },
                              });
                            }, () => {
                              this.onAtualizar();
                            });
                          }
                        } />
                    <Field.Input.Number name="du_parcela" record={record} label='Parcela' prefix="" thousandSeparator="." decimalScale={0} onChange={this.onAtualizar}/>
                  </Field.Range>
                </Field.List.Row>
              </Field.List>
            </Group>

            <Group
              name="filtro"
              caption="Totais do Lote"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number record={recordTotais} name="quantidade" label="Quantidade" prefix="" thousandSeparator="." decimalScale={0}   disabled={true}/>
                    <Field.Input.Number record={recordTotais} name="totalDuplicata" label="Total Duplicata" prefix="" thousandSeparator="." decimalScale={2}  disabled={true}/>
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number record={recordTotais} name="totalJuros" label="Total Juros/Desc" prefix="" thousandSeparator="." decimalScale={2}  disabled={true}/>
                    <Field.Input.Number record={recordTotais} name="totalLote" label="Total Lote" prefix="" thousandSeparator="." decimalScale={2}   disabled={true}/>
                  </Field.Range>
                </Field.List.Row>
              </Field.List>
            </Group>

          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Recebimento de Duplicata"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="editar" icon={<EditIcon/>} text="Editar" onClick={this.onEditar}/>
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnString name="du_associado_nome" label="Nome Associado" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="du_numeroParcela" label="Duplicata" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="du_dataEmissao" label="Emissão" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="du_dataVencimento" label="Vencimento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="du_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnNumber name="du_saldo" label="saldo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnString name="du_situacaoDuplicata" label="Situacão" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={3}
            />
            <Table
              name="table2"
              caption="Lançamentos do Lote"
              collapsable={false}
              collapsed={false}
              expanded={false}
              recordArray={recordArray2}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={null}
              menu={null}
              columnArray={[
                (<Table.ColumnString name="du_associado_nome" label="Nome Associado" expanded={true} cellWidth={160} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="du_numeroParcela" label="Duplicata" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="mdu_dataMovimento" label="Lançamento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="mdu_dataPagamento" label="Pagamento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="mdu_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnNumber name="mdu_jurosDesconto" label="Juros/Desc" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnString name="tmdu_nome" label="Tipo Lançamento" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={3}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
