import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { showError } from "../AppMessage";
import PropTypes from "prop-types";
import print from "print-js";
import download from "downloadjs";
import Typography from "@mui/material/Typography";
// import withStyles from '@mui/styles/withStyles';

/*
const styles = ((theme) => {
  return {
    groupDescriptionText: {
      color: theme.palette.text.secondary,
      fontWeight: 'normal',
    },
  };
}); 
*/

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      et_etapa: PropTypes.number.isRequired,
      et_nome: PropTypes.string.isRequired,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;

    this.state = {
      ...this.state,
      record: {
        ...parameterMap,

        produtoServicoRecordArray: [],
        produtoServicoTotal: 0,
        produtoServicoPIXRecordArray: [],
        produtoServicoPIXTotal: 0,

        pixString: null,
        pixImage: null,
        pixPDF: null,
      },
      mode: "list", // mode = list || pix;
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.onGerarPix = this.onGerarPix.bind(this);
    this.onCopiar = this.onCopiar.bind(this);
    this.onPDF = this.onPDF.bind(this);
    this.render = this.render.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderPIX = this.renderPIX.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const props = this.props;
      const parameterMap = props.parameterMap;
      const recordArray = parameterMap.et_etapa === null ? [] : await this.getRecordArray(parameterMap);

      const produtoServicoRecordArray = recordArray.filter((record, recordIndex) => {
        return record.ps_produtoServico !== record.en_produtoServicoPIX;
      });
      const produtoServicoPIXRecordArray = recordArray.filter((record, recordIndex) => {
        return record.ps_produtoServico === record.en_produtoServicoPIX;
      });

      let produtoServicoTotal = 0;
      let produtoServicoPIXTotal = 0;

      produtoServicoRecordArray.forEach((record, recordIndex) => {
        produtoServicoTotal = produtoServicoTotal + record.vTotalProdutoServico;
      });
      produtoServicoPIXRecordArray.forEach((record, recordIndex) => {
        record.vTotalProdutoServico = record.vTotalProdutoServico * -1;

        produtoServicoPIXTotal = produtoServicoPIXTotal + record.vTotalProdutoServico;
      });

      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,

            produtoServicoRecordArray: produtoServicoRecordArray,
            produtoServicoTotal: produtoServicoTotal,
            produtoServicoPIXRecordArray: produtoServicoPIXRecordArray,
            produtoServicoPIXTotal: produtoServicoPIXTotal,
          },
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    if (this.state.mode === "list") {
      this.close();
    } else {
      this.setState(
        (state, props) => {
          return {
            ...state,
            mode: "list",
          };
        },
        () => {
          this.onAtualizar();
        }
      );
    }
  }

  async getRecordArray(parameterMap) {
    console.log("parameterMap");
    console.log(parameterMap);

    const response = await this.fetch("/produtoServicoConsultaAssociado/detalhar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  async onImprimir() {
    try {
      const props = this.props;
      const parameterMap = props.parameterMap;
      console.log("parameterMap = ");
      console.log(parameterMap);
      const response = await this.fetch("/produtoServicoConsultaAssociado/detalhar/carregarPDF", parameterMap);
      const pdfContent = response.pdfContent;

      print({
        printable: pdfContent,
        type: "pdf",
        base64: true,
        onError: (error) => {
          download("data:application/pdf;base64," + pdfContent, "portalDoAtirador_com_br.pdf", "application/pdf");
        },
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onGerarPix() {
    try {
      const props = this.props;
      const parameterMap = props.parameterMap;

      const response = await this.fetch("/produtoServicoConsultaAssociado/detalhar/gerarPIX", parameterMap);

      this.setState((state, props) => {
        return {
          ...state,
          mode: "pix",
          record: {
            ...state.record,
            pixString: response.pixString,
            pixImage: response.pixImage,
            pixPDF: response.pixPDF,
          },
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onCopiar() {
    const state = this.state;
    const record = state.record;
    const pixString = record.pixString;

    await navigator.clipboard.writeText(pixString);

    this.showSnack("Código copiado com Sucesso", 2000);
  }

  onPDF() {
    const state = this.state;
    const record = state.record;
    const pixPDF = record.pixPDF;

    print({
      printable: pixPDF,
      type: "pdf",
      base64: true,
    });
  }

  /* prettier-ignore */
  render() {
    let toolbar = null;

    if (this.state.mode === 'list') {
      toolbar = (
        <React.Fragment>
          <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>
          <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
        </React.Fragment>
      );
    } else {
      toolbar = (
        <React.Fragment>
          <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
        </React.Fragment>
      );
    }

    return (
      <ProgramLayout
        program={this}
        width="1280px"
        height="600px"
      >
        <Header
          avatar="E"
          caption={"Ficha Financeira da Etapa"}
          description={"Exibe a lista de Produto/Serviço na Etapa"}
          toolbar={toolbar}
        />
        {((this.state.mode === 'list') && (
          this.renderList()
        ))}
        {((this.state.mode === 'pix') && (
          this.renderPIX()
        ))}
      </ProgramLayout>
    );
  }

  /* prettier-ignore */
  renderList() {
    const state = this.state;
    const record = state.record;
    const produtoServicoRecordArray = record.produtoServicoRecordArray;
    const produtoServicoPIXRecordArray = record.produtoServicoPIXRecordArray;

    return (
      <React.Fragment>
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="grupoResumo"
              caption="Resumo Produto/Serviço"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Number record={record} name="produtoServicoTotal" label="Saldo" prefix="" thousandSeparator="." decimalScale={2} disabled={true}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="grupoResumo"
              caption="Resumo PIX a Confirmar"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Number record={record} name="produtoServicoPIXTotal" label="Total PIX a Confirmar" prefix="" thousandSeparator="." decimalScale={2} disabled={true}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
        </ProgramRow>
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Table
              name="table"
              caption="Produto/Serviço"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={produtoServicoRecordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={null}
              menu={null}
              columnArray={[
                (<Table.ColumnString name="ps_nome" label="Nome" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="psa_tipo" label="Tipo" expanded={false} cellWidth={50} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="qQuantidadeConsiderada" label="Qtd" expanded={false} cellWidth={50} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="psa_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="vTotalProdutoServico" label="Total" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={0}
            />
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={false}>
            <Table
              name="table2"
              caption="PIX a Confirmar"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={produtoServicoPIXRecordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <Menu.IconButton name="gerarPIX" icon={<AttachMoneyIcon/>} text="Gerar PIX de Pagamento" onClick={this.onGerarPix} disabled={(this.state.record.totalPendente === 0)}/>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnString name="ps_nome" label="Nome" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="psa_tipo" label="Tipo" expanded={false} cellWidth={50} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="qQuantidadeConsiderada" label="Qtd" expanded={false} cellWidth={50} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="psa_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="vTotalProdutoServico" label="Total" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={0}
            />
          </ProgramColumn>
        </ProgramRow>
      </React.Fragment>
    );
  }

  /* prettier-ignore */
  renderPIX() {
    /*
    const {
      classes
    } = this.props;
    */

    const state = this.state;
    const record = state.record;

    return (
      <React.Fragment>
        <ProgramRow>
          <ProgramColumn width="400px" expanded={true}>
            <Group
              name="grupoCopiaECola"
              caption="PIX Copia-e-Cola"
              expanded={true}
              collapsable={false}
              collapsed={false}
              toolbar={
                <Menu.IconButton name="copiar" icon={<FileCopyIcon/>} text="Copiar" onClick={this.onCopiar}/>
              }
              menu={null}
            >
              <Typography
                /* className={classes.groupDescriptionText} */
                variant="body2"
                /* style={{margin: '8px', marginBottom: "16px"}} */
                sx={(theme) => ({
                  margin: '8px',
                  marginBottom: "16px",
                  color: theme.palette.text.secondary,
                  fontWeight: 'normal',
                })}
              >
                Clique no campo Código PIX abaixo ou no ícone acima para copiar o código.<br/>
                <br/>
                Utilize o aplicativo do seu banco para fazer o pagamento.<br/>
              </Typography>
              <Field.List onClick={this.onCopiar}>
                <Field.List.Row>
                  <Field.String record={record} name="pixString" label="Código PIX" prefix="" disabled={true} onClick={this.onCopiar}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Group
              name="grupoQRCode"
              caption="PIX QR Code e PDF"
              expanded={true}
              collapsable={false}
              collapsed={false}
              toolbar={
                <Menu.IconButton name="pdf" icon={<PictureAsPdfIcon/>} text="Exibir PDF" onClick={this.onPDF}/>
              }
              menu={null}
            >
              <div style={{display: "flex", justifyContent: "space-evenly", flexFlow: "row wrap"}}>
                <img src={record.pixImage} alt="Imagem" style={{width: "100%", height: "100%"}}/>
              </div>
            </Group>
          </ProgramColumn>
        </ProgramRow>
      </React.Fragment>
    );
  }
}

//export default Listar;
// export default withStyles(styles, {withTheme: true})(Listar);
export default Listar;
