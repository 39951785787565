import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";
import format from "date-fns/format";
import addMonths from "date-fns/addMonths";
import print from "print-js";
import download from "downloadjs";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const dataFinal = format(new Date(), "yyyy-MM-dd");
    const dataInicial = format(addMonths(new Date(), -12), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        en_entidade: this.context.app.state.vp_entidade,
        en_entidade_nome: this.context.app.state.vp_entidadeNome,
        as_associado: this.context.app.state.vp_associado,
        as_associado_nome: this.context.app.state.vp_associadoNome,
        ha_evento: null,
        ha_tipoEvento: null,
        vm_dataEventoInicial: dataInicial,
        vm_dataEventoFinal: dataFinal,
        vm_incluirParticipacaoCampeonato: "S",
        vm_incluirParticipacaoCampeonato_descricao: "Sim",
        vm_calibre: null,        
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.as_associado !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (record.as_associado == null) {
        showError(this, "Favor Selecione um associado");
        return;
      }

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/participacaoAssociado/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
          onError: (error) => {
            download("data:application/pdf;base64," + pdfContent, "portalDoAtirador_com_br.pdf", "application/pdf");
          },
        });
      } else {
        showError(this, "Nenhum registro encontrado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/participacaoAssociado/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="P" caption={"Participação Esportiva do Associado"} description={"Lista Participaçoes Esportivas do Associado"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name="vm_dataEventoInicial" record={record} label="Data Inicial" onChange={this.onAtualizar} />
                  <Field.Input.Date name="vm_dataEventoFinal" record={record} label="Data Final" onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="ha_evento"
                    caption="ha_tipoEvento"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "habitualidade",
                      flg_campo: "ha_evento",
                    }}
                    label="Evento"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="vm_incluirParticipacaoCampeonato"
                    caption="vm_incluirParticipacaoCampeonato_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "todas",
                      flg_campo: "simNao",
                    }}
                    label="Incluir Participações em Campeonatos"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                  <Field.String name="vm_calibre" record={record} label="Calibe" onChange={this.onAtualizar}/>
              </Field.List.Row>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista Participaçoes do Associado"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnDate name="dataEvento" label="Data" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="localEvento" label="Local do Evento" expanded={true} cellWidth={160} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="tipoEvento" label="Tipo Evento" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="etapa" label="Nome da Etapa" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="mo_abreviacao" label="Modalidade" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="classificacao" label="Classif" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnString name="nomeClasse" label="Classe" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} />,
                <Table.ColumnNumber name="acertos" label="Acertos" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnNumber name="quantidadeMunicao" label="Q.Munição" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
