import React from "react";
import HeaderTypographyVariant from "./HeaderTypographyVariant";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    headerTypographyTitle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
    headerTypographyCaption: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    headerTypographyDescription: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      fontWeight: 'normal',
    },
  };
});
*/

class HeaderTypography extends React.Component {
  static Variant = HeaderTypographyVariant;

  static propTypes = {
    variant: PropTypes.oneOf(Object.values(HeaderTypographyVariant)).isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    variant: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      variant,
      children,
      ...otherProps
    } = this.props;

    var typographyClassName = null;
    var typographyVariant = null;

    switch (variant) {
      case HeaderTypographyVariant.Title:
        // typographyClassName = classes.headerTypographyTitle;
        typographyClassName = (theme) => ({
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: theme.palette.common.white,
          fontWeight: 'bold',
        })
        typographyVariant = 'h6';
        break;
      case HeaderTypographyVariant.Caption:
        // typographyClassName = classes.headerTypographyCaption;
        typographyClassName = (theme) => ({
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: theme.palette.text.secondary,
          fontWeight: 'bold',
        })
        typographyVariant = 'subtitle1';
        break;
      case HeaderTypographyVariant.Description:
        // typographyClassName = classes.headerTypographyDescription;
        typographyClassName = (theme) => ({
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: theme.palette.text.secondary,
          fontWeight: 'normal',
        })
        typographyVariant = 'body2';
        break;
      default:
        typographyClassName = null;
        typographyVariant = null;
        break;
    };

    return (
      <Typography
        /* className={typographyClassName} */ 
        sx={typographyClassName}
        variant={typographyVariant}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(HeaderTypography);
export default HeaderTypography;
