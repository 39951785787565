import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import Editar from "./Editar";
import { showError } from "../AppMessage";
import PropTypes from "prop-types";
import print from "print-js";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Typography from "@mui/material/Typography";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import withStyles from '@mui/styles/withStyles';

/*
const styles = (theme) => {
  return {
    groupDescriptionText: {
      color: theme.palette.text.secondary,
      fontWeight: "normal",
    },
  };
};
*/

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      et_etapa: PropTypes.number.isRequired,
      et_nome: PropTypes.string.isRequired,
      as_associado: PropTypes.number.isRequired,
      as_nome: PropTypes.string.isRequired,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;

    this.state = {
      ...this.state,
      record: {
        ...parameterMap,
        as_totalProdutoServico: null,

        produtoServicoRecordArray: [],
        produtoServicoTotal: 0,
        produtoServicoPIXRecordArray: [],
        produtoServicoPIXTotal: 0,

        pixString: null,
        pixImage: null,
        pixPDF: null,
      },
      mode: "detalhar", // mode = detalhar || pix;
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onAdicionar = this.onAdicionar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.onGerarPix = this.onGerarPix.bind(this);
    this.render = this.render.bind(this);
    this.renderPIX = this.renderPIX.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = record.et_etapa === null ? [] : await this.getRecordArray(record);

      const produtoServicoRecordArray = recordArray.filter((record, recordIndex) => {
        return record.ps_produtoServico !== record.en_produtoServicoPIX;
      });
      const produtoServicoPIXRecordArray = recordArray.filter((record, recordIndex) => {
        return record.ps_produtoServico === record.en_produtoServicoPIX;
      });

      let produtoServicoTotal = 0;
      let produtoServicoPIXTotal = 0;

      produtoServicoRecordArray.forEach((record, recordIndex) => {
        produtoServicoTotal = produtoServicoTotal + record.vTotalProdutoServico;
      });
      produtoServicoPIXRecordArray.forEach((record, recordIndex) => {
        record.vTotalProdutoServico = record.vTotalProdutoServico * -1;

        produtoServicoPIXTotal = produtoServicoPIXTotal + record.vTotalProdutoServico;
      });

      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,
            produtoServicoRecordArray: produtoServicoRecordArray,
            produtoServicoTotal: produtoServicoTotal,
            produtoServicoPIXRecordArray: produtoServicoPIXRecordArray,
            produtoServicoPIXTotal: produtoServicoPIXTotal,
          },
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onAdicionar() {
    const state = this.state;
    const record = state.record;

    this.openDialog(
      <Editar
        parameterMap={record}
        onClose={async (editar) => {
          try {
            const editarState = editar.state;
            const editarSuccess = editarState.success;

            if (editarSuccess === true) {
              await this.onAtualizar();
            }
          } catch (error) {
            showError(this, error);
          }
        }}
      />
    );
  }

  onFechar() {
    if (this.state.mode === "detalhar") {
      this.close();
    } else {
      this.setState(
        (state, props) => {
          return {
            ...state,
            mode: "detalhar",
          };
        },
        () => {
          this.onAtualizar();
        }
      );
    }
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/produtoServicoAssociado/detalhar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const stateRecord = state.record;

      console.log(stateRecord);

      const response = await this.fetch("/produtoServicoAssociado/imprimir/carregarPDF", stateRecord);
      const pdfContent = response.pdfContent;

      print({
        printable: pdfContent,
        type: "pdf",
        base64: true,
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onGerarPix() {
    try {
      const props = this.props;
      const parameterMap = props.parameterMap;

      const response = await this.fetch("/produtoServicoAssociado/detalhar/gerarPIX", parameterMap);

      this.setState((state, props) => {
        return {
          ...state,
          mode: "pix",
          record: {
            ...state.record,
            pixString: response.pixString,
            pixImage: response.pixImage,
            pixPDF: response.pixPDF,
          },
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  /* prettier-ignore */
  render() {
    return (
      <ProgramLayout
        program={this}
        width="1280px"
        height="600px"
      >
        <Header
          avatar="E"
          caption={"Cadastro de Produto/Serviço do Associado"}
          description={"Adiciona, edita e exclui Produto/Serviço do Associado"}
          toolbar={
            <React.Fragment>
              <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
            </React.Fragment>
          }
        />
        {((this.state.mode === 'detalhar') && (
          this.renderDetalhar()
        ))}
        {((this.state.mode === 'pix') && (
          this.renderPIX()
        ))}
      </ProgramLayout>
    );
  }

  /* prettier-ignore */
  renderDetalhar() {
    const state = this.state;
    const record = state.record;
    const produtoServicoRecordArray = record.produtoServicoRecordArray;
    const produtoServicoPIXRecordArray = record.produtoServicoPIXRecordArray;

    return (
      <React.Fragment>
        <Group
          name="filtro"
          caption="Filtro de pesquisa"
          expanded={false}
          collapsable={false}
          collapsed={false}
          toolbar={null}
          menu={null}
        >
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/etapa/referenciar"}
                urlParameterMap={{}}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="et_etapa" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="et_nome" record={record} label="Etapa" disabled={true}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/associado/referenciar"}
                urlParameterMap={{}}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_associado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_nome" record={record} label="Associado" disabled={true}/>
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
        </Group>

        <ProgramRow>
          <ProgramColumn width="400px" expanded={true}>
            <Group
              name="filtro"
              caption="Resumo Produto/Serviço"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Number record={record} name="produtoServicoTotal" label="Saldo" prefix="" thousandSeparator="." decimalScale={2} disabled={true}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Group
              name="filtro"
              caption="Resumo PIX a Confirmar"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Number record={record} name="produtoServicoPIXTotal" label="Total PIX a Confirmar" prefix="" thousandSeparator="." decimalScale={2} disabled={true}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
        </ProgramRow>

        <ProgramRow>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Produto/Serviço do Associado"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={produtoServicoRecordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>
                  <Menu.IconButton name="adicionar" icon={<AddIcon/>} text="Adicionar" onClick={this.onAdicionar}/>
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnString name="ps_nome" label="Nome" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="psa_tipo" label="Tipo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="qQuantidadeConsiderada" label="Qtd" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="psa_valor" label="Saldo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="vTotalProdutoServico" label="Total" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table2"
              caption="PIX a Confirmar"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={produtoServicoPIXRecordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="gerarPIX" icon={<AttachMoneyIcon/>} text="Gerar PIX de Pagamento" onClick={this.onGerarPix} disabled={(this.state.record.totalPendente === 0)}/>
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnString name="ps_nome" label="Nome" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="psa_tipo" label="Tipo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="qQuantidadeConsiderada" label="Qtd" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="psa_valor" label="Saldo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
                (<Table.ColumnNumber name="vTotalProdutoServico" label="Total" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </React.Fragment>
    );
  }

  /* prettier-ignore */
  renderPIX() {
    /*
    const {
      classes
    } = this.props;
    */

    const state = this.state;
    const record = state.record;

    return (
      <React.Fragment>
        <ProgramRow>
          <ProgramColumn width="400px" expanded={true}>
            <Group
              name="grupoCopiaECola"
              caption="PIX Copia-e-Cola"
              expanded={true}
              collapsable={false}
              collapsed={false}
              toolbar={
                <Menu.IconButton name="copiar" icon={<FileCopyIcon/>} text="Copiar" onClick={this.onCopiar}/>
              }
              menu={null}
            >
              <Typography
                /* className={classes.groupDescriptionText} */
                variant="body2"
                /* style={{margin: '8px', marginBottom: "16px"}} */
                sx={(theme) => ({
                  margin: '8px',
                  marginBottom: "16px",
                  color: theme.palette.text.secondary,
                  fontWeight: 'normal',
                })}
              >
                Clique no campo Código PIX abaixo ou no ícone acima para copiar o código.<br/>
                <br/>
                Utilize o aplicativo do seu banco para fazer o pagamento.<br/>
              </Typography>
              <Field.List onClick={this.onCopiar}>
                <Field.List.Row>
                  <Field.String record={record} name="pixString" label="Código PIX" prefix="" disabled={true} onClick={this.onCopiar}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Group
              name="grupoQRCode"
              caption="PIX QR Code e PDF"
              expanded={true}
              collapsable={false}
              collapsed={false}
              toolbar={
                <Menu.IconButton name="pdf" icon={<PictureAsPdfIcon/>} text="Exibir PDF" onClick={this.onPDF}/>
              }
              menu={null}
            >
              <div style={{display: "flex", justifyContent: "space-evenly", flexFlow: "row wrap"}}>
                <img src={record.pixImage} alt="Imagem" style={{width: "100%", height: "100%"}}/>
              </div>
            </Group>
          </ProgramColumn>
        </ProgramRow>
      </React.Fragment>
    );
  }
}

// export default Listar;
// export default withStyles(styles, {withTheme: true})(Listar);
export default Listar;
