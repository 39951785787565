import React from "react";
import Context from "../context/Context";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from "../validation/Validation";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import FieldLayout from "./FieldLayout";
import FieldWrapper from "./FieldWrapper";
import FieldReferenceProgram from "./FieldReferenceProgram";
import PropTypes from "prop-types";

class FieldReference extends React.Component {
  static contextType = Context;

  static propTypes = {
    url: PropTypes.string.isRequired,
    //urlParameterMap: PropTypes.object.isRequired,
    urlParameterMap: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    columnArray: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    // children: AirbnbPropTypes.nChildren(2),
  };

  static defaultProps = {
    url: null,
    urlParameterMap: null,
    columnArray: null,
    onChange: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.validating = false;

    this.validate = this.validate.bind(this);
    this.validateReference = this.validateReference.bind(this);
    this.setValue = this.setValue.bind(this);
    this.render = this.render.bind(this);
  }

  async validate(parameters) {
    if (this.validating === false) {
      this.validating = true;

      this.validateReference(parameters);
    }

    const value = parameters.value;

    if (value !== null) {
      throw new Error("Loading reference...");
    }
  }

  async validateReference(parameters) {
    const context = this.context;
    const app = context.app;
    const programManager = context.programManager;

    const props = this.props;
    const url = props.url;
    const children = props.children;
    const childrenArray = React.Children.toArray(children);

    const fieldCodeElement = childrenArray[0];
    const fieldCodeElementProps = fieldCodeElement.props;
    const fieldCodeElementPropsName = fieldCodeElementProps.name;
    const fieldCodeName = fieldCodeElementPropsName;

    const fieldDescriptionElement = childrenArray[1];
    const fieldDescriptionElementProps = fieldDescriptionElement.props;
    const fieldDescriptionElementPropsName = fieldDescriptionElementProps.name;
    const fieldDescriptionName = fieldDescriptionElementPropsName;

    const parametersField = parameters.field;
    const parametersFieldProps = parametersField.props;
    const parametersFieldName = parametersFieldProps.name;
    const parametersFieldIsFieldCode = parametersFieldName === fieldCodeName;
    const parametersValue = parameters.value;
    const parametersValueIsDefined = Validation.isDefined(parametersValue);

    if (parametersValueIsDefined !== true) {
      const value = null;
      const description = null;

      this.setValue(parameters, value, description);
    } else {
      const urlParameterMapIsFunction = Validation.isFunction(props.urlParameterMap);
      const urlParameterMapIsDefined = Validation.isDefined(props.urlParameterMap);
      const urlParameterMap = urlParameterMapIsDefined ? (urlParameterMapIsFunction ? props.urlParameterMap() : props.urlParameterMap) : {};
      const parameterMap = {
        ...urlParameterMap,
        value: parametersFieldIsFieldCode ? parametersValue : null,
        description: parametersFieldIsFieldCode ? null : parametersValue,
      };

      const response = await app.fetch(url, parameterMap);
      const recordArray = response.recordArray;
      const recordArrayLength = recordArray.length;

      if (recordArrayLength === 1) {
        const record = recordArray[0];
        const value = record.value;
        const description = record.description;

        this.setValue(parameters, value, description);
      } else {
        const filter = {};

        if (recordArrayLength > 1) {
          filter[parametersFieldName] = parametersValue;
        }

        programManager.openDialog(
          <FieldReferenceProgram
            url={this.props.url}
            urlParameterMap={this.props.urlParameterMap}
            filter={filter}
            columnArray={this.props.columnArray}
            parametersFieldName={parametersFieldName}
            parametersValue={recordArrayLength > 0 ? parametersValue : null}
            parametersFieldIsFieldCode={parametersFieldIsFieldCode}
            fieldCodeName={fieldCodeName}
            fieldDescriptionName={fieldDescriptionName}
            onClose={(program) => {
              const result = program.result;
              const resultIsDefined = Validation.isDefined(result);

              if (resultIsDefined) {
                const record = result;
                const value = record.value;
                const description = record.description;

                this.setValue(parameters, value, description);
              } else {
                this.validating = false;
              }
            }}
          />
        );
      }
    }

    /* Quick Fix : tryes to focus the field */
    try {
      parameters.field.reference.textField.select();
    } catch (error) {
      //console.log(error);
    }
  }

  setValue(parameters, value, description) {
    const context = this.context;
    const program = context.program;

    const props = this.props;
    const children = props.children;
    const childrenArray = React.Children.toArray(children);

    const fieldCodeElement = childrenArray[0];
    const fieldCodeElementProps = fieldCodeElement.props;
    const fieldCodeElementPropsName = fieldCodeElementProps.name;
    const fieldCode = program.getComponent(fieldCodeElementPropsName);
    const fieldCodeName = fieldCodeElementPropsName;
    const fieldCodeRecord = fieldCodeElementProps.record;
    const fieldCodeFormatValue = fieldCode.formatValue;

    const fieldDescriptionElement = childrenArray[1];
    const fieldDescriptionElementProps = fieldDescriptionElement.props;
    const fieldDescriptionElementPropsName = fieldDescriptionElementProps.name;
    const fieldDescription = program.getComponent(fieldDescriptionElementPropsName);
    const fieldDescriptionName = fieldDescriptionElementPropsName;
    const fieldDescriptionRecord = fieldDescriptionElementProps.record;
    const fieldDescriptionFormatValue = fieldDescription.formatValue;

    const parametersField = parameters.field;
    const parametersFieldProps = parametersField.props;
    const parametersFieldName = parametersFieldProps.name;
    const parametersFieldIsFieldCode = parametersFieldName === fieldCodeName;

    const fieldCodeValue = value;
    const fieldCodeValueString = fieldCodeFormatValue(fieldCodeValue);
    const fieldDescriptionValue = description;
    const fieldDescriptionValueString = fieldDescriptionFormatValue(fieldDescriptionValue);

    fieldCodeRecord[fieldCodeName] = fieldCodeValue;
    fieldDescriptionRecord[fieldDescriptionName] = fieldDescriptionValue;

    fieldCode.setState(
      (state, props) => {
        return {
          ...state,
          error: false,
          value: fieldCodeValueString,
        };
      },
      () => {
        if (parametersFieldIsFieldCode === true) {
          const onChange = this.props.onChange;
          const onChangeIsFunction = Validation.isFunction(onChange);

          if (onChangeIsFunction) {
            onChange({ field: this });
          }
        }

        this.validating = false;
      }
    );

    fieldDescription.setState(
      (state, props) => {
        return {
          ...state,
          error: false,
          value: fieldDescriptionValueString,
        };
      },
      () => {
        if (parametersFieldIsFieldCode === false) {
          const onChange = this.props.onChange;
          const onChangeIsFunction = Validation.isFunction(onChange);

          if (onChangeIsFunction) {
            onChange({ field: this });
          }
        }

        this.validating = false;
      }
    );

    // updated for mui5
    // this.validating = false;
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      url,
      urlParameterMap,
      columnArray,
      children,
      onChange,
      ...otherProps
    } = this.props;

    const childrenArray = React.Children.toArray(children);

    const fieldCode = childrenArray[0];
    const fieldCodeClone = React.cloneElement(fieldCode, {
      validate: this.validate,
    });

    const fieldDescription = childrenArray[1];

    const fieldDescriptionProps = fieldDescription.props;
    const fieldDescriptionDisabled = (fieldDescriptionProps.disabled === true);

    const fieldDescriptionClone = React.cloneElement(fieldDescription, {
      validate: this.validate,
      InputProps: {
        endAdornment: (
          (fieldDescriptionDisabled ? null :
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                style={{marginLeft: '-16px', left: '6px'}}
                onClick={(event) => {
                  console.log('Pending implementation');
                }}
                size="large">
                <SearchIcon/>
              </IconButton>
            </InputAdornment>
          )
        ),
      }
    });

    return (
      <FieldLayout {...otherProps}>
        <FieldWrapper variant='code'>
          {fieldCodeClone}
        </FieldWrapper>
        <FieldWrapper variant='description'>
          {fieldDescriptionClone}
        </FieldWrapper>
      </FieldLayout>
    );
  }
}

export default FieldReference;
