import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import { showError } from "../AppMessage";
import format from "date-fns/format";
import addMonths from "date-fns/addMonths";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const dataFinal = format(new Date(), "yyyy-MM-dd");
    const dataInicial = format(addMonths(new Date(), -12), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        ha_entidade: this.context.app.state.vp_entidade,
        ha_entidade_nome: this.context.app.state.vp_entidadeNome,
        ha_habitualidade: null,
        ha_associado: null,
        ha_associado_nome: null,
        ha_evento: null,
        ha_evento_descricao: null,
        ha_dataEventoInicial: dataInicial,
        ha_dataEventoFinal: dataFinal,
        vm_calibre: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onAdicionar = this.onAdicionar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.ha_associado !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onAdicionar() {
    const state = this.state;
    const stateRecord = state.record;
    const record = {
      ha_habitualidade: null,
      ha_entidade: stateRecord.ha_entidade,
      ha_entidade_nome: stateRecord.ha_entidade_nome,
    };

    this.openDialog(
      <Editar
        parameterMap={record}
        onClose={async (editar) => {
          try {
            const editarState = editar.state;
            const editarSuccess = editarState.success;
            const editarResult = editarState.result;
            const editarFilter = {
              ...this.state.record,
              ...editarResult,
            };

            if (editarSuccess === true) {
              const editarRecord = await this.getRecord(editarFilter);
              const state = this.state;
              const recordArray = state.recordArray;

              recordArray.splice(0, 0, editarRecord);

              this.setState((state, props) => {
                return {
                  ...state,
                  recordArray: recordArray,
                };
              });
            }
          } catch (error) {
            showError(this, error);
          }
        }}
      />
    );
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const record = tableSelectionArray[0];

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;
              const editarResult = editarState.result;
              const editarFilter = {
                ...this.state.record,
                ...editarResult,
              };

              if (editarSuccess === true) {
                const state = this.state;
                const recordArray = state.recordArray;
                let recordArrayNew = [];

                if (editarResult === null) {
                  recordArrayNew = recordArray.filter((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record !== recordArrayRecord;
                  });
                } else {
                  const editarRecord = await this.getRecord(editarFilter);
                  const recordNew = {
                    ...record,
                    ...editarRecord,
                  };

                  recordArrayNew = recordArray.map((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record === recordArrayRecord ? recordNew : recordArrayRecord;
                  });
                }

                this.setState((state, props) => {
                  return {
                    ...state,
                    recordArray: recordArrayNew,
                  };
                });
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/habitualidade/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const vp_entidade = this.getVP("vp_entidade");
    const entidadeDisabled = vp_entidade === 1 ? false : true;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="P" caption={"Cadastro de habitualidade"} description={"Adiciona, edita e exclui habitualidade "} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/entidade/referenciar"}
                    urlParameterMap={{}}
                    onChange={() => {
                      this.setState(
                        (state, props) => {
                          return {
                            ...state,
                            record: {
                              ...state.record,
                              ha_associado: null,
                              ha_associado_nome: null,
                            },
                          };
                        },
                        () => {
                          this.onAtualizar();
                        }
                      );
                    }}
                    columnArray={[<Table.ColumnNumber name="en_entidade" label="Entidade" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="en_nome" label="Nome Entidade" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="ha_entidade" record={record} label="Entidade" prefix="" thousandSeparator="" decimalScale={0} disabled={entidadeDisabled} />
                    <Field.Input.String name="ha_entidade_nome" record={record} label="Nome Entidade" disabled={entidadeDisabled} />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.ha_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="ha_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="ha_associado_nome" record={record} label="associado " />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name="ha_dataEventoInicial" record={record} label="Data Inicial" onChange={this.onAtualizar} />
                  <Field.Input.Date name="ha_dataEventoFinal" record={record} label="Data Final" onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List.Row>
                <Field.Number name="ha_habitualidade" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} onChange={this.onAtualizar} />
              </Field.List.Row>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="ha_evento"
                    caption="ha_evento_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "habitualidade",
                      flg_campo: "ha_evento",
                    }}
                    label="Evento"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                  <Field.String name="vm_calibre" record={record} label="Calibe" onChange={this.onAtualizar}/>
              </Field.List.Row>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de habitualidade"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="adicionar" icon={<AddIcon />} text="Adicionar" onClick={this.onAdicionar} />
                  <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="ha_associado_nome" label="Nome do associado " expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="ha_evento_descricao" label="Evento" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="ha_modalidade_nome" label="Modalidade" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="ha_quantidadeMunicao" label="Qtde" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnDate name="ha_dataEvento" label="Data" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="ha_horaEvento" label="Hora" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
