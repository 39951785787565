import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";
import format from "date-fns/format";
import print from "print-js";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };
  constructor(props, context) {
    super(props, context);

    const dataFinal = format(new Date(), "yyyy-MM-dd");
    const dataInicial = format(new Date(), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        mdu_entidade: this.context.app.state.vp_entidade,
        mdu_entidade_nome: this.context.app.state.vp_entidadeNome,
        mdu_associado: this.context.app.state.vp_associado,
        mdu_associado_nome: this.context.app.state.vp_associadoNome,
        mdu_dataMovimentoInicial: dataInicial,
        mdu_dataMovimentoFinal: dataFinal,
        mdu_loteCobranca: null,
        mdu_loteCobranca_nome: null,
        mdu_valorInicial: null,
        mdu_valorFinal: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      recordArrayNew = await this.getRecordArray(record);

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/relatorioMovimentoContasReceber/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Nenhum Lançamento foi encontrado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/relatorioMovimentoContasReceber/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const vp_entidade = this.getVP("vp_entidade");
    const entidadeDisabled = vp_entidade === 1 ? false : true;

    return (
      <ProgramLayout program={this} width="1480px" height="maximum">
        <Header avatar="P" caption={"Filtro dos Lançamentos"} description={"Filtros Lançamentos Contas a Receber"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/entidade/referenciar"}
                    urlParameterMap={{}}
                    onChange={() => {
                      this.setState(
                        (state, props) => {
                          return {
                            ...state,
                            record: {
                              ...state.record,
                              mdu_associado: null,
                              mdu_associado_nome: null,
                            },
                          };
                        },
                        () => {
                          this.onAtualizar();
                        }
                      );
                    }}
                    columnArray={[<Table.ColumnNumber name="en_entidade" label="Entidade" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="en_nome" label="Nome Entidade" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="mdu_entidade" record={record} label="Entidade" prefix="" thousandSeparator="" decimalScale={0} disabled={entidadeDisabled} />
                    <Field.Input.String name="mdu_entidade_nome" record={record} label="Nome Entidade" disabled={entidadeDisabled} />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/loteCobranca/referenciar"}
                    urlParameterMap={() => {
                      return {
                        lcob_entidade: record.mdu_entidade,
                      };
                    }}
                    onChange={() => {
                      this.setState(
                        (state, props) => {
                          return {
                            ...state,
                            record: {
                              ...state.record,
                              mdu_associado: null,
                              mdu_associado_nome: null,
                              mdu_dataMovimentoInicial: null,
                              mdu_dataMovimentoFinal: null,
                              mdu_valorInicial: null,
                              mdu_valorFinal: null,
                            },
                          };
                        },
                        () => {
                          this.onAtualizar();
                        }
                      );
                    }}
                    columnArray={[<Table.ColumnNumber name="lcob_loteCobranca" label="Lote" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="lcob_usuario_nome" label="Nome Usuário" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="mdu_loteCobranca" record={record} label="Lote" prefix="" thousandSeparator="" decimalScale={0} disabled={false} onChange={this.onAtualizar} />
                    <Field.Input.String name="mdu_loteCobranca_nome" record={record} label="Nome do Usuario" disabled={false} onChange={this.onAtualizar} />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.mdu_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="mdu_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={false} />
                    <Field.Input.String name="mdu_associado_nome" record={record} label="associado " disabled={false} />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name="mdu_dataMovimentoInicial" record={record} label="Movimento Inicial" onChange={this.onAtualizar} />
                  <Field.Input.Date name="mdu_dataMovimentoFinal" record={record} label="Movimento Final" onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
              <Field.List.Row>
                <Field.Range>
                  <Field.Number record={record} name="mdu_valorInicial" label="Valor Inicial" prefix="" thousandSeparator="" decimalScale={2} disabled={false} onChange={this.onAtualizar} />
                  <Field.Number record={record} name="mdu_valorFinal" label="Valor Final" prefix="" thousandSeparator="" decimalScale={2} disabled={false} onChange={this.onAtualizar} />
                </Field.Range>
              </Field.List.Row>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista dos Lançamentos "
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="as_nome" label="Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="du_numeroParcela" label="Duplicata" expanded={false} cellWidth={90} cellHeight={40} fixedCellWidth={90} fixedCellHeight={56} />,
                <Table.ColumnNumber name="mdu_loteCobranca" label="Lote" expanded={false} cellWidth={65} cellHeight={40} fixedCellWidth={65} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnDate name="mdu_dataVencimento" label="Vencimento" expanded={false} cellWidth={115} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                //                (<Table.ColumnDate name="mdu_dataMovimento" label="Lançamento" expanded={false} cellWidth={115} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                <Table.ColumnDate name="mdu_dataPagamento" label="Pagamento" expanded={false} cellWidth={115} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="mdu_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                <Table.ColumnString name="tmdu_nome" label="Tipo Lançamento" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="du_observacao" label="Obs Duplicata" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
