import React from "react";
import FieldInput from "./FieldInput";
import Context from "../context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from "../validation/Validation";
import TextField from "@mui/material/TextField";
//import NumberFormat from 'react-number-format';
import { NumericFormat } from "react-number-format";
import NumericFormatCustom from "./NumericFormatCustom";

class FieldNumber extends FieldInput {
  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    decimalScale: PropTypes.number.isRequired,
    thousandSeparator: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    decimalScale: null,
    thousandSeparator: null,
    prefix: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.reference = {
      textField: null,
    };

    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.parseValue = this.parseValue.bind(this);
    this.render = this.render.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  focus() {
    try {
      const reference = this.reference;
      const textField = reference.textField;

      textField.select();
      textField.focus();
    } catch {}
  }

  getPosition() {
    try {
      const reference = this.reference;
      const textField = reference.textField;
      const position = textField.getBoundingClientRect();

      return position;
    } catch {
      return null;
    }
  }

  parseValue(valueString) {
    const valueStringIsEmpty = Validation.isEmpty(valueString);

    if (valueStringIsEmpty) {
      return null;
    } else {
      const valueStringNumber = valueString.replace(".", "").replace(",", ".");
      const valueNumber = Number(valueStringNumber);

      return valueNumber;
    }
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      name,
      record,
      label,
      helperText: propHelperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      decimalScale,
      thousandSeparator,
      prefix,
      children,
      ...otherProps
    } = this.props;

    const {
      value,
      error,
      /* helperText, */
    } = this.state;

    return (
      <TextField
        size="small"

        label={label}
        value={value}
        error={error}
        helperText={null /* helperText */}
        variant='outlined'
        margin='dense'
        autoComplete='off'
        style={{
          flex: '1 1 auto',
          margin: '0px',
        }}
        InputLabelProps={{
          style: {
            maxWidth: 'calc(100% - 32px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          },
        }}
        InputProps={{
          inputComponent: NumericFormatCustom,// this.renderInput,
          inputProps: {
            ref: (textField) => {
              const reference = this.reference;

              reference.textField = textField;
            },
            onValueChange: (values) => {
              const value = (values.floatValue === undefined ? null : values.floatValue);

              this.onChange({
                target: {
                  value: value,
                },
              });
            },
            thousandSeparator: thousandSeparator,
            decimalSeparator: ',',
            decimalScale: decimalScale,
            fixedDecimalScale: true,
            prefix: prefix,
          },
        }}
        onFocus={(event) => {this.onFocus(event);}}
        onBlur={(event) => {this.onBlur(event);}}
        onKeyDown={(event) => {this.onKeyDown(event);}}
        {...otherProps}
      />
    );
  }

  /* prettier-ignore */
  renderInput(props) {
    const {
      inputRef,
      ...otherProps
    } = props;

    return (
      <NumericFormat
        {...otherProps}
        getInputRef={inputRef}
      />
    );
  }
}

export default FieldNumber;
