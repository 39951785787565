import React from 'react';
import Component from '../component/Component';
import Context from '../context/Context';
import PropTypes from 'prop-types';
import Validation from '../validation/Validation';
import MuiChip from '@mui/material/Chip';

class Chip extends Component {

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
  }

  static defaultProps = {
    name: null,
    children: null,
  }

  constructor(props, context) {
    super(props, context);

    this.reference = {
      chip: null,
      actionMap: null,
    };

    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyDownEsc = this.onKeyDownEsc.bind(this);
    this.onKeyDownArrowLeft = this.onKeyDownArrowLeft.bind(this);
    this.onKeyDownArrowRight = this.onKeyDownArrowRight.bind(this);
    this.onKeyDownArrowUp = this.onKeyDownArrowUp.bind(this);
    this.onKeyDownArrowDown = this.onKeyDownArrowDown.bind(this);
    this.render = this.render.bind(this);
  }

  focus() {
    const reference = this.reference;
    const chip = reference.chip;

    chip.focus();
  }

  getPosition() {
    const reference = this.reference;
    const chip = reference.chip;
    const chipIsDefined = Validation.isDefined(chip);

    if (chipIsDefined) {
      const position = chip.getBoundingClientRect();

      return position;
    } else {
      return null;
    }
  }

  onFocus(event) {
    const props = this.props;
    const name = props.name;
    const context = this.context;
    const program = context.program;

    program.setState((state, props) => {
      return {
        ...state,
        componentFocused: name,
      };
    }, () => {
      const onFocus = props.onFocus;
      const onFocusIsFunction = Validation.isFunction(onFocus);

      if (onFocusIsFunction) {
        onFocus(event);
      }
    });
  }

  onKeyDown(event) {
    const keyCode = event.keyCode;

    switch(keyCode) {
      case 27:
        this.onKeyDownEsc(event);
        break;
      case 37:
        this.onKeyDownArrowLeft(event);
        break;
      case 39:
        this.onKeyDownArrowRight(event);
        break;
      case 38:
        this.onKeyDownArrowUp(event);
        break;
      case 40:
        this.onKeyDownArrowDown(event);
        break;
      default:
    }
  }

  onKeyDownEsc(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    const programFocusLeft = program.focusLeft;
    const programFocusLeftFailed = (programFocusLeft(this) === false);

    if (programFocusLeftFailed) {
      const programFocusUp = program.focusUp;
      const programFocusUpFailed = (programFocusUp(this) === false);

      if (programFocusUpFailed) {
        const programManager = context.programManager;

        programManager.close();
      }
    }
  }

  onKeyDownArrowLeft(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusLeft(this);
  }

  onKeyDownArrowRight(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusRight(this);
  }

  onKeyDownArrowUp(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusUp(this);
  }

  onKeyDownArrowDown(event) {
    event.preventDefault();

    const context = this.context;
    const program = context.program;
    program.focusDown(this);
  }

  render() {
    const {
      theme,
      classes,
      name,
      children,
      ...otherProps
    } = this.props;

    return (
      <MuiChip
        ref={(chip) => {
          const reference = this.reference;

          reference.chip = chip;
        }}
        onFocus={(event) => {this.onFocus(event);}}
        onKeyDown={(event) => {this.onKeyDown(event);}}
        {...otherProps}
      />
    );
  }

}

export default Chip;
