import React from "react";
import Context from "../context/Context";
import PropTypes from "prop-types";
import Validation from "../validation/Validation";

class Program extends React.Component {
  static contextType = Context;

  static propTypes = {
    state: PropTypes.object,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    state: null,
    onClose: null,
  };

  constructor(props, context) {
    super(props, context);

    const state = props.state;

    this.state = {
      ...this.state,
      open: false,
      componentDataMap: {},
      componentFocused: null,
      ...state,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onResume = this.onResume.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getComponent = this.getComponent.bind(this);
    this.getComponentData = this.getComponentData.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.focus = this.focus.bind(this);
    this.focusLeft = this.focusLeft.bind(this);
    this.focusRight = this.focusRight.bind(this);
    this.focusUp = this.focusUp.bind(this);
    this.focusDown = this.focusDown.bind(this);
    this.getComponentPosition = this.getComponentPosition.bind(this);

    this.showSnack = this.showSnack.bind(this);
    this.fetch = this.fetch.bind(this);
    this.open = this.open.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.close = this.close.bind(this);

    this.getVP = this.getVP.bind(this);
  }

  componentDidMount() {
    const state = this.state;
    const open = state.open;

    if (!open) {
      this.onOpen();

      this.setState((state, props) => {
        return {
          ...state /* untested, but i think this should be added */,
          open: true,
        };
      });
    } else {
      this.onResume();
    }
  }

  onOpen() {
    this.focus();
  }

  onResume() {
    this.focus();
  }

  onStop() {
    this.setComponentData();
  }

  onClose() {}

  getComponent(componentName) {
    const state = this.state;
    const componentDataMap = state.componentDataMap;

    if (componentName in componentDataMap) {
      const componentData = componentDataMap[componentName];
      const component = componentData.component;

      return component;
    } else {
      return null;
    }
  }

  getComponentData(component) {
    const state = this.state;
    const componentProps = component.props;
    const componentName = componentProps.name;
    const componentDataMap = state.componentDataMap;
    const componentDataIsDefined = componentName in componentDataMap;

    if (!componentDataIsDefined) {
      const componentData = {
        component: null,
        state: {},
      };

      componentDataMap[componentName] = componentData;
    }

    const componentData = componentDataMap[componentName];

    componentData.component = component;

    return componentData;
  }

  setComponentData() {
    const state = this.state;
    const componentDataMap = state.componentDataMap;
    const componentDataList = Object.values(componentDataMap);

    componentDataList.forEach((componentData) => {
      const component = componentData.component;
      const componentState = component.state;

      componentData.state = componentState;
    });
  }

  focus() {
    const state = this.state;
    const componentFocused = state.componentFocused;
    const componentDataMap = state.componentDataMap;
    const componentFocusedIsDefined =
      componentDataMap.hasOwnProperty(componentFocused);

    if (componentFocusedIsDefined) {
      const componentData = componentDataMap[componentFocused];
      const componentDataComponent = componentData.component;
      const componentDataComponentFocus = componentDataComponent.focus;
      const componentDataComponentFocusIsFunction = Validation.isFunction(
        componentDataComponentFocus
      );

      if (componentDataComponentFocusIsFunction) {
        componentDataComponentFocus();
      }
    }
  }

  focusLeft(component) {
    const componentPosition = this.getComponentPosition(component);
    const componentPositionIsDefined = Validation.isDefined(componentPosition);

    if (componentPositionIsDefined) {
      const state = this.state;
      const componentDataMap = state.componentDataMap;
      const componentDataArray = Object.values(componentDataMap);

      var componentDataArrayInHorizontalRange = [];

      componentDataArray.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          const cy = Math.round(componentPosition.y / 4);
          const ch = Math.round(componentPosition.height / 4);
          const iy = Math.round(componentDataComponentPosition.y / 4);
          const ih = Math.round(componentDataComponentPosition.height / 4);

          if ((iy <= cy && iy + ih > cy) || (iy >= cy && iy < cy + ch)) {
            if (componentDataComponentPosition.x < componentPosition.x) {
              componentDataArrayInHorizontalRange.push(componentData);
            }
          }
        }
      });
      //const componentDataArrayInHorizontalRangeIsEmpty = Validation.isEmpty(componentDataArrayInHorizontalRange);

      /*
      if (componentDataArrayInHorizontalRangeIsEmpty) {
        componentDataArrayInHorizontalRange = componentDataArray;
      }
      */

      var componentLeft = null;
      var componentLeftPosition = null;

      componentDataArrayInHorizontalRange.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          if (componentDataComponent !== component) {
            // if (Math.abs(componentDataComponentPosition.y - componentPosition.y) < 8) {
            if (componentDataComponentPosition.x < componentPosition.x) {
              if (componentLeft === null) {
                componentLeft = componentDataComponent;
                componentLeftPosition = componentDataComponentPosition;
              } else {
                if (
                  Math.round(
                    (componentDataComponentPosition.x +
                      componentDataComponentPosition.width) /
                      4
                  ) >=
                  Math.round(
                    (componentLeftPosition.x + componentLeftPosition.width) / 4
                  )
                ) {
                  if (
                    Math.abs(
                      componentDataComponentPosition.y - componentPosition.y
                    ) <= Math.abs(componentLeftPosition.y - componentPosition.y)
                  ) {
                    componentLeft = componentDataComponent;
                    componentLeftPosition = componentDataComponentPosition;
                  }
                }
              }
            }
            // }
          }
        }
      });

      if (componentLeft !== null) {
        const componentLeftFocus = componentLeft.focus;
        const componentLeftFocusIsFunction =
          Validation.isFunction(componentLeftFocus);

        if (componentLeftFocusIsFunction) {
          componentLeftFocus();

          return true;
        }
      }
    }

    return false;
  }

  focusRight(component) {
    const componentPosition = this.getComponentPosition(component);
    const componentPositionIsDefined = Validation.isDefined(componentPosition);

    if (componentPositionIsDefined) {
      const state = this.state;
      const componentDataMap = state.componentDataMap;
      const componentDataArray = Object.values(componentDataMap);

      var componentDataArrayInHorizontalRange = [];

      componentDataArray.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          const cy = Math.round(componentPosition.y / 4);
          const ch = Math.round(componentPosition.height / 4);
          const iy = Math.round(componentDataComponentPosition.y / 4);
          const ih = Math.round(componentDataComponentPosition.height / 4);

          if ((iy <= cy && iy + ih > cy) || (iy >= cy && iy < cy + ch)) {
            if (componentDataComponentPosition.x > componentPosition.x) {
              componentDataArrayInHorizontalRange.push(componentData);
            }
          }
        }
      });
      //const componentDataArrayInHorizontalRangeIsEmpty = Validation.isEmpty(componentDataArrayInHorizontalRange);

      /*
      if (componentDataArrayInHorizontalRangeIsEmpty) {
        componentDataArrayInHorizontalRange = componentDataArray;
      }
      */

      var componentRight = null;
      var componentRightPosition = null;

      componentDataArrayInHorizontalRange.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          if (componentDataComponent !== component) {
            // if (Math.abs(componentDataComponentPosition.y - componentPosition.y) < 8) {
            if (componentDataComponentPosition.x > componentPosition.x) {
              if (componentRight === null) {
                componentRight = componentDataComponent;
                componentRightPosition = componentDataComponentPosition;
              } else {
                if (
                  Math.round(componentDataComponentPosition.x / 4) <=
                  Math.round(componentRightPosition.x / 4)
                ) {
                  if (
                    Math.abs(
                      componentDataComponentPosition.y - componentPosition.y
                    ) >=
                    Math.abs(componentRightPosition.y - componentPosition.y)
                  ) {
                    componentRight = componentDataComponent;
                    componentRightPosition = componentDataComponentPosition;
                  }
                }
              }
            }
            // }
          }
        }
      });

      if (componentRight !== null) {
        const componentRightFocus = componentRight.focus;
        const componentRightFocusIsFunction =
          Validation.isFunction(componentRightFocus);

        if (componentRightFocusIsFunction) {
          componentRightFocus();

          return true;
        }
      }
    }

    return false;
  }

  focusUp(component) {
    const componentPosition = this.getComponentPosition(component);
    const componentPositionIsDefined = Validation.isDefined(componentPosition);

    if (componentPositionIsDefined) {
      const state = this.state;
      const componentDataMap = state.componentDataMap;
      const componentDataArray = Object.values(componentDataMap);

      var componentDataArrayInVerticalRange = [];

      componentDataArray.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          const cx = Math.round(componentPosition.x / 4);
          const cw = Math.round(componentPosition.width / 4);
          const ix = Math.round(componentDataComponentPosition.x / 4);
          const iw = Math.round(componentDataComponentPosition.width / 4);

          if ((ix <= cx && ix + iw > cx) || (ix >= cx && ix < cx + cw)) {
            //if (componentDataComponentPosition.y < componentPosition.y) {
            componentDataArrayInVerticalRange.push(componentData);
            //}
          }
        }
      });

      // const componentDataArrayInVerticalRangeIsEmpty = Validation.isEmpty(componentDataArrayInVerticalRange);

      /*
      if (componentDataArrayInVerticalRangeIsEmpty) {
        componentDataArrayInVerticalRange = componentDataArray;
      }
      */

      var componentTop = null;
      var componentTopPosition = null;

      componentDataArrayInVerticalRange.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          if (componentDataComponent !== component) {
            if (componentDataComponentPosition.y < componentPosition.y) {
              if (componentTop === null) {
                componentTop = componentDataComponent;
                componentTopPosition = componentDataComponentPosition;
              } else {
                if (
                  Math.round(componentDataComponentPosition.y / 4) >
                  Math.round(componentTopPosition.y / 4)
                ) {
                  componentTop = componentDataComponent;
                  componentTopPosition = componentDataComponentPosition;
                } else {
                  if (
                    Math.round(componentDataComponentPosition.y / 4) ===
                    Math.round(componentTopPosition.y / 4)
                  ) {
                    if (
                      Math.abs(
                        componentDataComponentPosition.x - componentPosition.x
                      ) <=
                      Math.abs(componentTopPosition.x - componentPosition.x)
                    ) {
                      componentTop = componentDataComponent;
                      componentTopPosition = componentDataComponentPosition;
                    }
                  }
                }
              }
            }
          }
        }
      });

      if (componentTop !== null) {
        const componentTopFocus = componentTop.focus;
        const componentTopFocusIsFunction =
          Validation.isFunction(componentTopFocus);

        if (componentTopFocusIsFunction) {
          componentTopFocus();

          return true;
        }
      }
    }

    return false;
  }

  focusDown(component) {
    const componentPosition = this.getComponentPosition(component);
    const componentPositionIsDefined = Validation.isDefined(componentPosition);

    if (componentPositionIsDefined) {
      const state = this.state;
      const componentDataMap = state.componentDataMap;
      const componentDataArray = Object.values(componentDataMap);

      var componentDataArrayInVerticalRange = [];

      componentDataArray.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          const cx = Math.round(componentPosition.x / 4);
          const cw = Math.round(componentPosition.width / 4);
          const ix = Math.round(componentDataComponentPosition.x / 4);
          const iw = Math.round(componentDataComponentPosition.width / 4);

          if ((ix <= cx && ix + iw > cx) || (ix >= cx && ix < cx + cw)) {
            componentDataArrayInVerticalRange.push(componentData);
          }
        }
      });

      // const componentDataArrayInVerticalRangeIsEmpty = Validation.isEmpty(componentDataArrayInVerticalRange);

      /*
      if (componentDataArrayInVerticalRangeIsEmpty) {
        componentDataArrayInVerticalRange = componentDataArray;
      }
      */

      var componentBottom = null;
      var componentBottomPosition = null;

      componentDataArrayInVerticalRange.forEach((componentData) => {
        const componentDataComponent = componentData.component;
        const componentDataComponentPosition = this.getComponentPosition(
          componentDataComponent
        );
        const componentDataComponentPositionIsDefined = Validation.isDefined(
          componentDataComponentPosition
        );

        if (componentDataComponentPositionIsDefined) {
          if (componentDataComponent !== component) {
            if (componentDataComponentPosition.y > componentPosition.y) {
              if (componentBottom === null) {
                componentBottom = componentDataComponent;
                componentBottomPosition = componentDataComponentPosition;
              } else {
                if (
                  Math.round(componentDataComponentPosition.y / 4) <
                  Math.round(componentBottomPosition.y / 4)
                ) {
                  componentBottom = componentDataComponent;
                  componentBottomPosition = componentDataComponentPosition;
                } else {
                  if (
                    Math.round(componentDataComponentPosition.y / 4) ===
                    Math.round(componentBottomPosition.y / 4)
                  ) {
                    if (
                      Math.abs(
                        componentDataComponentPosition.x - componentPosition.x
                      ) <=
                      Math.abs(componentBottomPosition.x - componentPosition.x)
                    ) {
                      componentBottom = componentDataComponent;
                      componentBottomPosition = componentDataComponentPosition;
                    }
                  }
                }
              }
            }
          }
        }
      });

      if (componentBottom !== null) {
        const componentBottomFocus = componentBottom.focus;
        const componentBottomFocusIsFunction =
          Validation.isFunction(componentBottomFocus);

        if (componentBottomFocusIsFunction) {
          componentBottomFocus();

          return true;
        }
      }
    }

    return false;
  }

  getComponentPosition(component) {
    const componentGetPosition = component.getPosition;
    const componentGetPositionIsFunction =
      Validation.isFunction(componentGetPosition);

    if (componentGetPositionIsFunction) {
      const componentPosition = componentGetPosition();
      const componentPositionIsObject = Validation.isObject(componentPosition);

      if (componentPositionIsObject) {
        const componentPositionXIsDefined = "x" in componentPosition;
        const componentPositionYIsDefined = "y" in componentPosition;
        const componentPositionIsDefined =
          componentPositionXIsDefined && componentPositionYIsDefined;

        if (componentPositionIsDefined) {
          return componentPosition;
        }
      }
    }

    return null;
  }

  showSnack(message, duration = 3000) {
    const context = this.context;
    const app = context.app;
    const parameters = {
      autoHideDuration: duration,
    };

    app.showSnack(message, parameters);
  }

  async fetch(servlet, parameters) {
    const context = this.context;
    const app = context.app;
    const response = await app.fetch(servlet, parameters);

    return response;
  }

  open(program) {
    const context = this.context;
    const programManager = context.programManager;

    programManager.open(program);
  }

  openDialog(program) {
    const context = this.context;
    const programManager = context.programManager;

    programManager.openDialog(program);
  }

  close() {
    const context = this.context;
    const programManager = context.programManager;

    programManager.close();
  }

  getVP(parametro) {
    const context = this.context;
    const app = context.app;
    const appState = app.state;
    const vp_parametro = appState[parametro];

    return vp_parametro;
  }
}

export default Program;
