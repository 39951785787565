import React from "react";
import Program from "../../../mui-v4/program/Program";
import Context from "../../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../../mui-v4/program/ProgramLayout";
import Header from "../../../mui-v4/header/Header";
import Menu from "../../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../../mui-v4/group/Group";
import Field from "../../../mui-v4/field/Field";
import Table from "../../../mui-v4/table/Table";
import { showError } from "../../AppMessage";
import ButtonBar from "../../../mui-v4/button/ButtonBar";
import Button from "../../../mui-v4/button/Button";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const componentFocused = "ca_campeonato";

    this.state = {
      ...this.state,
      record: {
        us_usuario: this.context.app.state.vp_usuario,
        isu_campeonato: null,
        isu_campeonato_nome: null,
        et_etapa: null,
        et_etapa_nome: null,
      },
      recordList: [],
      recordArray: [],
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onFechar = this.onFechar.bind(this);
    this.onAplicar = this.onAplicar.bind(this);
    this.render = this.render.bind(this);
  }

  onFechar() {
    this.close();
  }

  async onAplicar() {
    try {
      const state = this.state;
      const record = state.record;

      await this.fetch("/resultado/calcular/aplicar", record);

      this.showSnack("Classificação encerrada com Sucesso", 3000);
      this.close();
    } catch (error) {
      showError(this, error);
    }
  }

  render() {
    const state = this.state;
    const record = state.record;

    return (
      <ProgramLayout program={this} width="640px" height="minimum">
        <Header avatar="M" caption={"Calcular Classificação"} description={"Calcula a Classificação dos Atiradores"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="filtro" caption="Configuração" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/campeonato/referenciar"}
                urlParameterMap={() => {
                  return {
                    ca_entidade: this.context.app.state.vp_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="ca_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="ca_campeonato_nome" record={record} label="Campeonato " />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/etapa/referenciarEtapaAberta"}
                urlParameterMap={() => {
                  return {
                    et_campeonato: this.state.record.ca_campeonato,
                    et_usuario: this.state.record.us_usuario,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="et_etapa" record={record} label="Etapa" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="et_etapa_nome" record={record} label="Nome Etapa " />
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="aplicar" variant="contained" size="small" onClick={this.onAplicar}>
              Aplicar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Listar;
