import React from 'react';
import PropTypes from 'prop-types';
import FieldInputFormattedVariant from './FieldInputFormattedVariant';
// import AirbnbPropTypes from 'airbnb-prop-types';
import FieldLayout from './FieldLayout';
import FieldWrapper from './FieldWrapper';
import FieldInputFormatted from './FieldInputFormatted';

class FieldFormatted extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(FieldInputFormattedVariant)).isRequired,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  }

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    variant: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    children: null,
  }

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      name,
      record,
      label,
      variant,
      helperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      children,
      ...otherProps
    } = this.props;

    return (
      <FieldLayout {...otherProps}>
        <FieldWrapper variant='single'>
          <FieldInputFormatted
            name={name}
            record={record}
            label={label}
            variant={variant}
            helperText={helperText}
            required={required}
            validate={validate}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </FieldWrapper>
      </FieldLayout>
    );
  }

}

export default FieldFormatted;
