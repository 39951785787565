import React from "react";
import HeaderPanelPosition from "./HeaderPanelPosition";
import PropTypes from "prop-types";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    headerPanelLeft: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    headerPanelCenter: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      padding: theme.spacing(1),
      overflow: 'hidden',
    },
    headerPanelRight: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  };
});
*/

class HeaderPanel extends React.Component {
  static Position = HeaderPanelPosition;

  static propTypes = {
    position: PropTypes.oneOf(Object.values(HeaderPanelPosition)).isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    position: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      position,
      children,
      ...otherProps
    } = this.props;

    var panelClassName = null;

    switch (position) {
      case HeaderPanelPosition.Left:
        // panelClassName = classes.headerPanelLeft;
        panelClassName = (theme) => ({
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          padding: theme.spacing(1),
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),    
        })
        break;
      case HeaderPanelPosition.Center:
        // panelClassName = classes.headerPanelCenter;
        panelClassName = (theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          padding: theme.spacing(1),
          overflow: 'hidden',    
        })
        break;
      case HeaderPanelPosition.Right:
        // panelClassName = classes.headerPanelRight;
        panelClassName = (theme) => ({
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          padding: theme.spacing(1),
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),    
        })
        break;
      default:
        panelClassName = null;
        break;
    };

    return (
      <Box
        /* className={panelClassName} */
        sx={panelClassName}
        {...otherProps}
      >
        {children}
      </Box>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(HeaderPanel);
export default HeaderPanel;
