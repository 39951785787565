import React from "react";
import PropTypes from "prop-types";
// import classNames from 'classnames';
import MuiTableCell from "@mui/material/TableCell";
// import withStyles from '@mui/styles/withStyles';
// import Box from "@mui/material/Box";

/*
const styles = (theme) => {
  return {
    tableCell: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2) + 1,
      border: "2px solid " + theme.palette.common.white,
      borderBottom: "1px solid " + theme.palette.grey[300],
    },
    tableCellHeader: {
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      fontSize: "0.75rem",
    },
    tableCellCursorRow: {
      backgroundColor: theme.palette.grey[100],
      border: "2px solid " + theme.palette.grey[100],
      borderBottom: "1px solid " + theme.palette.grey[300],
      paddingBottom: theme.spacing(2) + 1,
    },
    tableCellCursor: {
      backgroundColor: theme.palette.grey[200],
      border: "2px solid " + theme.palette.grey[200],
      borderBottom: "2px solid " + theme.palette.grey[500],
    },
    tableCellCursorFocused: {
      backgroundColor: theme.palette.grey[300],
      border: "2px solid " + theme.palette.grey[300],
      borderBottom: "2px solid " + theme.palette.primary.main,
    },
    tableCellCursorEnabled: {
      backgroundColor: theme.palette.grey[300],
      border: "2px solid " + theme.palette.primary.main,
      borderRadius: "4px",
    },
  };
};
*/

class TableCell extends React.Component {
  static propTypes = {
    parent: PropTypes.any.isRequired, // Reference to the parent Grid (instance)
    isVisible: PropTypes.any.isRequired, // This cell is visible within the grid (eg it is not an overscanned cell)
    isScrolling: PropTypes.any.isRequired, // The Grid is currently being scrolled
    rowIndex: PropTypes.any.isRequired, // Vertical (row) index of cell
    columnIndex: PropTypes.any.isRequired, // Horizontal (column) index of cell
    // key: PropTypes.any, // Unique key within array of cells
    style: PropTypes.any.isRequired, // Style object to be applied to cell (to position it). This must be passed through to the rendered cell element
    table: PropTypes.object.isRequired, // The Table object
    keyValue: PropTypes.any.isRequired, // This is the 'key' value, as the 'key' prop is not passed thru
    onClick: PropTypes.func.isRequired, // The onClick event
    children: PropTypes.node,
  };

  static defaultProps = {
    parent: null,
    isVisible: null,
    isScrolling: null,
    rowIndex: null,
    columnIndex: null,
    // key: null,
    style: null,
    table: null,
    keyValue: null,
    onClick: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      parent,
      isVisible,
      isScrolling,
      rowIndex,
      columnIndex,
      // key,
      style,
      table,
      keyValue,
      onClick,
      children,
      ...otherProps
    } = this.props;

    const tableProps = table.props;
    const tableState = table.state;
    const tableFocused = tableState.focused;
    const tableEnabled = tableState.enabled;
    const tableFixedRowCount = tableProps.fixedRowCount;
    const tableCursorRowIndex = tableState.cursorRowIndex;
    const tableCursorColumnIndex = tableState.cursorColumnIndex;
    const cellIsHeader = rowIndex < tableFixedRowCount;
    const cellIsCursorRow = rowIndex === tableCursorRowIndex;
    const cellIsCursor = cellIsCursorRow && columnIndex === tableCursorColumnIndex;
    const cellIsCursorFocused = cellIsCursor && tableFocused;
    const cellIsCursorEnabled = cellIsCursor && tableEnabled;

    const cellVariant = cellIsHeader ? "head" : "body";
    /*
    const cellClassName = classNames(
      classes.tableCell,
      {[classes.tableCellHeader]:        cellIsHeader},
      {[classes.tableCellCursorRow]:     cellIsCursorRow},
      {[classes.tableCellCursor]:        cellIsCursor},
      {[classes.tableCellCursorFocused]: cellIsCursorFocused},
      {[classes.tableCellCursorEnabled]: cellIsCursorEnabled},
    );
    */
    const cellClassName = (theme) => ({
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2) + 1,
      border: "2px solid " + theme.palette.common.white,
      borderBottom: "1px solid " + theme.palette.grey[300],
      ...(!cellIsHeader
        ? {}
        : {
            color: theme.palette.text.secondary,
            fontWeight: "bold",
            fontSize: "0.75rem",
          }),
      ...(!cellIsCursorRow
        ? {}
        : {
            backgroundColor: theme.palette.grey[100],
            border: "2px solid " + theme.palette.grey[100],
            borderBottom: "1px solid " + theme.palette.grey[300],
            paddingBottom: theme.spacing(2) + 1,
          }),
      ...(!cellIsCursor
        ? {}
        : {
            backgroundColor: theme.palette.grey[200],
            border: "2px solid " + theme.palette.grey[200],
            borderBottom: "2px solid " + theme.palette.grey[500],
          }),
      ...(!cellIsCursorFocused
        ? {}
        : {
            backgroundColor: theme.palette.grey[300],
            border: "2px solid " + theme.palette.grey[300],
            borderBottom: "2px solid " + theme.palette.primary.main,
          }),
      ...(!cellIsCursorEnabled
        ? {}
        : {
            backgroundColor: theme.palette.grey[300],
            border: "2px solid " + theme.palette.primary.main,
            borderRadius: "4px",
          }),
    });

    return (
      <MuiTableCell
        component="div"
        key={keyValue}
        style={style}
        variant={cellVariant}
        /* className={cellClassName} */
        sx={cellClassName}
        onClick={onClick}
        // onMouseDown={onClick}
        // onMouseUp={onClick}
        {...otherProps}
      >
        {children}
      </MuiTableCell>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(TableCell);
export default TableCell;
