import React from 'react';
import GroupLayout from './GroupLayout';
import GroupHeader from './GroupHeader';
import GroupBody from './GroupBody';
import Context from '../context/Context';
import Component from '../component/Component';
import PropTypes from 'prop-types';
import Validation from '../validation/Validation';
import Header from '../header/Header';
import IconButton from '@mui/material/IconButton';
import Menu from '../menu/Menu';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import MoreVert from '@mui/icons-material/MoreVert';

class Group extends Component {

  static Layout = GroupLayout;
  static Header = GroupHeader;
  static Body = GroupBody;

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    avatar: PropTypes.node,
    title: PropTypes.node,
    caption: PropTypes.node,
    description: PropTypes.node,
    toolbar: PropTypes.node,
    menu: PropTypes.node,
    collapsable: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    children: PropTypes.node,
  }

  static defaultProps = {
    name: null,
    avatar: null,
    title: null,
    caption: null,
    description: null,
    toolbar: null,
    menu: null,
    collapsable: null,
    collapsed: null,
    expanded: null,
    children: null,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      collapsed: props.collapsed,
      ...this.state,
    };

    this.onSwitchCollapsed = this.onSwitchCollapsed.bind(this);
    this.getMenuArray = this.getMenuArray.bind(this);
    this.render = this.render.bind(this);
  }

  getMenuArray(menu) {
    const menuIsDefined = Validation.isDefined(menu);
    const menuIsArray = Validation.isArray(menu);
    const menuArrayClone = [];

    if (menuIsDefined) {
      const menuArray = (menuIsArray ? menu : [menu]);

      menuArray.forEach((menuArrayItem, menuArrayItemIndex) => {
        const menuArrayItemClone = React.cloneElement(menuArrayItem, {
          key: menuArrayItemIndex,
        });

        menuArrayClone.push(menuArrayItemClone);
      });
    }

    return menuArrayClone;
  }

  onSwitchCollapsed() {
    this.setState((state, props) => {
      const collapsed = (!(state.collapsed));

      return {
        ...state,
        collapsed: collapsed,
      };
    });
  }

  render() {
    const {
      theme,
      classes,
      name,
      avatar,
      title,
      caption,
      description,
      toolbar,
      menu,
      collapsable,
      collapsed,
      expanded,
      children,
      ...otherProps
    } = this.props;

    const state = this.state;
    const stateCollapsed = state.collapsed;

    const avatarIsDefined = Validation.isDefined(avatar);
    const titleIsDefined = Validation.isDefined(title);
    const captionIsDefined = Validation.isDefined(caption);
    const descriptionIsDefined = Validation.isDefined(description);
    const toolbarIsDefined = Validation.isDefined(toolbar);
    const menuIsDefined = Validation.isDefined(menu);

    const headerIsDefined = ((avatarIsDefined) || (titleIsDefined) || (captionIsDefined) || (descriptionIsDefined) || (toolbarIsDefined) || (menuIsDefined));
    const menuArray = this.getMenuArray(menu);
    const menuArrayIsEmpty = Validation.isEmpty(menuArray);

    return (
      <GroupLayout expanded={expanded} {...otherProps}>
        {(headerIsDefined) &&
          <GroupHeader>
            <Header
              avatar={avatar}
              title={title}
              caption={caption}
              description={description}
              toolbar={
                <React.Fragment>
                  {toolbar}
                  {((collapsable) && (menuArrayIsEmpty)) &&
                    <IconButton onClick={(event) => {this.onSwitchCollapsed();}} size="large">
                      {(stateCollapsed ? <ExpandMore/> : <ExpandLess/>)}
                    </IconButton>
                  }
                  {((collapsable) && (!(menuArrayIsEmpty))) &&
                    <Menu.IconButton icon={<MoreVert/>} name={name + '_menuIconButton'} text='Mais opções'>
                      {menuArray}
                      <Menu.Item
                        text={(stateCollapsed ? 'Maximizar' : 'Minimizar')}
                        icon={(stateCollapsed ? <ExpandMore/> : <ExpandLess/>)}
                        onClick={(event) => {this.onSwitchCollapsed();}}
                      />
                    </Menu.IconButton>
                  }
                </React.Fragment>
              }
            />
          </GroupHeader>
        }
        <GroupBody collapsed={((collapsable) && (stateCollapsed))}>
          {children}
        </GroupBody>
      </GroupLayout>
    );
  }

}

export default Group;
