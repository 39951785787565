const MenuDirection = {
  Vertical: {
    Top: 'top',
    Center: 'center',
    Bottom: 'bottom',
  },
  Horizontal: {
    Left: 'left',
    Center: 'center',
    Right: 'right',
  },
};

export default MenuDirection;
