import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import Table from "../../mui-v4/table/Table";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      eq_equipe: PropTypes.number,
      eq_entidade: PropTypes.number,
      eq_entidade_nome: PropTypes.string,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.eq_equipe) ? false : true;
    const componentFocused = "eq_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onRemover = this.onRemover.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/equipe/editar/criar" : "/equipe/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/equipe/editar/inserir" : "/equipe/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onRemover() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/equipe/editar/remover";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/equipe/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="E" caption={recordIsNew ? "Adicionar equipe" : "Editar equipe"} description={"Editor de equipe"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="equipe" caption="equipe" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Number record={record} name="eq_equipe" label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="eq_nome" label="Nome" />
            </Field.List.Row>
            ario
            <Field.List.Row>
              <Field.String record={record} name="eq_nomeClube" label="Nome do Clube" />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="eq_CPFcnpj" label="CPF e/ou CNPJ" />
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/unidadeFederacao/referenciar"}
                urlParameterMap={{}}
                onChange={() => {
                  this.setState((state, props) => {
                    return {
                      ...state,
                      record: {
                        ...state.record,
                        eq_municipio: null,
                        eq_municipio_nome: null,
                      },
                    };
                  });
                }}
                columnArray={[<Table.ColumnNumber name="uf_unidadeFederacao" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="uf_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="eq_unidadeFederacao" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="eq_unidadeFederacao_nome" record={record} label="Unidade da Federação " />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/municipio/referenciar"}
                urlParameterMap={() => {
                  return {
                    mu_unidadeFederacao: record.eq_unidadeFederacao,
                  };
                }}
                onChange={null}
                columnArray={[<Table.ColumnNumber name="mu_municipio" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="mu_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="eq_municipio" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="eq_municipio_nome" record={record} label="*Município " />
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
          <Field.List.Row>
            <Field.String record={record} name="eq_endereco" label="Endereço" />
          </Field.List.Row>
          <Field.List.Row>
            <Field.String record={record} name="eq_CEP" label="CEP" />
          </Field.List.Row>
          <Field.List.Row>
            <Field.String record={record} name="eq_telefone" label="Telefone" />
          </Field.List.Row>
          <Field.List.Row>
            <Field.String record={record} name="eq_email" label="email" />
          </Field.List.Row>
          <Field.List.Row>
            <Field.String record={record} name="eq_chavePIX" label="PIX" />
          </Field.List.Row>
          <Field.List.Row>
            <Field.Reference
              url={"/usuario/referenciar"}
              urlParameterMap={() => {
                return {
                  us_entidade: record.eq_entidade,
                };
              }}
              onChange={this.onAtualizar}
              columnArray={[<Table.ColumnNumber name="us_usuario" label="Usuario" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="us_nome" label="Nome Usuario" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
            >
              <Field.Input.Number name="eq_usuarioSecretaria" record={record} label="Usuario" prefix="" thousandSeparator="" decimalScale={0} />
              <Field.Input.String name="eq_usuarioSecretaria_nome" record={record} label="Usuario P/Secretaria" />
            </Field.Reference>
          </Field.List.Row>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="eq_ativo"
                caption="eq_ativo_description"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "equipe",
                  flg_campo: "eq_ativo",
                }}
                label="Ativo"
                onChange={this.onAtualizar}
              />
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="remover" variant="contained" size="small" onClick={this.onRemover} disabled={recordIsNew === true}>
              Remover
            </Button>,
          ]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
