import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import Paper from "@mui/material/Paper";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    programManagerLayout: {
      flex: '1 1 auto',
    },
    programManagerBanner: {
      [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
        position: 'absolute',
        width: '100%',
        height: theme.spacing(10),
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
        display: 'none',
      },
    },
    programManagerPanel: {
      [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
        position: 'absolute',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        padding: theme.spacing(2),
        paddingTop: theme.spacing((10 / 2)),
      },
      [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
        position: 'absolute',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        padding: theme.spacing(0),
      },
    },
  };
});
*/

class ProgramManagerLayoutNormal extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <Scrollbars
        /* className={classes.programManagerLayout} */
        style={{flex: '1 1 auto',}}
      >
        <Paper
          /* className={classes.programManagerBanner} */
          sx={(theme) => ({
            [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
              position: 'absolute',
              width: '100%',
              height: theme.spacing(10),
              backgroundColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
              display: 'none',
            },
          })}
          square={true}/>
        <Box
          /* className={classes.programManagerPanel} */
          sx={(theme) => ({
            [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
              position: 'absolute',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              padding: theme.spacing(2),
              paddingTop: theme.spacing((10 / 2)),
            },
            [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
              position: 'absolute',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'stretch',
              padding: theme.spacing(0),
            },
          })}
          {...otherProps}
        >
          {children}
        </Box>
      </Scrollbars>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(ProgramManagerLayoutNormal);;
export default ProgramManagerLayoutNormal;
