import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      cob_cobrador: PropTypes.number,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.cob_cobrador) ? false : true;
    const componentFocused = "cob_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onRemover = this.onRemover.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/cobrador/editar/criar" : "/cobrador/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/cobrador/editar/inserir" : "/cobrador/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onRemover() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/cobrador/editar/remover";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/cobrador/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;
    const vp_entidade = this.getVP("vp_entidade");
    const entidadeDisabled = vp_entidade === 1 ? false : true;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="E" caption={recordIsNew ? "Adicionar Cobrador" : "Editar Cobrador"} description={"Editor de Cobrador"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="cobrador" caption="cobrador" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Reference url={"/entidade/referenciar"} urlParameterMap={{}} onChange={this.onAtualizar} columnArray={[<Table.ColumnNumber name="en_entidade" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="en_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}>
                <Field.Input.Number name="cob_entidade" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={entidadeDisabled} />
                <Field.Input.String name="cob_entidade_nome" record={record} label="Entidade " disabled={entidadeDisabled} />
              </Field.Reference>
            </Field.List.Row>

            <Field.List.Row>
              <Field.Number record={record} name="cob_cobrador" label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="cob_nome" label="Nome" />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="cob_apelido" label="Apelido" />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="cob_ativo"
                caption="cob_ativo_description"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "cobrador",
                  flg_campo: "cob_ativo",
                }}
                label="Ativo"
                onChange={this.onAtualizar}
              />
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="remover" variant="contained" size="small" onClick={this.onRemover} disabled={recordIsNew === true}>
              Remover
            </Button>,
          ]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
