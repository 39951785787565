import React from "react";
import Program from "../mui-v4/program/Program";
import Context from "../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../mui-v4/program/ProgramLayout";
import Header from "../mui-v4/header/Header";
import Group from "../mui-v4/group/Group";
import Field from "../mui-v4/field/Field";
import ButtonBar from "../mui-v4/button/ButtonBar";
import Button from "../mui-v4/button/Button";
import PersonIcon from "@mui/icons-material/Person";
import Table from "../mui-v4/table/Table";
import Validation from "../mui-v4/validation/Validation";

class AppLogin extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        vp_login: null, 
        vp_senha: null, 
      },
      recordArray: [],
      groupLoginVisible: true,
      groupEsqueciVisible: false,
      groupEntidadeVisible: false,
      esqueciSenha: false,
      entidade: null,
      programaList: null,
      componentFocused: "usuario",
      ...props.state,
    };

    this.onClose = this.onClose.bind(this);
    this.onEntrar = this.onEntrar.bind(this);
    this.onEsqueci = this.onEsqueci.bind(this);
    this.onSelecionar = this.onSelecionar.bind(this);
    this.render = this.render.bind(this);
  }

  onClose() {
    const state = this.state;
    const entidade = state.entidade;

    if (entidade !== null) {
      return true;
    } else {
      return false;
    }
  }

  async onEntrar() {
    /* receber os valores de usuario e senha do navegador */
    try {
      this.getComponent("vp_login").focus();
      this.getComponent("vp_senha").focus();
      
      const state = this.state;
      const record = state.record;
      
      if (record.vp_senha === null) {
        return;
      }
    } catch (error) {}
    /* receber os valores de usuario e senha do navegador */

    try {
      const state = this.state;
      const record = state.record;
      const response = await this.fetch("/session/login/getEntidadeList", record);
      const entidadeList = response.entidadeList;

      if (Validation.isEmpty(entidadeList)) {
        this.showSnack("Falha na Autenticação", 2000);
      } else {
        if (entidadeList.length === 1) {
          entidadeList[0].bSelected = true;
        }

        this.showSnack("Selecione uma Entidade", 2000);
        this.setState(
          (state, props) => {
            return {
              ...state,
              groupLoginVisible: false,
              groupEsqueciVisible: false,
              groupEntidadeVisible: true,
              recordArray: entidadeList,
            };
          },
          () => {
            /* auto login se uma entidade so */
            if (entidadeList.length === 1) {
              this.onSelecionar();
            }
            /* auto login */
          }
        );
      }
    } catch (error) {
      this.showSnack(error.message, 3000);
    }
  }

  async onEsqueci() {
    try {
      this.setState((state, props) => {
        return {
          ...state,
          groupLoginVisible: false,
          groupEsqueciVisible: true,
          groupEntidadeVisible: false,
        };
      });
    } catch (error) {
      this.showSnack(error.message, 3000);
    }
  }

  async onEnviar(confirmar) {
    try {
      if (confirmar === true) {
        const state = this.state;
        const record = state.record;
        await this.fetch("/session/login/setPassword", record);

        this.showSnack("Sua Senha foi redefinida. Verifique seu e-mail", 2000);
      }

      this.setState((state, props) => {
        return {
          ...state,
          groupLoginVisible: true,
          groupEsqueciVisible: false,
          groupEntidadeVisible: false,
        };
      });
    } catch (error) {
      this.showSnack(error.message, 3000);
    }
  }

  async onSelecionar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray)) {
        let recordArrayIndex = -1;

        recordArray.forEach((record, recordIndex) => {
          if (record.bSelected === true) {
            recordArrayIndex = recordIndex;
          }
        });

        if (recordArrayIndex === -1) {
          this.showSnack("Selecione uma Entidade", 2000);
        } else {
          const response = await this.fetch("/session/login/setEntidade", {
            ...record,
            selectionIndex: recordArrayIndex,
          });

          this.setState(
            (state, props) => {
              return {
                ...state,
                entidade: response.entidade,
                programaList: response.programaList,
                campeonatoList: response.campeonatoList,
                etapaList: response.etapaList,
              };
            },
            () => {
              this.showSnack("Bem vindo", 2000);
              this.close();
            }
          );
        }
      }
    } catch (error) {
      this.showSnack(error.message, 3000);
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const groupLoginVisible = state.groupLoginVisible;
    const groupEsqueciVisible = state.groupEsqueciVisible;
    const groupEntidadeVisible = state.groupEntidadeVisible;

    return (
      <ProgramLayout program={this} width="480px" height="maximum">
        <Header avatar={<PersonIcon />} caption={"Login"} description={null} toolbar={null} />
        {groupLoginVisible === true && (
          <React.Fragment>
            <Group name="groupLogin" caption="Identificação" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.String name="vp_login" record={record} label="E-mail ou CPF/CNPJ" InputLabelProps={{shrink: true}} />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Password name="vp_senha" record={record} label="Senha" onChange={this.onEntrar} InputLabelProps={{shrink: true}} />
                </Field.List.Row>
              </Field.List>
            </Group>
            <ButtonBar
              leftButtonList={[
                <Button name="esqueciSenha" variant="contained" size="small" onClick={this.onEsqueci}>
                  Esqueci a Senha
                </Button>,
              ]}
              rightButtonList={[
                <Button name="entrar" variant="contained" size="small" onClick={this.onEntrar}>
                  Entrar
                </Button>,
              ]}
            />
          </React.Fragment>
        )}
        {groupEsqueciVisible === true && (
          <React.Fragment>
            <Group name="groupEsqueci" caption="Informe seu login (e-mail ou CPF/CNPJ)" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.String name="vp_login" record={record} label="E-mail" disabled={false} />
                </Field.List.Row>
              </Field.List>
            </Group>
            <ButtonBar
              leftButtonList={[]}
              rightButtonList={[
                <Button
                  name="cancelar"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    this.onEnviar(false);
                  }}
                >
                  Cancelar
                </Button>,
                <Button
                  name="enviar"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    this.onEnviar(true);
                  }}
                >
                  Enviar nova Senha
                </Button>,
              ]}
            />
          </React.Fragment>
        )}
        {groupEntidadeVisible === true && (
          <React.Fragment>
            <Table
              name="groupEntidade"
              caption="Selecione a Entidade"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={null}
              menu={null}
              columnArray={[<Table.ColumnString name="vp_entidadeAbreviacao" label="Apelido" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />, <Table.ColumnString name="vp_entidadeNome" label="Entidade" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />, <Table.ColumnString name="vp_funcao" label="Função" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
            <ButtonBar
              leftButtonList={[
                <Button
                  name="cancelar"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    this.onEnviar(false);
                  }}
                >
                  Cancelar
                </Button>,
              ]}
              rightButtonList={[
                <Button name="selecionar" variant="contained" size="small" onClick={this.onSelecionar}>
                  Selecionar
                </Button>,
              ]}
            />
          </React.Fragment>
        )}
      </ProgramLayout>
    );
  }
}

export default AppLogin;
