import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { showError } from "../AppMessage";

const Modo = {
  consulta: "consulta",
  alteracao: "alteracao",
};

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        ieo_entidade: this.context.app.state.vp_entidade,
        ieo_entidade_nome: this.context.app.state.vp_entidadeNome,
        us_usuario: this.context.app.state.vp_usuario,
        ieo_campeonato: null,
        ieo_campeonato_nome: null,
        ieo_etapa: null,
        ieo_etapa_nome: null,
        ieo_outrosCampeonato: null,
        ieo_outrosCampeonato_nome: null,
        ieo_associado: null,
        ieo_associado_nome: null,
        ieo_ordem: null,
      },
      recordArray: [],
      modo: Modo.consulta,
      componentFocused: "null",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onCancelar = this.onCancelar.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.ieo_entidade !== null && record.ieo_campeonato !== null && record.ieo_etapa !== null && record.ieo_outrosCampeonato !== null && record.ieo_associado !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  onEditar() {
    this.setState((state, props) => {
      return {
        ...state,
        modo: Modo.alteracao,
      };
    });
  }

  onCancelar() {
    this.setState((state, props) => {
      return {
        ...state,
        modo: Modo.consulta,
      };
    });
  }

  async onSalvar() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      const parameterMap = {
        recordArray: recordArray,
      };

      await this.fetch("/inscricaoEtapaOutrosCampeonato/listar/alterar", parameterMap);

      this.showSnack("As alterações foram salvas", 2000);
      this.setState(
        (state, props) => {
          return {
            ...state,
            modo: Modo.consulta,
          };
        },
        () => {
          this.onAtualizar();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/inscricaoEtapaOutrosCampeonato/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="E" caption={"Validação do Atirador na Etapa"} description={"Validar Outros Campeonatos do Atirador"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonato/referenciar"}
                    urlParameterMap={() => {
                      return {
                        ca_entidade: record.ieo_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="ieo_campeonato" record={record} label="Campeonato" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="ieo_campeonato_nome" record={record} label="Nome Campeonato" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/etapa/referenciarEtapaAberta"}
                    urlParameterMap={() => {
                      return {
                        et_campeonato: record.ieo_campeonato,
                        et_usuario: record.us_usuario,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="ieo_etapa" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="ieo_etapa_nome" record={record} label="Etapa " />
                  </Field.Reference>
                </Field.List.Row>

                <Field.List.Row>
                  <Field.Reference
                    url={"/outrosCampeonatoEtapa/referenciar"}
                    urlParameterMap={() => {
                      return {
                        oce_etapa: record.ieo_etapa,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="oca_outrosCampeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="oca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="ieo_outrosCampeonato" record={record} label="Campeonato" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="ieo_outrosCampeonato_nome" record={record} label="Nome Campeonato Validar" />
                  </Field.Reference>
                </Field.List.Row>

                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.ieo_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="ieo_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="ieo_associado_nome" record={record} label="associado " />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            {this.state.modo === Modo.consulta && (
              <Table
                name="tableConsulta"
                caption="Lista Modalidades do Atirador na Etapa"
                collapsable={false}
                collapsed={false}
                expanded={true}
                recordArray={recordArray}
                selectionType={this.state.modo === Modo.consulta ? "none" : "multiple"}
                selectionColumnName="bSelected"
                toolbar={
                  recordArray.length === 0 ? null : (
                    <React.Fragment>
                      <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                    </React.Fragment>
                  )
                }
                menu={null}
                columnArray={[
                  <Table.ColumnString name="ieo_situacaoAtual" label="Selecionado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="ieo_modalidade_abreviacao" label="Abreviação" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="ieo_modalidade_nome" label="Modalidade" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                ]}
                fixedRowCount={1}
                fixedColumnCount={1}
              />
            )}
            {this.state.modo === Modo.alteracao && (
              <Table
                name="table"
                caption="Alterar as Modalidades do Atirador na Etapa"
                collapsable={false}
                collapsed={false}
                expanded={true}
                recordArray={recordArray}
                selectionType="multiple"
                selectionColumnName="bSelected"
                toolbar={
                  <React.Fragment>
                    <Menu.IconButton name="salvar" icon={<SaveIcon />} text="Salvar" onClick={this.onSalvar} />
                    <Menu.IconButton name="cancelar" icon={<CloseIcon />} text="Cancelar" onClick={this.onCancelar} />
                  </React.Fragment>
                }
                menu={null}
                columnArray={[
                  <Table.ColumnString name="ieo_situacaoAtual" label="Selecionado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="ieo_modalidade_abreviacao" label="Abreviação" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="ieo_modalidade_nome" label="Modalidade" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                ]}
                fixedRowCount={1}
                fixedColumnCount={1}
              />
            )}
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
