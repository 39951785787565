import React from 'react';
import PropTypes from 'prop-types';
import MenuDirection from './MenuDirection';
import Validation from '../validation/Validation';
import Menu from '@mui/material/Menu';

class MenuItemList extends React.Component {

  static propTypes = {
    anchor: PropTypes.object,
    anchorOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(Object.values(MenuDirection.Vertical)).isRequired,
      horizontal: PropTypes.oneOf(Object.values(MenuDirection.Horizontal)).isRequired,
    }).isRequired,
    transformOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(Object.values(MenuDirection.Vertical)).isRequired,
      horizontal: PropTypes.oneOf(Object.values(MenuDirection.Horizontal)).isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    anchor: null,
    anchorOrigin: null,
    transformOrigin: null,
    onClose: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  onClose(event) {
    const props = this.props;
    const propsOnClose = props.onClose;
    const propsOnCloseIsFunction = Validation.isFunction(propsOnClose);

    if (propsOnCloseIsFunction) {
      propsOnClose();
    }
  }

  render() {
    const {
      theme,
      classes,
      anchor,
      anchorOrigin,
      transformOrigin,
      children,
      ...otherProps
    } = this.props;

    const childrenArray = React.Children.toArray(children);
    const childrenArrayIsEmpty = Validation.isEmpty(childrenArray);
    const open = Validation.isDefined(anchor);

    if (childrenArrayIsEmpty) {
      return null;
    } else {
      return (
        <Menu
          anchorEl={anchor}
          open={open}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={(event) => {this.onClose(event);}}
          {...otherProps}
        >
          {this.renderChildren()}
        </Menu>
      );
    }
  }

  renderChildren() {
    const props = this.props;
    const children = React.Children.toArray(props.children);

    var childrenIconIsDefined = false;

    children.forEach((child) => {
      const childProps = child.props;
      const childIcon = childProps.icon;
      const childIconIsDefined = Validation.isDefined(childIcon);

      childrenIconIsDefined = ((childrenIconIsDefined) || (childIconIsDefined));
    });

    const childrenClone = [];

    children.forEach((child, childIndex) => {
      const childProps = child.props;
      const childIcon = childProps.icon;
      const childIconIsDefined = Validation.isDefined(childIcon);
      const childInset = ((childrenIconIsDefined) && (!(childIconIsDefined)));

      const childClone = React.cloneElement(child, {
        inset: childInset,
        onClose: (event) => {
          const childProps = child.props;
          const childOnClose = childProps.onClose;
          const childOnCloseIsFunction = Validation.isFunction(childOnClose);

          if (childOnCloseIsFunction) {
            childOnClose(event);
          }

          this.onClose(event);
        },
      });

      childrenClone.push(childClone);
    });

    return childrenClone;
  }

}

export default MenuItemList;
