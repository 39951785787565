import React from "react";

import CampeonatoIcon from '@mui/icons-material/EmojiEvents';
import EtapaIcon from "@mui/icons-material/DateRange";
import EtapaAbertaIcon from "@mui/icons-material/EventAvailable";
import EtapaEncerradaIcon from "@mui/icons-material/EventBusy";
import EtapaFuturaIcon from "@mui/icons-material/Event";
import InscricaoEtapaIcon from '@mui/icons-material/People';
import InscricaoSumulaIcon from '@mui/icons-material/Ballot';
import ModalidadeIcon from '@mui/icons-material/Category';
import SumulaIcon from '@mui/icons-material/Ballot';
import ValidarIcon from '@mui/icons-material/CallSplit';
import UsuarioAdministradorIcon from "@mui/icons-material/VerifiedUser";
import UsuarioGerenteIcon from "@mui/icons-material/AssignmentInd";
import UsuarioAtiradorIcon from "@mui/icons-material/Person";

import FinanceiroIcon from "@mui/icons-material/AttachMoney";
import DialpadIcon from "@mui/icons-material/Dialpad";
import ClassificacaoIcon from '@mui/icons-material/Equalizer';

const  ResultadoIcon = (props) => { return (<DialpadIcon style={{transform: "rotate(270deg)", ...props.style}} />);};


export { CampeonatoIcon };
export { EtapaIcon };
export { EtapaAbertaIcon };
export { EtapaEncerradaIcon };
export { EtapaFuturaIcon };
export { InscricaoEtapaIcon };
export { InscricaoSumulaIcon };
export { ModalidadeIcon };
export { SumulaIcon };
export { ValidarIcon };
export { UsuarioAdministradorIcon };
export { UsuarioGerenteIcon };
export { UsuarioAtiradorIcon };

export { FinanceiroIcon };
export { ResultadoIcon };
export { ClassificacaoIcon };
