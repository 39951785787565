import React from "react";
import PropTypes from "prop-types";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    programRow: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'row wrap',
      overflow: 'hidden',
    },
  };
});
*/

class ProgramRow extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      childrenFit: true,
    };

    this.reference = {
      programRow: null,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.onResize = this.onResize.bind(this);
    this.getChildrenWidth = this.getChildrenWidth.bind(this);
    this.render = this.render.bind(this);
    this.getChildrenClone = this.getChildrenClone.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    const reference = this.reference;
    const programRow = reference.programRow;
    const programRowBoundingClientRect = programRow.getBoundingClientRect();
    const programRowWidth = programRowBoundingClientRect.width;
    const childrenWidth = this.getChildrenWidth();
    const state = this.state;
    const childrenFitCurrent = state.childrenFit;
    const childrenFitNew = programRowWidth > childrenWidth;

    if (childrenFitNew !== childrenFitCurrent) {
      this.setState((state, props) => {
        return {
          ...state,
          childrenFit: childrenFitNew,
        };
      });
    }
  }

  getChildrenWidth() {
    const props = this.props;
    const children = props.children;
    const childrenArray = React.Children.toArray(children);

    var childrenWidth = 0;
    childrenArray.forEach((child) => {
      const childIsValidElement = React.isValidElement(child);

      if (childIsValidElement) {
        const childProps = child.props;
        const childWidth = childProps.width;
        const childWidthLength = childWidth.length;
        const childWidthSuffix = "px";
        const childWidthSuffixLength = childWidthSuffix.length;
        const childWidthValueString = childWidth.substring(0, childWidthLength - childWidthSuffixLength);
        const childWidthValue = parseInt(childWidthValueString);

        childrenWidth += childWidthValue;
      }
    });

    return childrenWidth;
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      style,
      children,
      ...otherProps
    } = this.props;

    const {
      childrenFit,
    } = this.state;

    const childrenClone = this.getChildrenClone();

    const styleComposed = {
      ...(childrenFit ? {flexFlow: 'row wrap'} : {flexFlow: 'column nowrap'}),
      ...style,
    };

    return (
      <Box
        /* className={classes.programRow} */
        /* style={styleComposed} */

        sx={(theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'row wrap',
          overflow: 'hidden',
          ...styleComposed,
        })}

        ref={(programRow) => {
          const reference = this.reference;

          reference.programRow = programRow;
        }}
        {...otherProps}
      >
        {childrenClone}
      </Box>
    );
  }

  getChildrenClone() {
    const state = this.state;
    const childrenFit = state.childrenFit;

    const props = this.props;
    const children = props.children;

    if (childrenFit) {
      return children;
    } else {
      const childrenArray = React.Children.toArray(children);
      const childrenClone = [];

      childrenArray.forEach((child, childIndex) => {
        const childClone = React.cloneElement(child, {
          key: childIndex,
          width: "maximum",
        });

        childrenClone.push(childClone);
      });

      return childrenClone;
    }
  }
}

// export default withStyles(styles, {withTheme: true})(ProgramRow);
export default ProgramRow;
