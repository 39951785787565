import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Table from "../../mui-v4/table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import { showError } from "../AppMessage";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        isu_entidade: this.context.app.state.vp_entidade,
        isu_entidade_nome: this.context.app.state.vp_entidadeNome,
        isu_campeonato: this.props.parameterMap.isu_campeonato,
        isu_etapa: this.props.parameterMap.isu_etapa,
        isu_sumula: null,
        isu_data: null,
        isu_tipoSumula: null,
        isu_associado: null,
        isu_numero: null,
        isu_impressa: null,
        isu_ativo: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);    
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (this.state.record.isu_entidade !== null && this.state.record.isu_campeonato !== null && this.state.record.isu_etapa !== null && this.state.record.isu_tipoSumula !== null && this.state.record.isu_data !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();
   
    if (tableSelectionArray.length === 1 && tableSelectionArray[0].su_impressa == "N" ) {
      const state = this.state;
      const stateRecord = state.record;

      const record = {
        ...tableSelectionArray[0],
        isu_entidade: stateRecord.isu_entidade,
        isu_entidade_nome: stateRecord.isu_entidade_nome,
        isu_campeonato: stateRecord.isu_campeonato,
        isu_campeonato_nome: stateRecord.isu_campeonato_nome,
        isu_associado: stateRecord.isu_associado,
        isu_associado_nome: stateRecord.isu_associado_nome,
        isu_etapa: stateRecord.isu_etapa,
      };

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;

              // if (editarSuccess === true) {
                this.onAtualizar();
              // }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    } else {
      showError(this, "Sumula esta finalizada, alteração somente na secretária");
    }
  }


 

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/inscricaoSumulaAssociado/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="800px" height="600px">
        <Header avatar="S" caption={"Cadastro de Súmula"} description={"Adiciona, edita e exclui Súmula "}
        toolbar={
                <React.Fragment>
                  <Menu.IconButton name="Atualizar" icon={"Atualizar"} text="Atualizar" onClick={this.onAtualizar} />
                  <Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />
                </React.Fragment>
              }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Date name="isu_data" record={record} label="Data" prefix="" onChange={this.onAtualizar} />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo name="isu_tipoSumula" caption="isu_tipoSumula_nome" record={record} url={"/tipoSumula/referenciarPorCampeonato"} urlParameterMap={{ tsu_campeonato: this.state.record.isu_campeonato }} label="Tipo da Súmula" onChange={this.onAtualizar} />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de Súmula"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="isu_numeroPosicao" label="Súmula" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="isu_associado_nome" label="Nome Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="isu_impressa_descricao" label="Impressa" expanded={false} cellWidth={90} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="isu_treino_descricao" label="Treino" expanded={false} cellWidth={90} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
