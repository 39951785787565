import React from 'react';
import HeaderLayout from './HeaderLayout';
import HeaderPanel from './HeaderPanel';
import HeaderAvatar from './HeaderAvatar';
import HeaderTypography from './HeaderTypography';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from '../validation/Validation';

class Header extends React.Component {

  static Layout = HeaderLayout;
  static Panel = HeaderPanel;
  static Avatar = HeaderAvatar;
  static Typography = HeaderTypography;

  static propTypes = {
    avatar: PropTypes.node,
    title: PropTypes.node,
    caption: PropTypes.node,
    description: PropTypes.node,
    toolbar: PropTypes.node,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    avatar: null,
    title: null,
    caption: null,
    description: null,
    toolbar: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      avatar,
      title,
      caption,
      description,
      toolbar,
      children,
      ...otherProps
    } = this.props;

    const avatarIsDefined = Validation.isDefined(avatar);
    const titleIsDefined = Validation.isDefined(title);
    const captionIsDefined = Validation.isDefined(caption);
    const descriptionIsDefined = Validation.isDefined(description);
    const toolbarIsDefined = Validation.isDefined(toolbar);

    return (
      <Header.Layout {...otherProps}>
        {(avatarIsDefined) && (
          <Header.Panel position='left'>
            <Header.Avatar>
              {avatar}
            </Header.Avatar>
          </Header.Panel>
        )}
        {(titleIsDefined || captionIsDefined || descriptionIsDefined) && (
          <Header.Panel position='center'>
            {(titleIsDefined) && (
              <Header.Typography variant='title'>
                {title}
              </Header.Typography>
            )}
            {(captionIsDefined) && (
              <Header.Typography variant='caption'>
                {caption}
              </Header.Typography>
            )}
            {(descriptionIsDefined) && (
              <Header.Typography variant='description'>
                {description}
              </Header.Typography>
            )}
          </Header.Panel>
        )}
        {(toolbarIsDefined) && (
          <Header.Panel position='right'>
            {toolbar}
          </Header.Panel>
        )}
      </Header.Layout>
    );
  }

}

export default Header;
