import React from "react";
import ProgramManagerLayoutNormal from "./ProgramManagerLayoutNormal";
import ProgramManagerLayoutDialog from "./ProgramManagerLayoutDialog";
import Context from "../context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from "../validation/Validation";

class ProgramManager extends React.Component {
  static LayoutNormal = ProgramManagerLayoutNormal;
  static LayoutDialog = ProgramManagerLayoutDialog;

  static contextType = Context;

  static propTypes = {
    programList: PropTypes.arrayOf(PropTypes.element),
    programDialogList: PropTypes.arrayOf(PropTypes.element),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    programList: null,
    programDialogList: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.contextValue = {
      ...context,
      programManager: this,
    };

    var programList = props.programList;
    var programDialogList = props.programDialogList;
    const programListIsArray = Validation.isArray(programList);
    const programDialogListIsArray = Validation.isArray(programDialogList);

    programList = programListIsArray ? programList : [];
    programDialogList = programDialogListIsArray ? programDialogList : [];

    this.state = {
      ...this.state,
      programList: programList,
      programDialogList: programDialogList,
    };

    this.reference = {
      program: null,
      programManagerList: [],
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.isDialog = this.isDialog.bind(this);
    this.render = this.render.bind(this);
    this.renderProgram = this.renderProgram.bind(this);
    this.renderProgramDialogList = this.renderProgramDialogList.bind(this);
  }

  open(program) {
    const state = this.state;
    const programList = state.programList;
    const reference = this.reference;
    const referenceProgram = reference.program;
    const referenceProgramIsObject = Validation.isObject(referenceProgram);

    if (referenceProgramIsObject) {
      const referenceProgramOnStop = referenceProgram.onStop;
      const referenceProgramOnStopIsFunction = Validation.isFunction(
        referenceProgramOnStop
      );

      if (referenceProgramOnStopIsFunction) {
        referenceProgram.onStop();
      }

      const referenceProgramState = referenceProgram.state;
      const referenceProgramIndex = programList.length - 1;
      const referenceProgramElement = programList[referenceProgramIndex];
      const referenceProgramElementClone = React.cloneElement(
        referenceProgramElement,
        {
          state: referenceProgramState,
        }
      );

      programList[referenceProgramIndex] = referenceProgramElementClone;
    }

    programList.push(program);

    /* This workaround is required in case the Program opens a new instance of itself */
    /*
    this.setState((state, props) => {
      return {
        ...state,
        programList: [],
      };
    }, () => {
      this.setState((state, props) => {
        return {
          ...state,
          programList: programList,
        };
      });
    });
    */
    /* workaround fix attempt */
    this.setState((state, props) => {
      return {
        ...state,
        programList: programList,
      };
    });
    /* workaround fix attempt */
  }

  async close() {
    const reference = this.reference;
    const programManagerList = reference.programManagerList;

    var programManagerListIsEmpty = Validation.isEmpty(programManagerList);
    while (!programManagerListIsEmpty) {
      const programManagerIndex = programManagerList.length - 1;
      const programManager = programManagerList[programManagerIndex];
      const programManagerClose = programManager.close;
      const programManagerCloseFailed = programManagerClose() === false;

      if (programManagerCloseFailed) {
        return false;
      } else {
        programManagerListIsEmpty = Validation.isEmpty(programManagerList);
      }
    }

    const referenceProgram = reference.program;
    const referenceProgramOnClose = referenceProgram.onClose;
    const referenceProgramOnCloseIsFunction = Validation.isFunction(
      referenceProgramOnClose
    );

    if (referenceProgramOnCloseIsFunction) {
      const referenceProgramOnCloseResult = await referenceProgramOnClose();
      const referenceProgramOnCloseFailed =
        referenceProgramOnCloseResult === false;

      if (referenceProgramOnCloseFailed) {
        return false;
      }
    }

    const referenceProgramProps = referenceProgram.props;
    const referenceProgramPropsOnClose = referenceProgramProps.onClose;
    const referenceProgramPropsOnCloseIsFunction = Validation.isFunction(
      referenceProgramPropsOnClose
    );

    if (referenceProgramPropsOnCloseIsFunction) {
      const referenceProgramPropsOnCloseFailed =
        referenceProgramPropsOnClose(referenceProgram) === false;

      if (referenceProgramPropsOnCloseFailed) {
        return false;
      }
    }

    const state = this.state;
    const programList = state.programList;

    programList.splice(-1, 1);

    /* This workaround is required in case the Program opens a new instance of itself */
    this.setState(
      (state, props) => {
        return {
          ...state,
          programList: [],
        };
      },
      () => {
        this.setState((state, props) => {
          return {
            ...state,
            programList: programList,
          };
        });
      }
    );

    const programListIsEmpty = Validation.isEmpty(programList);

    if (programListIsEmpty) {
      const context = this.context;
      const contextIsObject = Validation.isObject(context);

      if (contextIsObject) {
        const programManager = context.programManager;
        const programManagerIsObject = Validation.isObject(programManager);

        if (programManagerIsObject) {
          programManager.closeDialog();
        }
      }
    }

    return true;
  }

  openDialog(program) {
    const state = this.state;
    const programDialogList = state.programDialogList;

    programDialogList.push(program);

    this.setState((state, props) => {
      return {
        ...state,
        programDialogList: programDialogList,
      };
    });
  }

  closeDialog() {
    const state = this.state;
    const programDialogList = state.programDialogList;

    programDialogList.splice(-1, 1);

    this.setState(
      (state, props) => {
        return {
          ...state,
          programDialogList: programDialogList,
        };
      },
      () => {
        const state = this.state;
        const programDialogList = state.programDialogList;
        const programDialogListIsEmpty = Validation.isEmpty(programDialogList);
        if (programDialogListIsEmpty) {
          const reference = this.reference;
          const referenceProgram = reference.program;
          const referenceProgramIsObject =
            Validation.isObject(referenceProgram);

          if (referenceProgramIsObject) {
            const referenceProgramOnResume = referenceProgram.onResume;
            const referenceProgramOnResumeIsFunction = Validation.isFunction(
              referenceProgramOnResume
            );

            if (referenceProgramOnResumeIsFunction) {
              referenceProgramOnResume();
            }
          }
        }
      }
    );
  }

  isDialog() {
    const context = this.context;
    const contextIsObject = Validation.isObject(context);

    if (contextIsObject) {
      const programManager = context.programManager;
      const programManagerIsObject = Validation.isObject(programManager);
      const isDialog = programManagerIsObject;

      return isDialog;
    } else {
      return false;
    }
  }

  render() {
    const {
      theme,
      classes,
      programList,
      programDialogList,
      children,
      ...otherProps
    } = this.props;

    const contextValue = this.contextValue;
    const isDialog = this.isDialog();

    return (
      <Context.Provider value={contextValue}>
        {isDialog !== true && (
          <ProgramManager.LayoutNormal {...otherProps}>
            {this.renderProgram()}
          </ProgramManager.LayoutNormal>
        )}
        {isDialog === true && (
          <ProgramManager.LayoutDialog
            onClose={() => {
              this.close();
            }}
            {...otherProps}
          >
            {this.renderProgram()}
          </ProgramManager.LayoutDialog>
        )}
        {this.renderProgramDialogList()}
      </Context.Provider>
    );
  }

  renderProgram() {
    const state = this.state;
    const programList = state.programList;
    const programListIsEmpty = Validation.isEmpty(programList);

    if (programListIsEmpty) {
      return null;
    } else {
      const programIndex = programList.length - 1;
      const program = programList[programIndex];
      const programClone = React.cloneElement(program, {
        /* workaround fix attempt */
        key: programIndex,
        /* workaround fix attempt */
        ref: (program) => {
          const reference = this.reference;

          reference.program = program;
        },
      });

      return programClone;
    }
  }

  renderProgramDialogList() {
    var programManagerList = [];

    const reference = this.reference;

    reference.programManagerList = [];

    const state = this.state;
    const programDialogList = state.programDialogList;

    programDialogList.forEach((programDialog, programDialogIndex) => {
      const programManager = (
        <ProgramManager
          programList={[programDialog]}
          programDialogList={[]}
          ref={(programManager) => {
            const programManagerList = reference.programManagerList;
            const programManagerIsValidElement =
              React.isValidElement(programManager);

            if (programManagerIsValidElement) {
              programManagerList.push(programManager);
            }
          }}
        />
      );

      programManagerList.push(
        <div key={programDialogIndex}>{programManager}</div>
      );
    });

    return programManagerList;
  }
}

export default ProgramManager;
