import React from "react";
import PropTypes from "prop-types";
import TableCellAlign from "../../mui-v4/table/TableCellAlign";
import TableSortOrder from "../../mui-v4/table/TableSortOrder";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import TableCell from "./TableCell";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    tableCellHeaderStringLeft: {
      flex: '0 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
    tableCellHeaderStringCenter: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    tableCellHeaderStringRight: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'right',
      direction: 'rtl',
    },
    tableCellHeaderStringSortContainer: {
      marginTop: '6px',
    },
  };
});
*/

class TableCellHeaderString extends React.Component {
  static propTypes = {
    parent: PropTypes.any.isRequired,
    isVisible: PropTypes.any.isRequired,
    isScrolling: PropTypes.any.isRequired,
    rowIndex: PropTypes.any.isRequired,
    columnIndex: PropTypes.any.isRequired,
    key: PropTypes.any,
    style: PropTypes.any.isRequired,
    keyValue: PropTypes.any.isRequired,
    table: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    align: PropTypes.oneOf(Object.values(TableCellAlign)).isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    parent: null,
    isVisible: null,
    isScrolling: null,
    rowIndex: null,
    columnIndex: null,
    key: null,
    style: null,
    keyValue: null,
    table: null,
    onClick: null,
    align: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      parent,
      isVisible,
      isScrolling,
      rowIndex,
      columnIndex,
      key,
      style,
      keyValue,
      table,
      onClick,
      align,
      children,
      ...otherProps
    } = this.props;

    const state = table.state;
    const columnArray = state.columnArray;
    const column = columnArray[columnIndex];
    const columnLabel = column.label;

    const sortColumnIndex = state.sortColumnIndex;
    const sortOrder = state.sortOrder;

    var   sort = null;

    if (columnIndex === sortColumnIndex) {
      if (sortOrder === TableSortOrder.Ascending) {
        sort = (<ExpandMore/>);
      } else {
        sort = (<ExpandLess/>);
      }
    }

    var className = null;

    switch (align) {
      case TableCellAlign.Left:
        // className = classes.tableCellHeaderStringLeft;
        className = (theme) => ({
          flex: '0 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left',
        });
        break;
      case TableCellAlign.Center:
        // className = classes.tableCellHeaderStringCenter;
        className = (theme) => ({
          flex: '1 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        });
        break;
      case TableCellAlign.Right:
        // className = classes.tableCellHeaderStringRight;
        className = (theme) => ({
          flex: '1 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'right',
          direction: 'rtl',
        });
        break;
      default:
        className = null;
        break;
    }

    return (
      <TableCell
        parent={parent}
        isVisible={isVisible}
        isScrolling={isScrolling}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        key={keyValue}
        style={style}
        table={table}
        keyValue={keyValue}
        onClick={onClick}
        {...otherProps}
      >
        <Box /* className={className} */ sx={className}>
          {columnLabel.split("\n").map((line, lineIndex) => {
            return (
              <div key={lineIndex}>
                {line}
              </div>
            );
          })}
        </Box>
        <div /*className={classes.tableCellHeaderStringSortContainer}*/ sx={(theme) => ({marginTop: '6px'})}>
          {sort}
        </div>
      </TableCell>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(TableCellHeaderString);
export default TableCellHeaderString;
