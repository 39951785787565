import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Detalhar from "./Detalhar";
import { showError } from "../AppMessage";
import PropTypes from "prop-types";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.object,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: {
      su_campeonato: null,
      su_campeonato_nome: null,
      et_etapa: null,
      et_etapa_nome: null,
    },
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        su_entidade: this.context.app.state.vp_entidade,
        su_entidade_nome: this.context.app.state.vp_entidadeNome,
        su_usuario: this.context.app.state.vp_usuario,
        su_campeonato: props.parameterMap.ca_campeonato,
        su_campeonato_nome: props.parameterMap.ca_campeonato_nome,
        et_etapa: props.parameterMap.et_etapa,
        et_etapa_nome: props.parameterMap.et_etapa_nome,
        as_associado: null,
        as_nome: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (this.state.record.su_entidade !== null && this.state.record.su_campeonato !== null && this.state.record.et_etapa !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const state = this.state;
      const stateRecord = state.record;

      const record = {
        ...tableSelectionArray[0],
        su_entidade: stateRecord.su_entidade,
        su_entidade_nome: stateRecord.su_entidade_nome,
        su_campeonato: stateRecord.su_campeonato,
        su_campeonato_nome: stateRecord.su_campeonato_nome,
        su_usuario: stateRecord.su_usuario,
      };

      this.openDialog(
        <Detalhar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const state = this.state;
              const recordArray = state.recordArray;
              const recordTemp = await this.getRecord(record);
              const recordNew = {
                ...record,
                ...recordTemp,
              };
              const recordArrayNew = recordArray.map((recordArrayRecord, recordArrayIndex, recordArray) => {
                return record === recordArrayRecord ? recordNew : recordArrayRecord;
              });

              this.setState((state, props) => {
                return {
                  ...state,
                  recordArray: recordArrayNew,
                };
              });
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/produtoServicoAssociado/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const { record, recordArray } = this.state;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="E" caption={"Cadastro de Produto/Serviço do Associado"} description={"Adiciona, edita e exclui Produto/Serviço do Associado"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonato/referenciar"}
                    urlParameterMap={() => {
                      return {
                        ca_entidade: this.context.app.state.vp_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="su_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="su_campeonato_nome" record={record} label="Campeonato " />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/etapa/referenciarSecretaria"}
                    urlParameterMap={() => {
                      return {
                        et_campeonato: this.state.record.su_campeonato,
                        et_usuario: this.state.record.su_usuario,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="et_etapa" record={record} label="Etapa" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="et_etapa_nome" record={record} label="Nome Etapa " />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: this.context.app.state.vp_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="as_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="as_associado_nome" record={record} label="associado " />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Saldo de Produto/Serviço do Associado"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="as_associado_string" label="Nome" expanded={true} cellWidth={250} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="produtoServicoTotal" label="Saldo" expanded={false} cellWidth={125} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2} />,
                <Table.ColumnNumber name="produtoServicoPIXTotal" label="Verif. PIX " expanded={false} cellWidth={125} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="." decimalScale={2} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
