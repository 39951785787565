import React from "react";
import PropTypes from "prop-types";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    headerLayout: {
      display: 'flex',
      flexFlow: 'row nowrap',
      overflow: 'hidden',
      height: theme.spacing(7),
    },
  };
});
*/

class HeaderLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <Box
        /* className={classes.headerLayout} */
        sx={(theme) => ({
          display: 'flex',
          flexFlow: 'row nowrap',
          overflow: 'hidden',
          height: theme.spacing(7),
        })}
        {...otherProps}>
        {children}
      </Box>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(HeaderLayout);
export default HeaderLayout;
