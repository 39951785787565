import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { showError } from "../AppMessage";

const Modo = {
  consulta: "consulta",
  alteracao: "alteracao",
};

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        psms_entidade: this.context.app.state.vp_entidade,
        psms_entidade_nome: this.context.app.state.vp_entidadeNome,
        psms_modalidade: null,
        psms_modalidade_nome: null,
        psms_ordem: null,
      },
      recordArray: [],
      modo: Modo.consulta,
      componentFocused: "null",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onCancelar = this.onCancelar.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.psms_entidade !== null && record.psms_modalidade !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  onEditar() {
    this.setState((state, props) => {
      return {
        ...state,
        modo: Modo.alteracao,
      };
    });
  }

  onCancelar() {
    this.setState((state, props) => {
      return {
        ...state,
        modo: Modo.consulta,
      };
    });
  }

  async onSalvar() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      const parameterMap = {
        recordArray: recordArray,
      };
      await this.fetch("/produtoServicoModalidadeSumula/listar/alterar", parameterMap);

      this.showSnack("As alterações foram salvas", 2000);
      this.setState(
        (state, props) => {
          return {
            ...state,
            modo: Modo.consulta,
          };
        },
        () => {
          this.onAtualizar();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/produtoServicoModalidadeSumula/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const vp_entidade = this.getVP("vp_entidade");
    const entidadeDisabled = vp_entidade === 1 ? false : true;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="E" caption={"Atribuir Produto/Servico da Modalidade Por Sumula"} description={"Atribuir Produto/Servico da Modalidade Por Sumula"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference url={"/entidade/referenciar"} urlParameterMap={{}} onChange={this.onAtualizar} columnArray={[<Table.ColumnNumber name="en_entidade" label="Entidade" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="en_nome" label="Nome Entidade" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}>
                    <Field.Input.Number name="psms_entidade" record={record} label="Entidade" prefix="" thousandSeparator="" decimalScale={0} disabled={entidadeDisabled} />
                    <Field.Input.String name="psms_entidade_nome" record={record} label="Nome Entidade" disabled={entidadeDisabled} />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/modalidade/referenciar"}
                    urlParameterMap={() => {
                      return {
                        mo_entidade: record.psms_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="mo_modalidade" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="mo_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="psms_modalidade" record={record} label="Modalidade" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="psms_modalidade_nome" record={record} label="Nome Modalidade" />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            {this.state.modo === Modo.consulta && (
              <Table
                name="tableConsulta"
                caption="Lista Produto/Servico da Modalidade Por Sumula"
                collapsable={false}
                collapsed={false}
                expanded={true}
                recordArray={recordArray}
                selectionType={this.state.modo === Modo.consulta ? "none" : "multiple"}
                selectionColumnName="bSelected"
                toolbar={
                  recordArray.length === 0 ? null : (
                    <React.Fragment>
                      <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                    </React.Fragment>
                  )
                }
                menu={null}
                columnArray={[
                  <Table.ColumnString name="psms_situacaoAtual" label="Selecionado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="psms_modalidade_nome" label="Nome Modalidade" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="psms_produtoServico_nome" label="Nome Produto Serviço" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnNumber name="ps_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                  <Table.ColumnString name="ps_usoExclussivo" label="Exclussivo Sistema" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                ]}
                fixedRowCount={1}
                fixedColumnCount={1}
              />
            )}
            {this.state.modo === Modo.alteracao && (
              <Table
                name="table"
                caption="Lista Produto/Servico da Modalidade Por Sumula"
                collapsable={false}
                collapsed={false}
                expanded={true}
                recordArray={recordArray}
                selectionType="multiple"
                selectionColumnName="bSelected"
                toolbar={
                  <React.Fragment>
                    <Menu.IconButton name="salvar" icon={<SaveIcon />} text="Salvar" onClick={this.onSalvar} />
                    <Menu.IconButton name="cancelar" icon={<CloseIcon />} text="Cancelar" onClick={this.onCancelar} />
                  </React.Fragment>
                }
                menu={null}
                columnArray={[
                  <Table.ColumnString name="psms_situacaoAtual" label="Selecionado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="psms_modalidade_nome" label="Nome Modalidade" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="psms_produtoServico_nome" label="Nome Produto Serviço" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnNumber name="ps_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                  <Table.ColumnString name="ps_usoExclussivo" label="Exclussivo Sistema" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                ]}
                fixedRowCount={1}
                fixedColumnCount={1}
              />
            )}
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
