import Component from '../component/Component';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';
import Context from '../context/Context';
import Validation from '../validation/Validation';

class FieldInput extends Component {

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  }

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    children: null,
  }

  constructor(props, context) {
    super(props, context);

    const value = this.getValue();
    const valueString = this.formatValue(value);
    const helperText = props.helperText;

    this.state = {
      ...this.state,
      value: valueString,
      error: false,
      helperText: helperText,
    };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyDownEsc = this.onKeyDownEsc.bind(this);
    this.onKeyDownEnter = this.onKeyDownEnter.bind(this);
    this.onKeyDownLeft = this.onKeyDownLeft.bind(this);
    this.onKeyDownRight = this.onKeyDownRight.bind(this);
    this.onKeyDownUp = this.onKeyDownUp.bind(this);
    this.onKeyDownDown = this.onKeyDownDown.bind(this);
    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.parseValue = this.parseValue.bind(this);
    this.validate = this.validate.bind(this);
    this.validateRequired = this.validateRequired.bind(this);
    this.validateFunction = this.validateFunction.bind(this);
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    const record = props.record;
    const prevPropsRecord = prevProps.record;
    const recordIsChanged = (prevPropsRecord !== record);

    if (recordIsChanged) {
      const value = this.getValue();
      const valueString = this.formatValue(value);

      this.setState((state, props) => {
        return ({
          ...this.state,
          value: valueString,
        });
      });
    }
  }

  onChange(event) {
    const props = this.props;
    const helperText = props.helperText;
    const target = event.target;
    const valueString = target.value;

    this.setState((state, props) => {
      return {
        ...state,
        value: valueString,
        error: false,
        helperText: helperText,
      };
    });
  }

  onFocus(event) {
    const props = this.props;
    const name = props.name;
    const context = this.context;
    const program = context.program;

    program.setState((state, props) => {
      return {
        ...state,
        componentFocused: name,
      };
    }, () => {
      this.setState((state, props) => {
        const helperText = props.helperText;

        return {
          ...state,
          error: false,
          helperText: helperText,
        };
      }, () => {
        const onFocus = props.onFocus;
        const onFocusIsFunction = Validation.isFunction(onFocus);

        if (onFocusIsFunction) {
          onFocus(event);
        }
      });
    });
  }

  async onBlur(event) {
    const props = this.props;
    const helperText = props.helperText;
    const onBlur = props.onBlur;
    const onBlurIsFunction = Validation.isFunction(onBlur);
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);

    try {
      await this.setValue(value);

      this.setState((state, props) => {
        return {
          ...state,
          error: false,
          helperText: helperText,
        }
      }, () => {
        if (onBlurIsFunction) {
          onBlur(event);
        }
      });
    } catch (error) {
      const valueCurrent = this.getValue();
      const valueCurrentString = this.formatValue(valueCurrent);

      this.setState((state, props) => {
        return {
          ...state,
          value: valueCurrentString,
          error: false,
          helperText: helperText,
        }
      }, () => {
        if (onBlurIsFunction) {
          onBlur(event);
        }
      });
    }
  }

  onKeyDown(event) {
    const keyCode = event.keyCode;

    switch (keyCode) {
      case 13:
        this.onKeyDownEnter(event);
        break;
      case 27:
        this.onKeyDownEsc(event);
        break;
      case 37:
        this.onKeyDownLeft(event);
        break;
      case 39:
        this.onKeyDownRight(event);
        break;
      case 38:
        this.onKeyDownUp(event);
        break;
      case 40:
        this.onKeyDownDown(event);
        break;
      default:
        break;
    }
  }

  async onKeyDownEnter(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (valueIsChanged) {
      try {
        await this.setValue(value);

        const props = this.props;
        const helperText = props.helperText;

        this.setState((state, props) => {
          return {
            ...state,
            error: false,
            helperText: helperText,
          };
        }, () => {
          target.select();
        });
      } catch (error) {
        const errorIsObject = Validation.isObject(error);

        if (errorIsObject) {
          const errorMessageIsDefined = ('message' in error);

          if (errorMessageIsDefined) {
            const errorMessage = error.message;

            this.setState((state, props) => {
              return {
                ...state,
                error: true,
                helperText: errorMessage,
              };
            });
          } else {
            this.setState((state, props) => {
              return {
                ...state,
                error: true,
                helperText: 'Erro na validação',
              };
            });
          }
        } else {
          this.setState((state, props) => {
            return {
              ...state,
              error: true,
              helperText: 'Erro na validação',
            };
          });
        }
      }
    } else {
      const valueStringLength = valueString.length;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      const selectionIsFull = ((selectionStart === 0) && (selectionEnd === valueStringLength));

      if (!(selectionIsFull)) {
        /* Quick fix to avoid an error on FieldReference's icon */
        const targetSelect = target.select;
        const targetSelectIsFunction = Validation.isFunction(targetSelect);

        if (targetSelectIsFunction) {
          target.select();
        }
        /* Quick fix to avoid an error on FieldReference's icon */
      } else {
        /* Quick fix to avoid an error on FieldReference's icon */
        const targetSetSelectionRange = target.setSelectionRange;
        const targetSetSelectionRangeIsFunction = Validation.isFunction(targetSetSelectionRange);

        if (targetSetSelectionRangeIsFunction) {
          target.setSelectionRange(valueStringLength, valueStringLength);
        }
        /* Quick fix to avoid an error on FieldReference's icon */
      }
    }
  }

  onKeyDownEsc(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (valueIsChanged) {
      const valueCurrentString = this.formatValue(valueCurrent);
      const valueCurrentStringLength = valueCurrentString.length;
      const props = this.props;
      const helperText = props.helperText;

      this.setState((state, props) => {
        return {
          ...state,
          value: valueCurrentString,
          error: false,
          helperText: helperText,
        };
      }, () => {
        target.setSelectionRange(valueCurrentStringLength, valueCurrentStringLength);
      });
    } else {
      const valueStringLength = valueString.length;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      const selectionIsFull = ((selectionStart === 0) && (selectionEnd === valueStringLength));

      if (!(selectionIsFull)) {
        target.select();
      } else {
        const context = this.context;
        const program = context.program;
        const programFocusLeftResult = program.focusLeft(this);
        const programFocusLeftFailed = (programFocusLeftResult === false);

        if (programFocusLeftFailed) {
          const programFocusUpResult = program.focusUp(this);
          const programFocusUpFailed = (programFocusUpResult === false);

          if (programFocusUpFailed) {
            const programManager = context.programManager;

            programManager.close();
          }
        }
      }
    }
  }

  onKeyDownLeft(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (!(valueIsChanged)) {
      const valueStringLength = valueString.length;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      const selectionIsFull = ((selectionStart === 0) && (selectionEnd === valueStringLength));

      if (selectionIsFull) {
        event.preventDefault();

        const context = this.context;
        const program = context.program;
        program.focusLeft(this);
      }
    }
  }

  onKeyDownRight(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (!(valueIsChanged)) {
      const valueStringLength = valueString.length;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      const selectionIsFull = ((selectionStart === 0) && (selectionEnd === valueStringLength));

      if (selectionIsFull) {
        event.preventDefault();

        const context = this.context;
        const program = context.program;
        program.focusRight(this);
      }
    }
  }

  onKeyDownUp(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (!(valueIsChanged)) {
      const valueStringLength = valueString.length;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      const selectionIsFull = ((selectionStart === 0) && (selectionEnd === valueStringLength));

      if (selectionIsFull) {
        event.preventDefault();

        const context = this.context;
        const program = context.program;
        program.focusUp(this);
      }
    }
  }

  onKeyDownDown(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (!(valueIsChanged)) {
      const valueStringLength = valueString.length;
      const selectionStart = target.selectionStart;
      const selectionEnd = target.selectionEnd;
      const selectionIsFull = ((selectionStart === 0) && (selectionEnd === valueStringLength));

      if (selectionIsFull) {
        event.preventDefault();

        const context = this.context;
        const program = context.program;
        program.focusDown(this);
      }
    }
  }

  getValue() {
    const props = this.props;
    const name = props.name;
    const record = props.record;
    const value = record[name];
    const valueIsDefined = Validation.isDefined(value);

    return (valueIsDefined ? value : null);
  }

  async setValue(value) {
    const valueCurrent = this.getValue();
    const valueIsChanged = (value !== valueCurrent);

    if (valueIsChanged) {
      await this.validate(value);

      const props = this.props;
      const name = props.name;
      const record = props.record;

      record[name] = value;

      const onChange = props.onChange;
      const onChangeIsFunction = Validation.isFunction(onChange);

      if (onChangeIsFunction) {
        onChange();
      }
    }
  }

  formatValue(value) {
    const valueIsDefined = Validation.isDefined(value);
    const valueString = (valueIsDefined ? value : '');

    return valueString;
  }

  parseValue(valueString) {
    const valueStringIsEmpty = Validation.isEmpty(valueString);
    const value = (valueStringIsEmpty ? null : valueString);

    return value;
  }

  async validate(value) {
    const valueIsDefined = (typeof(value) !== 'undefined');

    if (!(valueIsDefined)) {
      value = this.getValue();
    }

    await this.validateRequired(value);
    await this.validateFunction(value);
  }

  async validateRequired(value) {
    const props = this.props;
    const required = props.required;
    const valueIsRequired = (required === true);
    const valueIsDefined = Validation.isDefined(value);
    const valueIsString = ((valueIsDefined) && (Validation.isString(value)));
    const valueIsEmpty = ((valueIsString) && (Validation.isEmpty(value)));

    if ((valueIsRequired) && ((!(valueIsDefined)) || ((valueIsString) && (valueIsEmpty)))) {
      const valueCurrent = this.getValue();
      const error = {
        field: this,
        valueCurrent: valueCurrent,
        value: value,
        message: 'Campo obrigatório',
      };

      throw error;
    }
  }

  async validateFunction(value) {
    const props = this.props;
    const validate = props.validate;
    const validateIsFunction = Validation.isFunction(validate);

    if (validateIsFunction) {
      await validate({
        field: this,
        value: value,
      });
    }
  }

}

export default FieldInput;
