import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import { showError } from "../AppMessage";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        prg_programa: null,
        prg_nome: null,
        prg_finalidade: null,
        prg_modulo: null,
        prg_modulo_descricao: null,
        prg_ativo: null,
        prg_ativo_descricao: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onAdicionar = this.onAdicionar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArrayNew = await this.getRecordArray(record);

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onAdicionar() {
    const record = {
      prg_programa: null,
    };

    this.openDialog(
      <Editar
        parameterMap={record}
        onClose={async (editar) => {
          try {
            const editarState = editar.state;
            const editarSuccess = editarState.success;
            const editarResult = editarState.result;

            if (editarSuccess === true) {
              const editarRecord = await this.getRecord(editarResult);
              const state = this.state;
              const recordArray = state.recordArray;

              recordArray.splice(0, 0, editarRecord);

              this.setState((state, props) => {
                return {
                  ...state,
                  recordArray: recordArray,
                };
              });
            }
          } catch (error) {
            showError(this, error);
          }
        }}
      />
    );
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const record = tableSelectionArray[0];

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;
              const editarResult = editarState.result;

              if (editarSuccess === true) {
                const state = this.state;
                const recordArray = state.recordArray;
                let recordArrayNew = [];

                if (editarResult === null) {
                  recordArrayNew = recordArray.filter((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record !== recordArrayRecord;
                  });
                } else {
                  const editarRecord = await this.getRecord(editarResult);
                  const recordNew = {
                    ...record,
                    ...editarRecord,
                  };

                  recordArrayNew = recordArray.map((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record === recordArrayRecord ? recordNew : recordArrayRecord;
                  });
                }

                this.setState((state, props) => {
                  return {
                    ...state,
                    recordArray: recordArrayNew,
                  };
                });
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/programa/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="maximum" height="maximum">
        <Header avatar="E" caption={"Cadastro de Programa"} description={"Adiciona, edita e exclui Programa"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Number name="prg_programa" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} onChange={this.onAtualizar} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.String name="prg_nome" record={record} label="Nome" onChange={this.onAtualizar} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.String name="prg_finalidade" record={record} label="finalidade" onChange={this.onAtualizar} />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="prg_modulo"
                    caption="prg_modulo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "programa",
                      flg_campo: "prg_modulo",
                    }}
                    label="Modulo"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="prg_ativo"
                    caption="prg_ativo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "programa",
                      flg_campo: "prg_ativo",
                    }}
                    label="Ativo"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de Programa"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="adicionar" icon={<AddIcon />} text="Adicionar" onClick={this.onAdicionar} />
                  <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnNumber name="prg_programa" label="Código" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnNumber name="prg_numero" label="Numero" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnString name="prg_nome" label="Nome" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="prg_nomeFrontend" label="Nome Frontend" expanded={false} cellWidth={180} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="prg_finalidade" label="finalidade" expanded={false} cellWidth={180} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="prg_ordem" label="Ordem Exibição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="prg_nivel" label="Nivel" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnString name="prg_modulo_descricao" label="Modulo" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="prg_ativo_descricao" label="Ativo" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
