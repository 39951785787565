import React from "react";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    buttonBar: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    buttonBarSpacer: {
      flex: '1 1 auto',
    },
    buttonBarWrapper: {
      margin: theme.spacing(1),
    }
  };
});
*/

class ButtonBar extends React.Component {
  static propTypes = {
    leftButtonList: PropTypes.arrayOf(PropTypes.element),
    rightButtonList: PropTypes.arrayOf(PropTypes.element),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    leftButtonList: [],
    rightButtonList: [],
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
    this.renderLeftButtonList = this.renderLeftButtonList.bind(this);
    this.renderRightButtonList = this.renderRightButtonList.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      leftButtonList,
      rightButtonList,
      children,
      ...otherProps
    } = this.props;

    return (
      <Box
        /* className={classes.buttonBar} */
        sx={(theme) => ({
          display: 'flex',
          flexFlow: 'row wrap',    
        })}
        {...otherProps}
      >
        {this.renderLeftButtonList()}
        <Box
          /* className={classes.buttonBarSpacer} */
          sx={(theme) => ({
            flex: '1 1 auto',
          })}
        />
        {this.renderRightButtonList()}
      </Box>
    );
  }

  /* prettier-ignore */
  renderLeftButtonList() {
    const props = this.props;
    const leftButtonList = props.leftButtonList;
    //const classes = props.classes;
    var   buttonList = [];

    leftButtonList.forEach((button, buttonIndex) => {
      buttonList.push(
        <Box
          /* className={classes.buttonBarWrapper} */
          sx={(theme) => ({
            margin: theme.spacing(1),
          })}
          key={buttonIndex}
        >
          {button}
        </Box>
      );
    });

    return buttonList;
  }

  /* prettier-ignore */
  renderRightButtonList() {
    const props = this.props;
    const rightButtonList = props.rightButtonList;
    //const classes = props.classes;
    var   buttonList = [];

    rightButtonList.forEach((button, buttonIndex) => {
      buttonList.push(
        <Box
          /* className={classes.buttonBarWrapper} */
          sx={(theme) => ({
            margin: theme.spacing(1),
          })}
          key={buttonIndex}
        >
          {button}
        </Box>
      );
    });

    return buttonList;
  }
}

// export default withStyles(styles, {withTheme: true})(ButtonBar);
export default ButtonBar;
