import React from "react";
import AppLayout from "./AppLayout";
import AppHeader from "./AppHeader";
import AppBody from "./AppBody";
import Context from "../context/Context";
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';

//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
// import AirbnbPropTypes from 'airbnb-prop-types';
import ContextValueDefault from "../context/ContextValueDefault";
// import AppProgram from "./AppProgram";
import { getTime } from "date-fns";
import Validation from "../validation/Validation";

class App extends React.Component {
  static Layout = AppLayout;
  static Header = AppHeader;
  static Body = AppBody;

  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.contextValue = {
      ...ContextValueDefault,
      app: this,
    };

    const tabId = "" + getTime(new Date());

    this.state = {
      path: "http://127.0.0.1:8080/web",
      onBeforeUnloadWarning: false,
      name: "App",
      version: "1.18",
      tabId: tabId,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.fetch = this.fetch.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload(event) {
    const state = this.state;
    const onBeforeUnloadWarning = state.onBeforeUnloadWarning;

    if (onBeforeUnloadWarning) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  async fetch(servlet, parameters) {
    const parametersIsDefined = Validation.isDefined(parameters);
    parameters = parametersIsDefined ? parameters : {};

    if (parameters.tabId === undefined && parameters.sessionId === undefined) {
      const state = this.state;
      const path = state.path;
      const tabId = state.tabId;

      const url = path + servlet;
      const method = "POST";
      const headers = {};
      const parametersComposed = {
        ...parameters,
        tabId: tabId,
        sessionId: tabId,
      };

      const parametersComposedJSONString = JSON.stringify(parametersComposed);
      const responseJSONString = await window.fetch(url, {
        method: method,
        headers: headers,
        body: parametersComposedJSONString,
        credentials: "include",
      });
      const response = await responseJSONString.json();

      if ("success" in response) {
        if (response.success === false) {
          throw response.exception;
        }
      }

      return response;
    } else {
      throw new Error(
        'The "tabId/sessionId" field is protected and must not be used in the "parameters" object.'
      );
    }
  }

  render() {
    const { theme, classes, children, ...otherProps } = this.props;

    const contextValue = this.contextValue;
    const programList = [
      /*<AppProgram title="1" />*/
    ];
    const programDialogList = [];

    return (
      <Context.Provider value={contextValue}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
          <App.Layout {...otherProps}>
            <App.Header />
            <App.Body
              programList={programList}
              programDialogList={programDialogList}
            />
          </App.Layout>
        </LocalizationProvider>
      </Context.Provider>
    );
  }
}

export default App;
