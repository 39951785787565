import React from 'react';
import FieldWrapperVariant from './FieldWrapperVariant';
import PropTypes from 'prop-types';
// import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    fieldWrapperSingle: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'row nowrap',
      minWidth: theme.spacing(4) + 'px !important',
      margin: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    fieldWrapperRange: {
      flex: '1 1 calc(50% - ' + theme.spacing(2) + 'px)',
      display: 'flex',
      flexFlow: 'row nowrap',
      minWidth: theme.spacing(4) + 'px !important',
      margin: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    fieldWrapperCode: {
      flex: '1 1 calc(' + theme.spacing(15) + 'px - ' + theme.spacing(2) + 'px)',
      display: 'flex',
      flexFlow: 'row nowrap',
      minWidth: theme.spacing(4) + 'px !important',
      margin: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    fieldWrapperDescription: {
      flex: '1 1 calc(100% - ' + theme.spacing(15) + 'px - ' + theme.spacing(2) + 'px)',
      display: 'flex',
      flexFlow: 'row nowrap',
      minWidth: theme.spacing(4) + 'px !important',
      margin: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
  };
});
*/

class FieldWrapper extends React.Component {

  static Variant = FieldWrapperVariant;

  static propTypes = {
    variant: PropTypes.oneOf(Object.values(FieldWrapperVariant)).isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    variant: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      variant,
      children,
      ...otherProps
    } = this.props;

    var className = null;

    switch (variant) {
      case FieldWrapperVariant.Single:
        //className = classes.fieldWrapperSingle;
        className = (theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'row nowrap',
          minWidth: theme.spacing(4) + 'px !important',
          margin: theme.spacing(1),
          whiteSpace: 'nowrap',
        });
        break;
      case FieldWrapperVariant.Range:
        // className = classes.fieldWrapperRange;
        className = (theme) => ({
          flex: '1 1 calc(50% - ' + theme.spacing(2) + 'px)',
          display: 'flex',
          flexFlow: 'row nowrap',
          minWidth: theme.spacing(4) + 'px !important',
          margin: theme.spacing(1),
          whiteSpace: 'nowrap',
        });
        break;
      case FieldWrapperVariant.Code:
        // className = classes.fieldWrapperCode;
        className = (theme) => ({
          // flex: '1 1 calc(' + theme.spacing(15) + 'px - ' + theme.spacing(2) + 'px)',
          flex: '1 1 calc(' + theme.spacing(15) + ' - ' + theme.spacing(2) + ')',
          display: 'flex',
          flexFlow: 'row nowrap',
          minWidth: theme.spacing(4) + 'px !important',
          margin: theme.spacing(1),
          whiteSpace: 'nowrap',
        });
        break;
      case FieldWrapperVariant.Description:
        // className = classes.fieldWrapperDescription;
        className = (theme) => ({
          // flex: '1 1 calc(100% - ' + theme.spacing(15) + 'px - ' + theme.spacing(2) + 'px)',
          flex: '1 1 calc(100% - ' + theme.spacing(15) + ' - ' + theme.spacing(2) + ')',
          display: 'flex',
          flexFlow: 'row nowrap',
          minWidth: theme.spacing(4) + 'px !important',
          margin: theme.spacing(1),
          whiteSpace: 'nowrap',
        });
        break;
      default:
        className = null;
        break;
    };

    return (
      <Box
        /* className={className} */
        sx={className}
        {...otherProps}>
        {children}
      </Box>
    );
  }

}

// export default withStyles(styles, {withTheme: true})(FieldWrapper);
export default FieldWrapper;
