import React from "react";
import PropTypes from "prop-types";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Table from "../../mui-v4/table/Table";
import SaveIcon from "@mui/icons-material/Save";
import { showError } from "../AppMessage";

const Modo = {
  consulta: "consulta",
  alteracao: "alteracao",
};

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      iet_campeonato: PropTypes.number,
      iet_etapa: PropTypes.number,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        iet_campeonato: this.props.parameterMap.iet_campeonato,
        iet_etapa: this.props.parameterMap.iet_etapa,
      },
      recordArray: [],
      componentFocused: "null",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.iet_campeonato !== null && record.iet_etapa !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      const parameterMap = {
        recordArray: recordArray,
      };

      await this.fetch("/inscricaoEtapaAssociado/listar/alterar", parameterMap);

      this.showSnack("As alterações foram salvas", 2000);
      this.setState(
        (state, props) => {
          return {
            ...state,
            modo: Modo.consulta,
          };
        },
        () => {
          this.onFechar();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/inscricaoEtapaAssociado/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  render() {
    const state = this.state;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="800px" height="maximum">
        <Header
          avatar="E"
          caption={"Modalidades do Atirador na Etapa"}
          description={"Atribuir Modalidades da Etapa ao Atirador"}
          toolbar={
            <React.Fragment>
              <Menu.IconButton name="salvar" icon={<SaveIcon />} text="Salvar" onClick={this.onSalvar} />
              <Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />
            </React.Fragment>
          }
        />
        <Table
          name="table"
          caption="Alterar as Modalidades do Atirador na Etapa"
          collapsable={false}
          collapsed={false}
          expanded={true}
          recordArray={recordArray}
          selectionType="multiple"
          selectionColumnName="bSelected"
          toolbar={null}
          menu={null}
          columnArray={[
            <Table.ColumnString name="iet_modalidade_abreviacao" label="Abreviação" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
            <Table.ColumnString name="iet_modalidade_nome" label="Modalidade" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
            <Table.ColumnString name="iet_tipoSumula" label="Tipo Sumula" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
          ]}
          fixedRowCount={1}
          fixedColumnCount={1}
        />
      </ProgramLayout>
    );
  }
}

export default Listar;
