import MenuDirection from './MenuDirection';
import MenuIconButton from './MenuIconButton';
import MenuItem from './MenuItem';
import MenuItemList from './MenuItemList';

const Menu = {
  Direction: MenuDirection,
  IconButton: MenuIconButton,
  Item: MenuItem,
  ItemList: MenuItemList,
};

export default Menu;
