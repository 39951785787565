import React from "react";
import FieldInput from "./FieldInput";
import Context from "../context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Validation from "../validation/Validation";
// import withStyles from '@mui/styles/withStyles';

/*
const styles = ((theme) => {
  return {
    test: {
      display: 'none',
    },
  };
});
*/

class FieldInputCombo extends FieldInput {
  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    recordArray: PropTypes.arrayOf(PropTypes.object),
    url: PropTypes.string,
    urlParameterMap: PropTypes.object,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    caption: null,
    record: null,
    recordArray: null,
    url: null,
    urlParameterMap: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const recordArray = Validation.isArray(props.recordArray) ? props.recordArray : [];

    this.state = {
      ...this.state,
      recordArray: recordArray,
      open: false,
    };

    /* sets the value after the recordList was changed */
    const value = this.getValue();
    const valueString = this.formatValue(value);

    this.state = {
      ...this.state,
      value: valueString,
    };
    /* sets the value after the recordList was changed */

    this.reference = {
      autocomplete: null,
      textField: null,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.loadRecordArray = this.loadRecordArray.bind(this);
    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.onKeyDownEsc = this.onKeyDownEsc.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.parseValue = this.parseValue.bind(this);
    this.render = this.render.bind(this);
  }

  /* novo */
  componentDidUpdate(prevProps) {
    const props = this.props;
    const recordArray = props.recordArray;
    const prevPropsRecordArray = prevProps.recordArray;
    const recordArrayIsChanged = prevPropsRecordArray !== recordArray;

    if (recordArrayIsChanged) {
      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArray,
        };
      });
    }
  }
  /* novo */

  componentDidMount() {
    this.loadRecordArray();
  }

  loadRecordArray() {
    const props = this.props;
    const url = props.url;
    const urlIsDefined = Validation.isDefined(url);

    if (urlIsDefined) {
      this.setState(
        (state, props) => {
          return {
            ...state,
            recordArray: [],
          };
        },
        async () => {
          try {
            var urlParameterMap = props.urlParameterMap;
            const urlParameterMapIsDefined = Validation.isDefined(urlParameterMap);

            urlParameterMap = urlParameterMapIsDefined ? urlParameterMap : {};

            /* Rename the Refence fields */
            urlParameterMap = {
              ...urlParameterMap,
              value: null,
              description: null,
            };
            /* Rename the Refence fields */

            const context = this.context;
            const app = context.app;
            const responseRecord = await app.fetch(url, urlParameterMap);

            if (responseRecord.success === true) {
              const name = props.name;
              const caption = props.caption;
              const recordArray = responseRecord.recordArray;

              var nameExists = true;
              var captionExists = true;

              recordArray.forEach((record) => {
                /* Rename the Refence fields */
                record[name] = record.value;
                record[caption] = record.description;
                /* Rename the Refence fields */

                nameExists = nameExists && Validation.isDefined(record[name]);
                captionExists = captionExists && Validation.isDefined(record[caption]);
              });

              if (nameExists && captionExists) {
                this.setState(
                  (state, props) => {
                    return {
                      ...state,
                      recordArray: recordArray,
                    };
                  },
                  () => {
                    /* sets the value after the recordList was changed */
                    const value = this.getValue();
                    const valueString = this.formatValue(value);

                    this.setState((state, props) => {
                      return {
                        ...state,
                        value: valueString,
                      };
                    });
                    /* sets the value after the recordList was changed */
                  }
                );
              }
            } else {
              throw responseRecord.exception;
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    }
  }

  focus() {
    const reference = this.reference;
    const textField = reference.textField;

    const props = this.props;
    const url = props.url;
    const urlIsDefined = Validation.isDefined(url);

    const state = this.state;
    const recordArray = state.recordArray;
    const recordArrayIsEmpty = Validation.isEmpty(recordArray);

    if (urlIsDefined && recordArrayIsEmpty) {
      setTimeout(() => {
        textField.select();
        textField.focus();
      }, 500);
    } else {
      textField.select();
      textField.focus();
    }
  }

  getPosition() {
    const reference = this.reference;
    const autocomplete = reference.autocomplete;
    const position = autocomplete.getBoundingClientRect();

    return position;
  }

  onKeyDownEsc(event) {
    const target = event.target;
    const valueString = target.value;
    const value = this.parseValue(valueString);
    const valueCurrent = this.getValue();
    const valueIsChanged = value !== valueCurrent;

    if (valueIsChanged) {
      /* This workaround is required in order to the esc command to reload the current value */
      const state = this.state;
      const stateValue = state.value;

      this.setState(
        (state, props) => {
          return {
            ...state,
            value: null,
          };
        },
        () => {
          this.setState((state, props) => {
            return {
              ...state,
              value: stateValue,
            };
          });
        }
      );
    } else {
      super.onKeyDownEsc(event);
    }
  }

  formatValue(value) {
    const valueIsDefined = Validation.isDefined(value);

    if (valueIsDefined) {
      const props = this.props;
      const name = props.name;
      const caption = props.caption;

      const state = this.state;
      const recordArray = state.recordArray;

      var valueFormatted = null;

      if (Validation.isArray(recordArray)) {
        recordArray.forEach((record) => {
          if (record[name] === value) {
            valueFormatted = record[caption];
          }
        });
      }

      return valueFormatted;
    } else {
      return null;
    }
  }

  parseValue(value) {
    const valueIsDefined = Validation.isDefined(value);

    if (valueIsDefined) {
      const props = this.props;
      const name = props.name;
      const caption = props.caption;

      const state = this.state;
      const recordArray = state.recordArray;

      var valueParsed = null;

      recordArray.forEach((record) => {
        if (record[caption] === value) {
          valueParsed = record[name];
        }
      });

      return valueParsed;
    } else {
      return null;
    }
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      name,
      caption,
      record,
      recordArray: propRecordArray,
      url,
      urlParameterMap,
      label,
      helperText: propHelperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      children,
      ...otherProps
    } = this.props;

    const {
      value,
      error,
      /* helperText, */
      recordArray,
      open,
    } = this.state;

    const recordArrayIsEmpty = Validation.isEmpty(recordArray);

    var valueObject = null;

    if (value !== null) {
      recordArray.forEach((record) => {
        if (record[caption] === value) {
          valueObject = record;
        }
      });
    }

    return (
      <Autocomplete
        loading={recordArrayIsEmpty ? true : false}
        loadingText='Carregando...'

        /*
        classes={{
          clearIndicator: classes.test,
        }}
        */
       /*
       sx={{
        "& .MuiAutocomplete-clearIndicator": {
          display: 'none',
        }
       }}
       */
        clearIcon={null}

        value={valueObject}
        options={recordArray}
        getOptionLabel={(option) => {return Validation.isDefined(option[caption]) ? option[caption] : 'Undefined'}}
        isOptionEqualToValue={(option, value) => {return option[caption] === value[caption]}}
        autoHighlight={true}
        noOptionsText='Nenhum registro'
        ref={(autocomplete) => {
          const reference = this.reference;

          reference.autocomplete = autocomplete;
        }}
        style={{
          flex: '1 1 auto',
          margin: '0px',
        }}
        onChange={(event, value) => {
          event.target = {
            value: value !== null ? value[this.props.caption] : null,
            select: (() => {this.reference.textField.select();}),
            setSelectionRange: (() => {this.reference.textField.select();}),
          }

          this.onChange(event);
          this.onKeyDownEnter(event);
        }}
        onFocus={(event) => {this.onFocus(event);}}
        open={open}
        onOpen={(event) => {
          this.setState({open: true});
        }}
        onClose={(event) => {
          this.setState({open: false});
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              size="small"

              label={label}
              error={error}
              helperText={null /* helperText */}
              variant='outlined'
              margin='dense'
              autoComplete='off'
              style={{
                flex: '1 1 auto',
                margin: '0px',
              }}
              InputLabelProps={{
                style: {
                  maxWidth: 'calc(100% - 32px)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                },
              }}
              inputRef={(textField) => {
                const reference = this.reference;

                reference.textField = textField;
              }}

              onKeyDown={(event) => {
                const keyCode = event.keyCode;

                switch (keyCode) {
                  case 13:
                    this.setState({open: !open});
                    break;
                  case 27:
                  case 37:
                  case 39:
                  case 38:
                  case 40:
                    if (!open) {
                      this.onKeyDown(event);
                      event.preventDefault();
                      event.stopPropagation();
                    }
                    break;
                  default:
                    break;
                }
              }}
            />
          );
        }}
        {...otherProps}
      />
    );
  }
}

// export default FieldInputCombo;
// export default withStyles(styles, {withTheme: true})(FieldInputCombo);
export default FieldInputCombo;
