import React from "react";
import FieldInput from "./FieldInput";
import FieldInputFormattedVariant from "./FieldInputFormattedVariant";
import Context from "../context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from "../validation/Validation";
import TextField from "@mui/material/TextField";
import MaskedInput from "react-text-mask";

class FieldInputFormatted extends FieldInput {
  static Variant = FieldInputFormattedVariant;

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    variant: PropTypes.oneOf(Object.values(FieldInputFormattedVariant)).isRequired,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    variant: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const variant = props.variant;
    var mask = [];
    var maskPipe = null;

    switch (variant) {
      case FieldInputFormattedVariant.Telefone:
        mask = ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
        maskPipe = null;
        break;
      case FieldInputFormattedVariant.Placa:
        mask = [/[A-Z]/i, /[A-Z]/i, /[A-Z]/i, "-", /\d/, /\d/, /\d/, /\d/];
        maskPipe = (conformedValue) => {
          return conformedValue.toUpperCase();
        };
        break;
      case FieldInputFormattedVariant.Endereco:
        mask = [/\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/];
        maskPipe = (conformedValue) => {
          return conformedValue.toUpperCase();
        };
        break;
      case FieldInputFormattedVariant.Produto:
        mask = [/\d/, ".", /\d/, ".", /\d/, /\d/, /\d/, /\d/];
        maskPipe = (conformedValue) => {
          return conformedValue.toUpperCase();
        };
        break;
      case FieldInputFormattedVariant.CPF:
        mask = [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/];
        maskPipe = (conformedValue) => {
          return conformedValue.toUpperCase();
        };
        break;
      default:
        mask = [];
        maskPipe = null;
        break;
    }

    this.state = {
      ...this.state,
      mask: mask,
      maskPipe: maskPipe,
      maskVisible: false,
      maskPlaceholder: "_",
    };

    this.reference = {
      textField: null,
    };

    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.validate = this.validate.bind(this);
    this.validateIncomplete = this.validateIncomplete.bind(this);
    this.render = this.render.bind(this);
    // this.renderInput = this.renderInput.bind(this);
  }

  focus() {
    try {
      const reference = this.reference;
      const textField = reference.textField;

      textField.select();
      textField.focus();
    } catch {}
  }

  getPosition() {
    try {
      const reference = this.reference;
      const textField = reference.textField;
      const position = textField.getBoundingClientRect();

      return position;
    } catch {
      return null;
    }
  }

  async validate(value) {
    const valueIsDefined = typeof value !== "undefined";

    if (!valueIsDefined) {
      value = this.getValue();
    }

    await this.validateRequired(value);
    await this.validateIncomplete(value);
    await this.validateFunction(value);
  }

  async validateIncomplete(value) {
    const valueIsString = Validation.isString(value);

    if (valueIsString) {
      const state = this.state;
      const mask = state.mask;
      const maskLength = mask.length;
      const maskPlaceholder = state.maskPlaceholder;
      const valueLength = value.length;
      const valueIsIncomplete = maskLength !== valueLength || value.includes(maskPlaceholder);

      if (valueIsIncomplete) {
        const valueCurrent = this.getValue();
        const error = {
          field: this,
          valueCurrent: valueCurrent,
          value: value,
          message: "Campo incompleto",
        };

        throw error;
      }
    }
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      name,
      record,
      label,
      helperText: propHelperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      variant,
      children,
      ...otherProps
    } = this.props;

    const {
      value,
      error,
      /* helperText, */
      mask,
      maskPipe,
      maskVisible,
      maskPlaceholder,
    } = this.state;

    /*
    return (
      <TextField
        label={label}
        value={value}
        error={error}
        helperText={null}
        variant='outlined'
        margin='dense'
        autoComplete='off'
        style={{
          flex: '1 1 auto',
          margin: '0px',
        }}
        InputLabelProps={{
          style: {
            maxWidth: 'calc(100% - 32px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          },
        }}
        InputProps={{
          inputComponent: MaskedInput,
          inputProps: {
            mask: mask,
            pipe: maskPipe,
            showMask: maskVisible,
            placeholderChar: maskPlaceholder,
            reference: this.reference,
            ref: (ref) => {
              this.reference.textField = (ref != null ? ref.inputElement : null);
            }
          },
        }}
        onChange={(event) => {this.onChange(event);}}
        onFocus={(event) => {this.onFocus(event);}}
        onBlur={(event) => {this.onBlur(event);}}
        onKeyDown={(event) => {this.onKeyDown(event);}}
        {...otherProps}
      />
    );
    */

    return (
      <MaskedInput
        //{...field}
        ref={(ref) => {
          this.reference.textField = (ref != null ? ref.inputElement : null);
        }}

        mask={mask}
        pipe={maskPipe}
        showMask={maskVisible}
        placeholderChar={maskPlaceholder}

        //guide={false}
        //keepCharPositions

        onChange={(event) => {this.onChange(event);}}
        onFocus={(event) => {this.onFocus(event);}}
        onBlur={(event) => {this.onBlur(event);}}
        onKeyDown={(event) => {this.onKeyDown(event);}}


        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}

            size="small"

            label={label}
            value={value}
            error={error}
            helperText={null}
            variant='outlined'
            margin='dense'
            autoComplete='off'
            style={{
              flex: '1 1 auto',
              margin: '0px',
            }}
            InputLabelProps={{
              style: {
                maxWidth: 'calc(100% - 32px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              },
            }}
    
            // variant="outlined"
            // label="Telefon Numarası"
            // fullWidth
            // size="small"
            // placeholder="Telefon numaranızı giriniz"
            // error={!!errors?.phone}
            // helperText={errors?.phone?.message}
            /*
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone sx={{ color: "text.disabled" }} />
                </InputAdornment>
              ),
            }}
            */

          />
        )}
    />
    );
  }
}

export default FieldInputFormatted;
