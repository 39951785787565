import React from "react";
import PropTypes from "prop-types";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    fieldListRow: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'row wrap',
    },
  };
});
*/

class FieldListRow extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <Box
        /* className={classes.fieldListRow} */
        sx={(theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'row wrap',
        })}
        {...otherProps}
      >
        {children}
      </Box>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(FieldListRow);
export default FieldListRow;
