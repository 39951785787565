import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import { showError } from "../AppMessage";
import print from "print-js";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        as_entidade: this.context.app.state.vp_entidade,
        as_entidade_nome: this.context.app.state.vp_entidadeNome,
        as_associado: null,
        as_associado_nome: null,
        as_dataFiliacao: null,
        as_validadeFiliacao: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;

      if (record.as_associado !== null) {
        const recordNew = await this.getRecord(record);

        this.setState((state, props) => {
          return {
            ...state,
            record: {
              ...state.record,
              as_dataFiliacao: recordNew.as_dataFiliacao,
              as_validadeFiliacao: recordNew.as_validadeFiliacao,
            },
          };
        });
      }
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;

      if (record.as_associado !== null) {
        const response = await this.fetch("/declaracaoFiliacao/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecione um Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/declaracaoFiliacao/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const vp_entidade = this.getVP("vp_entidade");
    const entidadeDisabled = vp_entidade === 1 ? false : true;

    return (
      <ProgramLayout program={this} width="600px" height="minimum">
        <Header
          avatar="P"
          caption={"Declaração de Filiação"}
          description={"Declaração de Filiação do Associado"}
          toolbar={
            <React.Fragment>
              <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
              <Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />
            </React.Fragment>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/entidade/referenciar"}
                    urlParameterMap={{}}
                    onChange={() => {
                      this.setState(
                        (state, props) => {
                          return {
                            ...state,
                            record: {
                              ...state.record,
                              as_associado: null,
                              as_associado_nome: null,
                              as_dataFiliacao: null,
                              as_validadeFiliacao: null,
                            },
                          };
                        },
                        () => {
                          this.onAtualizar();
                        }
                      );
                    }}
                    columnArray={[<Table.ColumnNumber name="en_entidade" label="Entidade" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="en_nome" label="Nome Entidade" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="as_entidade" record={record} label="Entidade" prefix="" thousandSeparator="" decimalScale={0} disabled={entidadeDisabled} />
                    <Field.Input.String name="as_entidade_nome" record={record} label="Nome Entidade" disabled={entidadeDisabled} />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.as_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="as_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="as_associado_nome" record={record} label="Associado " />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name="as_dataFiliacao" record={record} label="Data Filiação" onChange={this.onAtualizar} disabled={true} />
                  <Field.Input.Date name="as_validadeFiliacao" record={record} label="Valida até" onChange={this.onAtualizar} disabled={true} />
                </Field.Range>
              </Field.List.Row>
            </Group>
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
