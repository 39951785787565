import React from 'react';
import PropTypes from 'prop-types';
import MenuDirection from './MenuDirection';
import Validation from '../validation/Validation';
import IconButton from '@mui/material/IconButton';
import MenuItemList from './MenuItemList';
import Component from '../component/Component';
import Context from '../context/Context';
import Tooltip from '@mui/material/Tooltip';

class MenuIconButton extends Component {

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    anchorOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(Object.values(MenuDirection.Vertical)).isRequired,
      horizontal: PropTypes.oneOf(Object.values(MenuDirection.Horizontal)).isRequired,
    }).isRequired,
    transformOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(Object.values(MenuDirection.Vertical)).isRequired,
      horizontal: PropTypes.oneOf(Object.values(MenuDirection.Horizontal)).isRequired,
    }).isRequired,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    name: null,
    text: null,
    icon: null,
    anchorOrigin: {vertical: MenuDirection.Vertical.Top, horizontal: MenuDirection.Horizontal.Left},
    transformOrigin: {vertical: MenuDirection.Vertical.Top, horizontal: MenuDirection.Horizontal.Left},
    onClick: null,
    onClose: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      anchor: null,
    };

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  focus() {
    const actionMap = this.actionMap;

    actionMap.focusVisible();
  }

  getPosition() {
    const element = this.element;
    const position = (element === null ? null : element.getBoundingClientRect());

    return position;
  }


    onKeyDown(event) {
      const keyCode = event.keyCode;

      switch(keyCode) {
        case 27:
          this.onKeyDownEsc(event);
          break;
        case 37:
          this.onKeyDownArrowLeft(event);
          break;
        case 39:
          this.onKeyDownArrowRight(event);
          break;
        case 38:
          this.onKeyDownArrowUp(event);
          break;
        case 40:
          this.onKeyDownArrowDown(event);
          break;
        default:
      }
    }

    onKeyDownEsc(event) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;
      const programFocusLeftFailed = (program.focusLeft(this) === false);

      if (programFocusLeftFailed) {
        const programFocusUpFailed = (program.focusUp(this) === false);

        if (programFocusUpFailed) {
          const programManager = context.programManager;

          programManager.close();
        }
      }
    }

    onKeyDownArrowLeft(event) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;
      program.focusLeft(this);
    }

    onKeyDownArrowRight(event) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;
      program.focusRight(this);
    }

    onKeyDownArrowUp(event) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;
      program.focusUp(this);
    }

    onKeyDownArrowDown(event) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;
      program.focusDown(this);
    }

  onClick(event) {
    const anchor = event.currentTarget;

    this.setState((state, props) => {
      return {
        ...state,
        anchor: anchor,
      };
    });

    const props = this.props;
    const propsOnClick = props.onClick;
    const propsOnClickIsFunction = Validation.isFunction(propsOnClick);

    if (propsOnClickIsFunction) {
      propsOnClick();
    }

    const children = props.children;
    const childrenArray = React.Children.toArray(children);
    const childrenArrayIsEmpty = Validation.isEmpty(childrenArray);

    if (childrenArrayIsEmpty) {
      this.onClose();
    }
  }

  onClose(event) {
    this.setState((state, props) => {
      return {
        ...state,
        anchor: null,
      };
    });

    const props = this.props;
    const propsOnClose = props.onClose;
    const propsOnCloseIsFunction = Validation.isFunction(propsOnClose);

    if (propsOnCloseIsFunction) {
      propsOnClose();
    }
  }

  render() {
    const {
      theme,
      classes,
      name,
      text,
      icon,
      anchorOrigin,
      transformOrigin,
      onClick,
      onClose,
      children,
      ...otherProps
    } = this.props;

    const {
      anchor,
    } = this.state;

    const textIsDefined = Validation.isDefined(text);

    return (
      <React.Fragment>
        {(textIsDefined) &&
          <Tooltip title={text}>
            <span>
              <IconButton
                ref={(iconButton) => {this.element = iconButton;}}
                action={(actionMap) => {this.actionMap = actionMap;}}
                onClick={(event) => {this.onClick(event);}}
                onKeyDown={(event) => {this.onKeyDown(event);}}
                {...otherProps}
                size="large">
                {icon}
              </IconButton>
            </span>
          </Tooltip>
        }
        {(!(textIsDefined)) &&
          <IconButton
            ref={(iconButton) => {this.element = iconButton;}}
            action={(actionMap) => {this.actionMap = actionMap;}}
            onClick={(event) => {this.onClick(event);}}
            onKeyDown={(event) => {this.onKeyDown(event);}}
            {...otherProps}
            size="large">
            {icon}
          </IconButton>
        }
        <MenuItemList
          anchor={anchor}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={(event) => {this.onClose();}}
        >
          {children}
        </MenuItemList>
      </React.Fragment>
    );
  }

}

export default MenuIconButton;
