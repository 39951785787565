import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      et_etapa: PropTypes.number.isRequired,
      et_nome: PropTypes.string.isRequired,
      as_associado: PropTypes.number.isRequired,
      as_nome: PropTypes.string.isRequired,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;

    this.state = {
      ...this.state,
      record: {
        et_etapa: parameterMap.et_etapa,
        et_nome: parameterMap.et_nome,
        as_associado: parameterMap.as_associado,
        as_nome: parameterMap.as_nome,
        su_entidade: parameterMap.su_entidade,
        su_entidade_nome: parameterMap.su_entidade_nome,
        su_campeonato: parameterMap.su_campeonato,
        su_campeonato_nome: parameterMap.su_campeonato_nome,
        su_etapa: parameterMap.su_etapa,
        su_etapa_nome: parameterMap.su_etapa_nome,
      },
      valorEditavel: false,
      componentFocused: "psa_produtoServico",
      success: false,
      result: null,
      ...props.state,
    };

    this.onAtualizar = this.onAtualizar.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onAtualizar() {
    const state = this.state;
    const record = state.record;
    const combo = this.getComponent("psa_produtoServico");
    const comboState = combo.state;
    const comboRecordArray = comboState.recordArray;

    let comboRecordSelected = null;

    comboRecordArray.forEach((comboRecord, comboRecordIndex) => {
      if (comboRecord.psa_produtoServico === record.psa_produtoServico) {
        comboRecordSelected = comboRecord;
      }
    });

    if (comboRecordSelected !== null) {
      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,
            psa_quantidade: 1,
            psa_tipo: comboRecordSelected.ps_tipo,
            psa_valor: comboRecordSelected.ps_valor,
          },
          valorEditavel: comboRecordSelected.ps_valorEditavel === "S",
        };
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/produtoServicoAssociado/editar/inserir";
      const parameterMap = record;

      await this.fetch(url, parameterMap);

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    return true;
  }

  render() {
    const state = this.state;
    const record = state.record;
    const valorEditavel = state.valorEditavel;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="E" caption={"Adicionar Produto/Serviço"} description={"Editor de Produto/Serviço"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="produtoServico" caption="Produto/Serviço" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="psa_produtoServico"
                caption="psa_produtoServico_descricao"
                record={record}
                url="/produtoServico/referenciarExcetoExclussivoSistema"
                urlParameterMap={{
                  ps_entidade: record.su_entidade,
                }}
                label="Produto/Serviço"
                onChange={this.onAtualizar}
              />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="psa_tipo"
                caption="psa_tipo_descricao"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "produtoServico",
                  flg_campo: "ps_tipo",
                }}
                label="Tipo"
                disabled={true}
              />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Number record={record} name="psa_quantidade" label="Quantidade" prefix="" thousandSeparator="." decimalScale={2} />
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="psa_valor" label="Valor" prefix="" thousandSeparator="." decimalScale={2} disabled={!valorEditavel} />
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
