import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";
// import format from "date-fns/format";
// import addMonths from "date-fns/addMonths";
import print from "print-js";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    /*
    const dataFinal = format(new Date(), "yyyy-MM-dd");
    const dataInicial = format(addMonths(new Date(), -12), "yyyy-MM-dd");
    */

    this.state = {
      ...this.state,
      record: {
        du_entidade: this.context.app.state.vp_entidade,
        du_entidade_nome: this.context.app.state.vp_entidadeNome,
        du_associado: this.context.app.state.vp_associado,
        du_associado_nome: this.context.app.state.vp_associadoNome,
        du_dataInicial: null,
        du_dataFinal: null,
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.onGerarPIX = this.onGerarPIX.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.du_associado !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/fichaFinanceiraAssociado/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecione um Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  async onGerarPIX() {
    try {
      const state = this.state;
      // const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const recordArrayFiltered = recordArray.filter((record, recordIndex) => {
          return record.bSelected === true && record.saldo > 0;
        });

        if (recordArrayFiltered.length > 0) {
          const parameterMap = { recordList: recordArrayFiltered };
          const response = await this.fetch("/enviarCobrancaPIX/listar/carregarPDF", parameterMap);
          //        const response = await this.fetch("/fichaFinanceiraAssociado/listar/carregarPDF", record);
          const pdfContent = response.pdfContent;

          print({
            printable: pdfContent,
            type: "pdf",
            base64: true,
          });
        } else {
          showError(this, "Selecione uma Duplicata com Saldo em aberto");
        }
      } else {
        showError(this, "Selecione um Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/fichaFinanceiraAssociado/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const vp_entidade = this.getVP("vp_entidade");
    const entidadeDisabled = (vp_entidade === 1 ? false : true);
    const vp_associado = this.getVP("vp_associado");    
    const associadoDisabled = (vp_associado == null ? false : true);


    return (
      <ProgramLayout
        program={this}
        width="1280px"
        height="600px"
      >
        <Header
          avatar="P"
          caption={"Ficha Financeira Associado"}
          description={"Ficha Financeira Associado"}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="filtro"
              caption="Filtro de pesquisa"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/entidade/referenciar"}
                    urlParameterMap={{}}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="en_entidade" label="Entidade" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="en_nome" label="Nome Entidade" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="du_entidade" record={record} label='Entidade' prefix="" thousandSeparator="" decimalScale={0} disabled={entidadeDisabled}/>
                    <Field.Input.String name="du_entidade_nome" record={record} label="Nome Entidade" disabled={entidadeDisabled}/>
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="du_associado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={associadoDisabled}/>
                    <Field.Input.String name="du_associado_nome" record={record} label="associado " disabled={associadoDisabled}/>
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List.Row>
                <Field.Range>
                  <Field.Input.Date name='du_dataInicial' record={record} label='Data Vencto Inicial' onChange={this.onAtualizar}/>
                  <Field.Input.Date name='du_dataFinal' record={record} label='Data Vencto Final' onChange={this.onAtualizar}/>
                </Field.Range>
              </Field.List.Row>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Ficha Financeira Associado"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>
                  <Menu.IconButton name="gerarPIX" icon={<AttachMoneyIcon/>} text="Gerar PIX" onClick={this.onGerarPIX}/>

                </React.Fragment>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnNumber name="du_numero" label="Duplicata" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnNumber name="du_parcela" label="Parcela" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnDate name="du_dataEmissao" label="Emissão" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="du_dataVencimento" label="Vencimento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="du_valor" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnNumber name="saldo" label="Saldo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnString name="du_situacaoDuplicata" label="Situacão" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="du_banco_nome" label="Banco" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
