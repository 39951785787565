import React from "react";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import ProgramColumnWidth from "./ProgramColumnWidth";
import Validation from "../validation/Validation";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    programColumn: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      overflow: 'hidden',
    },
  };
});
*/

class ProgramColumn extends React.Component {
  static Width = ProgramColumnWidth;

  static propTypes = {
    /*
    width: AirbnbPropTypes.or([
      AirbnbPropTypes.stringEndsWith('px'),
      PropTypes.oneOf(Object.values(ProgramColumnWidth)),
    ]).isRequired,
    */
    expanded: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    width: null,
    expanded: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      style,
      width,
      expanded,
      children,
      ...otherProps
    } = this.props;

    const expandedIsDefined = Validation.isDefined(expanded);

    const widthIsMaximum = (width === ProgramColumnWidth.maximum);
    const widthIsExpanded = ((expandedIsDefined) && (expanded === true));

    var styleComposed = {
      ...(widthIsMaximum  ? {width: '100%'}       : {width: width}),
      ...(widthIsExpanded ? {flex: '9999 1 auto'} : {flex: '1 1 auto'}),
      ...style,
    };

    return (
      <Box
        /* className={classes.programColumn} */
        /* style={styleComposed} */
        sx={(theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'column nowrap',
          overflow: 'hidden',
          ...styleComposed,
        })}

        {...otherProps}
      >
        {children}
      </Box>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(ProgramColumn);
export default ProgramColumn;
