import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { Scrollbars } from "react-custom-scrollbars-2";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    programManagerLayout: {
      flex: '1 1 auto',
    },
  };
});
*/

class ProgramManagerLayoutDialog extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    onClose: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
        disableEscapeKeyDown={true}
        PaperProps={{
          /*
          style: {
            maxWidth: 'calc(100vw - ' + theme.spacing(4) + 'px)',
            maxHeight: 'calc(100vh - ' + theme.spacing(4) + 'px)',
            margin: 0,
          }
          */
          sx:(theme) => ({
            maxWidth: 'calc(100vw - 16px)',
            maxHeight: 'calc(100vh - 16px)',
            margin: '0px',           
          })
        }}
        {...otherProps}>
        <Scrollbars
          /* className={classes.programManagerLayout} */
          style={{flex: '1 1 auto',}}
          autoHeight={true}
          autoHeightMin={0}
          autoHeightMax={'calc(100vh - ' + /*theme.spacing(4) -> (4*4=16) */ 16  + 'px)'}
        >
          {children}
        </Scrollbars>
      </Dialog>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(ProgramManagerLayoutDialog);
export default ProgramManagerLayoutDialog;
