import React from "react";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Program from "../mui-v4/program/Program";
import Context from "../mui-v4/context/Context";
import ProgramLayout from "../mui-v4/program/ProgramLayout";
import Header from "../mui-v4/header/Header";
import Group from "../mui-v4/group/Group";
// import withStyles from '@mui/styles/withStyles';
import Button from "../mui-v4/button/Button";
import ButtonBar from "../mui-v4/button/ButtonBar";
import Validation from "../mui-v4/validation/Validation";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/*
const styles = (theme) => {
  return {
    messageWrapper: {
      margin: theme.spacing(1),
    },
  };
};
*/

class AppMessage extends Program {
  static contextType = Context;

  static propTypes = {
    caption: PropTypes.string.isRequired,
    messageList: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultButtonLabel: PropTypes.string.isRequired,
    leftButtonLabelList: PropTypes.arrayOf(PropTypes.string),
    rightButtonLabelList: PropTypes.arrayOf(PropTypes.string),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    caption: null,
    messageList: [],
    defaultButtonLabel: null,
    leftButtonLabelList: [],
    rightButtonLabelList: [],
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const defaultButtonLabel = Validation.isDefined(props.defaultButtonLabel) ? props.defaultButtonLabel : "";
    const defaultButtonName = defaultButtonLabel.replace(" ", "_");

    this.state = {
      ...this.state,
      result: null,
      componentFocused: defaultButtonName,
      ...props.state,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.render = this.render.bind(this);
    this.renderMessageList = this.renderMessageList.bind(this);
    this.renderButtonList = this.renderButtonList.bind(this);
  }

  onButtonClick(buttonLabel) {
    this.setState(
      (state, props) => {
        return {
          ...state,
          result: buttonLabel,
        };
      },
      () => {
        const context = this.context;
        const programManager = context.programManager;

        programManager.close();
      }
    );
  }

  /* prettier-ignore */
  render() {
    const props = this.props;
    const caption = props.caption;
    const leftButtonLabelList = props.leftButtonLabelList;
    const rightButtonLabelList = props.rightButtonLabelList;

    return (
      <ProgramLayout
        program={this}
      >
        <Header
          avatar='S'
          caption={caption}
          description={null}
          toolbar={null}
        />
        <Group
          name='group1'
          caption={null}
          expanded={false}
          collapsable={false}
          collapsed={false}
          toolbar={null}
          menu={null}
        >
          {this.renderMessageList()}
        </Group>
        <ButtonBar
          leftButtonList={this.renderButtonList(leftButtonLabelList)}
          rightButtonList={this.renderButtonList(rightButtonLabelList)}
        />
      </ProgramLayout>
    );
  }

  /* prettier-ignore */
  renderMessageList() {
    const props = this.props;
    const messageList = Validation.isArray(props.messageList) ? props.messageList : ["Nenhuma mensagem disponível"];
    // const classes = props.classes;
    var   messageElementList = [];

    messageList.forEach((message, messageIndex) => {
      messageElementList.push(
        <Box
          /* className={classes.messageWrapper} */
          sx={(theme) => ({
            margin: theme.spacing(1),
          })}
          key={messageIndex}
        >
          <Typography variant='body1'>
            {message}
          </Typography>
        </Box>
      );
    });

    return messageElementList;
  }

  /* prettier-ignore */
  renderButtonList(buttonLabelList) {
    var   buttonList = [];

    buttonLabelList.forEach((buttonLabel, buttonLabelIndex) => {
      const buttonName = buttonLabel.replace(' ', '_');
      const button = (
        <Button
          name={buttonName}
          variant='contained'
          size='small'
          onClick={(event) => {this.onButtonClick(buttonLabel);}}
        >
          {buttonLabel}
        </Button>
      );

      buttonList.push(button);
    });

    return buttonList;
  }
}

export function showError(program, error, onClose = null) {
  const context = program.context;
  const programManager = context.programManager;

  var errorMessage = "";

  const errorIsString = Validation.isString(error);

  if (errorIsString) {
    errorMessage = error;
  } else {
    const errorIsObject = Validation.isObject(error);

    if (errorIsObject) {
      errorMessage = error.message;
    }
  }

  const errorMessageArray = errorMessage !== null ? errorMessage.split("\n") : "null";

  const onCloseIsFunction = Validation.isFunction(onClose);

  const AppMessageWithStyles = AppMessage; //withStyles(styles, {withTheme: true})(AppMessage)

  programManager.openDialog(<AppMessageWithStyles caption="Mensagem" messageList={errorMessageArray} defaultButtonLabel="Ok" leftButtonLabelList={[]} rightButtonLabelList={["Ok"]} onClose={onCloseIsFunction ? onClose : (appMessage) => {}} />);
}

// export default withStyles(styles, {withTheme: true})(AppMessage);
export default AppMessage;
