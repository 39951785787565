import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      ha_habitualidade: PropTypes.number,
      ha_entidade: PropTypes.number,
      ha_entidade_nome: PropTypes.string,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.ha_habitualidade) ? false : true;
    const componentFocused = "ha_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onRemover = this.onRemover.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/habitualidade/editar/criar" : "/habitualidade/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/habitualidade/editar/inserir" : "/habitualidade/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onRemover() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/habitualidade/editar/remover";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/habitualidade/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="P" caption={recordIsNew ? "Adicionar Habitualidade" : "Editar Habitualidade"} description={"Editor de Habitualidade"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="habitualidade" caption="habitualidade" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/entidade/referenciar"}
                urlParameterMap={{}}
                onChange={() => {
                  this.setState((state, props) => {
                    return {
                      ...state,
                      record: {
                        ...state.record,
                        ar_associado: null,
                        ar_associado_nome: null,
                      },
                    };
                  });
                }}
                columnArray={[<Table.ColumnNumber name="en_entidade" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="en_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="ha_entidade" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                <Field.Input.String name="ha_entidade_nome" record={record} label="Entidade" disabled={true} />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/associado/referenciar"}
                urlParameterMap={() => {
                  return {
                    as_entidade: record.ha_entidade,
                  };
                }}
                onChange={() => {
                  this.setState((state, props) => {
                    return {
                      ...state,
                      record: {
                        ...state.record,
                        ha_arma: null,
                        ha_marca_descricao: null,
                      },
                    };
                  });
                }}
                columnArray={[<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="ha_associado" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={!recordIsNew} />
                <Field.Input.String name="ha_associado_nome" record={record} label="Associado " disabled={!recordIsNew} />
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="ha_evento"
                caption="ha_evento_descricao"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "habitualidade",
                  flg_campo: "ha_evento",
                }}
                label="Evento"
                ha_evento
                onChange={this.onAtualizar}
                disabled={false}
              />
            </Field.List.Row>
          </Field.List>

          <Field.List.Row>
            <Field.Reference
              url={"/modalidade/referenciar"}
              urlParameterMap={() => {
                return {
                  mo_entidade: record.ha_entidade,
                };
              }}
              onChange={this.onAtualizar}
              columnArray={[<Table.ColumnNumber name="mo_modalidade" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="mo_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
            >
              <Field.Input.Number name="ha_modalidade" record={record} label="Modalidade" prefix="" thousandSeparator="" decimalScale={0} />
              <Field.Input.String name="ha_modalidade_nome" record={record} label="Nome Modalidade" />
            </Field.Reference>
          </Field.List.Row>

          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/arma/referenciar"}
                urlParameterMap={() => {
                  return {
                    ar_associado: record.ha_associado,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  <Table.ColumnNumber name="ar_arma" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                  <Table.ColumnString name="ar_marca" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />,
                  <Table.ColumnString name="ar_numeroSerie" label="Núm. Série" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />
                ]}
              >
                <Field.Input.Number name="ha_arma" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="ha_marca_descricao" record={record} label="Arma" />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="ha_quantidadeMunicao" label="Qtde" prefix="" thousandSeparator="" decimalScale={0} disabled={false} />
            </Field.List.Row>
            <Field.List.Row>
              <Field.Date name="ha_dataEvento" record={record} label="Data Evento" prefix="" onChange={this.onAtualizar} />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="ha_horaEvento" label="Hora Formato 00:00" />
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="ha_localEvento" label="Tipo/Cidade/NomeClube" />
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="remover" variant="contained" size="small" onClick={this.onRemover} disabled={recordIsNew === true}>
              Remover
            </Button>,
          ]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
