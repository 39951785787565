import React from 'react';
import PropTypes from 'prop-types';

class Component extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
  }

  static defaultProps = {
    name: null,
  }

  constructor(props, context) {
    super(props, context);

    const program = context.program;
    const componentData = program.getComponentData(this);
    const componentDataState = componentData.state;

    this.state = {
      ...this.state,
      ...componentDataState,
    };

    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
  }

  focus() {
  }

  getPosition() {
    return null;
  }

}

export default Component;
