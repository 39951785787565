import React from "react";
import PropTypes from "prop-types";

// import {KeyboardDatePicker} from '@material-ui/pickers';
import { DatePicker } from "@mui/x-date-pickers/DatePicker"; //from "@mui/lab/DatePicker";

import TodayIcon from "@mui/icons-material/Today";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import format from "date-fns/format";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import FieldInput from "./FieldInput";
import Context from "../context/Context";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from "../validation/Validation";

class FieldDate extends FieldInput {
  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    record: null,
    label: null,
    helperText: null,
    required: null,
    validate: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const name = props.name;
    const record = props.record;
    const recordValue = record[name];
    const value = parseISO(recordValue).toString() !== "Invalid Date" ? parseISO(recordValue) : null;

    this.state = {
      ...this.state,
      value: value,
      selectedSections: {
        startIndex: 0,
        endIndex: 0,
      },
      keyDownCount: 0,
      error: false,
    };

    this.reference = {
      textField: null,
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.focus = this.focus.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onkeyDownBackspace = this.onkeyDownBackspace.bind(this);
    this.onKeyDownEnter = this.onKeyDownEnter.bind(this);
    this.onKeyDownNumeric = this.onKeyDownNumeric.bind(this);
    this.onChange = this.onChange.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    const record = props.record;
    const prevPropsRecord = prevProps.record;
    const recordIsChanged = prevPropsRecord !== record;

    if (recordIsChanged) {
      const name = props.name;
      const record = props.record;
      const recordValue = record[name];
      const value = parseISO(recordValue).toString() !== "Invalid Date" ? parseISO(recordValue) : null;

      this.setState((state, props) => {
        return {
          ...this.state,
          value: value,
        };
      });
    }
  }

  getPosition() {
    const reference = this.reference;
    const textField = reference.textField;
    const position = textField.getBoundingClientRect();

    return position;
  }

  focus() {
    const reference = this.reference;
    const textField = reference.textField;

    textField.focus();
    textField.select();
  }

  onFocus(event) {
    this.setState(
      (state, props) => {
        return {
          ...state,
          selectedSections: {
            startIndex: 0,
            endIndex: 2,
          },
          keyDownCount: 0,
        };
      },
      () => {
        super.onFocus(event);
      }
    );
  }

  onBlur(event) {
    const props = this.props;
    const helperText = props.helperText;
    const onBlur = props.onBlur;
    const onBlurIsFunction = Validation.isFunction(onBlur);
    try {
      const target = this.reference.textField;
      const valueStringPtBr = target.value;
      const value = parse(valueStringPtBr, "dd/MM/yyyy", new Date());
      const valueStringEnUs = format(value, "yyyy-MM-dd");
      const valueCurrent = this.props.record[this.props.name];
      const valueIsChanged = value !== valueCurrent;

      if (valueIsChanged) {
        // await this.validate(value);
        const props = this.props;
        const name = props.name;
        const record = props.record;

        record[name] = valueStringEnUs;

        const onChange = props.onChange;
        const onChangeIsFunction = Validation.isFunction(onChange);

        if (onChangeIsFunction) {
          onChange();
        }

        this.setState(
          (state, props) => {
            return {
              ...state,
              value: value,
              selectedSections: {
                startIndex: 0,
                endIndex: 2,
              },
              keyDownCount: 0,

              error: false,
              helperText: helperText,
            };
          },
          () => {
            if (onBlurIsFunction) {
              onBlur(event);
            }
          }
        );
      }
    } catch (error) {
      const name = this.props.name;
      const record = this.props.record;
      const recordValue = record[name];
      const value = parseISO(recordValue).toString() !== "Invalid Date" ? parseISO(recordValue) : null;

      this.setState(
        (state, props) => {
          return {
            ...state,
            value: value,
            selectedSections: {
              startIndex: 0,
              endIndex: 2,
            },
            keyDownCount: 0,

            error: false,
            helperText: helperText,
          };
        },
        () => {
          if (onBlurIsFunction) {
            onBlur(event);
          }
        }
      );
    }
  }

  onKeyDown(event) {
    const keyCode = event.keyCode;

    switch (keyCode) {
      case 96:
      case 97:
      case 98:
      case 99:
      case 100:
      case 101:
      case 102:
      case 103:
      case 104:
      case 105:
        this.onKeyDownNumeric(event);
        break;
      case 13:
        this.onKeyDownEnter(event);
        break;
      case 8:
        this.onkeyDownBackspace(event);
        break;
      default:
        break;
    }
  }

  onkeyDownBackspace(event) {
    this.setState(
      (state, props) => {
        return {
          ...state,
          value: null,
          selectedSections: {
            startIndex: 0,
            endIndex: 2,
          },
          keyDownCount: 0,
        };
      },
      () => {
        this.reference.textField.value = "";
      }
    );
  }

  onKeyDownEnter(event) {
    const target = this.reference.textField; //event.target;
    const valueString = target.value;
    const valueStringLength = valueString.length;
    const selectionStart = target.selectionStart;
    const selectionEnd = target.selectionEnd;
    const selectionIsFull = selectionStart === 0 && selectionEnd === valueStringLength;

    if (selectionIsFull && valueString !== "") {
      this.setState((state, props) => {
        return {
          ...state,
          selectedSections: {
            startIndex: 0,
            endIndex: 0,
          },
          keyDownCount: 0,
        };
      });
    } else {
      const props = this.props;
      const helperText = props.helperText;
      const onChange = props.onChange;
      const onChangeIsFunction = Validation.isFunction(onChange);
      try {
        const target = this.reference.textField;
        const valueStringPtBr = target.value;
        const value = valueStringPtBr === "" ? null : parse(valueStringPtBr, "dd/MM/yyyy", new Date());
        const valueStringEnUs = value === null ? null : format(value, "yyyy-MM-dd");
        const valueCurrent = this.props.record[this.props.name];
        const valueIsChanged = value !== valueCurrent;

        if (valueIsChanged) {
          // await this.validate(value);
          const props = this.props;
          const name = props.name;
          const record = props.record;

          record[name] = valueStringEnUs;

          //const onChange = props.onChange;
          //const onChangeIsFunction = Validation.isFunction(onChange);

          if (onChangeIsFunction) {
            onChange();
          }

          this.setState(
            (state, props) => {
              return {
                ...state,
                value: value,
                selectedSections: {
                  startIndex: 0,
                  endIndex: 2,
                },
                keyDownCount: 0,

                error: false,
                helperText: helperText,
              };
            },
            () => {
              if (value === null) {
                this.reference.textField.value = "";
              }
            }
          );
        }
      } catch (error) {
        const name = this.props.name;
        const record = this.props.record;
        const recordValue = record[name];
        const value = parseISO(recordValue).toString() !== "Invalid Date" ? parseISO(recordValue) : null;

        this.setState(
          (state, props) => {
            return {
              ...state,
              value: value,
              selectedSections: {
                startIndex: 0,
                endIndex: 2,
              },
              keyDownCount: 0,

              error: false,
              helperText: helperText,
            };
          } /*,
          () => {
            if (onBlurIsFunction) {
              onBlur(event);
            }
          }*/
        );
      }
    }
  }

  onKeyDownNumeric(event) {
    this.setState((state, props) => {
      return {
        ...state,
        selectedSections:
          state.selectedSections.startIndex !== state.selectedSections.endIndex || (this.reference.textField.selectionStart === 0 && this.reference.textField.value !== null && this.reference.textField.selectionEnd === this.reference.textField.value.length)
            ? {
                startIndex: 0,
                endIndex: 0,
              }
            : state.keyDownCount + 1 > 2
            ? {
                startIndex: Math.min(state.selectedSections.startIndex + 1, 2),
                endIndex: Math.min(state.selectedSections.endIndex + 1, 2),
              }
            : state.selectedSections,
        keyDownCount: state.keyDownCount + 1 > 2 ? 1 : state.keyDownCount + 1,
      };
    });
  }

  onChange(value) {
    this.setState((state, props) => {
      return {
        ...state,
        value: value,
        selectedSections:
          state.selectedSections.startIndex < 2 && state.keyDownCount > 1
            ? {
                startIndex: Math.min(state.selectedSections.startIndex + 1, 2),
                endIndex: Math.min(state.selectedSections.endIndex + 1, 2),
              }
            : state.selectedSections,
        keyDownCount: state.selectedSections.startIndex < 2 && state.keyDownCount > 1 ? 0 : state.keyDownCount,
      };
    });
  }

  /* prettier-ignore */
  render() {
    const {
      name,
      record,
      label,
      helperText: propHelperText,
      required,
      validate,
      onChange,
      onFocus,
      onBlur,
      children,
      ...otherProps
    } = this.props;

    const {
      value,
      selectedSections,
      error,
    } = this.state;

    return (
      <DatePicker
        value={value}
        format="dd/MM/yyyy"
        selectedSections={selectedSections}

        label={label}
        error={error}
        helperText={null}
        inputVariant="outlined"
        margin="dense"
        autoComplete="off"
        sx={{
          flex: "1 1 auto",
          margin: "0px",
        }}
        InputLabelProps={{
          style: {
            maxWidth: "calc(100% - 32px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }}

        onChange={(date) => {
          this.onChange(date);
        }}
        onFocus={this.onFocus}
        
        slotProps={{
          textField: {
            size: "small",

            onKeyDown: (event) => {
              this.onKeyDown(event);
            },
            inputRef:(textField) => {
              const reference = this.reference;

              reference.textField = textField;
            },            
            onBlur: this.onBlur,
          }
        }}

        onAccept={(date) => {
          const valueStringEnUs = date === null ? null : format(date, "yyyy-MM-dd");

          const onChange = this.props.onChange;
          const onChangeIsFunction = Validation.isFunction(onChange);
    
          
          const props = this.props;
          const name = props.name;
          const record = props.record;

          record[name] = valueStringEnUs;

          if (onChangeIsFunction) {
            onChange();
          }

          this.setState(
            (state, props) => {
              return {
                ...state,
                value: date,
                selectedSections: {
                  startIndex: 0,
                  endIndex: 2,
                },
                keyDownCount: 0,

                error: false,
                helperText: null,
              };
            },
            () => {
              if (value === null) {
                this.reference.textField.value = "";
              }
            }
          );
        }}
      />
    );
  }
}

export default FieldDate;
