import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      pca_premiacaoCampeonato: PropTypes.number,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.pca_premiacaoCampeonato) ? false : true;
    const componentFocused = "pca_classe_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onAtualizar = this.onAtualizar.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onRemover = this.onRemover.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/premiacaoCampeonato/editar/criar" : "/premiacaoCampeonato/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onAtualizar() {
    const state = this.state;
    const record = state.record;
    const combo = this.getComponent("pca_premiacaoIndividualOuEquipe");
    const comboState = combo.state;
    const comboRecordArray = comboState.recordArray;

    let comboRecordSelected = null;

    comboRecordArray.forEach((comboRecord, comboRecordIndex) => {
      if (comboRecord.pca_premiacaoIndividualOuEquipe === record.pca_premiacaoIndividualOuEquipe) {
        comboRecordSelected = comboRecord;
      }
    });

    if (comboRecordSelected !== null && comboRecordSelected.pca_premiacaoIndividualOuEquipe === "E") {
      this.setState((state, props) => {
        return {
          ...state,
          record: {
            ...state.record,
            pca_campeonatoClasse: null,
            pca_classe_nome: null,
          },
        };
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/premiacaoCampeonato/editar/inserir" : "/premiacaoCampeonato/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onRemover() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/premiacaoCampeonato/editar/remover";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/premiacaoCampeonato/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error, (appMessage) => {});

      return false;
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="P" caption={recordIsNew ? "Adicionar Etapa" : "Editar Etapa"} description={"Editor de Etapa"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="etapa" caption="Etapa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/campeonato/referenciar"}
                urlParameterMap={() => {
                  return {
                    ca_entidade: this.context.app.state.vp_entidade,
                  };
                }}
                onChange={[]}
                columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="pca_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                <Field.Input.String name="pca_campeonato_nome" record={record} label="Campeonato " disabled={true} />
              </Field.Reference>
            </Field.List.Row>

            <Field.List.Row>
              <Field.Reference
                url={"/campeonatoModalidade/referenciar"}
                urlParameterMap={() => {
                  return {
                    cam_entidade: this.context.app.state.vp_entidade,
                    cam_campeonato: record.pca_campeonato,
                  };
                }}
                onChange={[]}
                columnArray={[<Table.ColumnNumber name="cam_campeonatoModalidade" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="mo_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="pca_campeonatoModalidade" record={record} label="Modalidade" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                <Field.Input.String name="pca_modalidade_nome" record={record} label="Nome Modalidade" disabled={true} />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/campeonatoClasse/referenciar"}
                urlParameterMap={() => {
                  return {
                    cac_entidade: this.context.app.state.vp_entidade,
                    cac_campeonato: record.pca_campeonato,
                    pca_premiacaoIndividualOuEquipe: null,
                    pca_premiacaoIndividualOuEquipe_descricao: null,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[<Table.ColumnNumber name="cac_campeonatoClasse" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="cs_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="pca_campeonatoClasse" record={record} label="Classe" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="pca_classe_nome" record={record} label="Nome Classe" />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="pca_nome" label="Nome do Premio" />
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="pca_classificacao" label="Classificação" prefix="" thousandSeparator="" decimalScale={0} />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="pca_premiacaoEtapaOuFinal"
                caption="pca_premiacaoEtapaOuFinal_descricao"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "premiacaoCampeonato",
                  flg_campo: "pca_premiacaoEtapaOuFinal",
                }}
                label="Tipo Premiação (Etapa ou Final)"
                onChange={[]}
              />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="pca_premiacaoIndividualOuEquipe"
                caption="pca_premiacaoIndividualOuEquipe_descricao"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "premiacaoCampeonato",
                  flg_campo: "pca_premiacaoIndividualOuEquipe",
                }}
                label="Premiação (Individual ou Equipe)"
                onChange={this.onAtualizar}
              />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo
                name="pca_ativo"
                caption="pca_ativo_descricao"
                record={record}
                url="/flag/referenciar"
                urlParameterMap={{
                  flg_tabela: "premiacaoCampeonato",
                  flg_campo: "pca_ativo",
                }}
                label="Ativo"
                onChange={[]}
              />
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="remover" variant="contained" size="small" onClick={this.onRemover} disabled={recordIsNew === true}>
              Remover
            </Button>,
          ]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
