import parse from 'date-fns/parse'

const Validation = {
  isUndefined: (value) => {
    return ((typeof(value) === 'undefined') || (value === null));
  },
  isDefined: (value) => {
    return ((typeof(value) !== 'undefined') && (value !== null));
  },

  isString: (value) => {
    return ((Validation.isDefined(value)) && ((typeof(value) === 'string') || (value instanceof String)));
  },
  isNumber: (value) => {
    return ((Validation.isDefined(value)) && (typeof(value) === 'number'));
  },
  isArray: (value) => {
    return ((Validation.isDefined(value)) && (Array.isArray(value)));
  },
  isObject: (value) => {
    return ((Validation.isDefined(value)) && ((typeof(value) === 'object') || (value instanceof Object)));
  },
  isFunction: (value) => {
    return ((Validation.isDefined(value)) && ((typeof(value) === 'function') || (value instanceof Function)));
  },

  isEmpty: (value) => {
    return (((Validation.isString(value)) || (Validation.isArray(value))) && (value.length === 0));
  },

  isDateString: (value) => {
    try {
      const valueDate = parse(value, 'yyyy-MM-dd', new Date());
      const valueDateString = valueDate.toString();
      const valueDateIsValid = (valueDateString !== 'Invalid Date')

      return valueDateIsValid;
    } catch (error) {
      return false;
    }
  },

  isDate: (value) => {
    try {
      const valueString = value.toString();
      const valueIsValid = (valueString !== 'Invalid Date')

      return valueIsValid;
    } catch (error) {
      return false;
    }
  },
};

export default Validation;
