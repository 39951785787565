import React from 'react';
import PropTypes from 'prop-types';
import MenuDirection from './MenuDirection';
import Validation from '../validation/Validation';
import MuiMenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItemList from './MenuItemList';

class MenuItem extends React.Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.element,
    inset: PropTypes.bool,
    selected: PropTypes.bool,
    enabled: PropTypes.bool,
    anchorOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(Object.values(MenuDirection.Vertical)).isRequired,
      horizontal: PropTypes.oneOf(Object.values(MenuDirection.Horizontal)).isRequired,
    }).isRequired,
    transformOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(Object.values(MenuDirection.Vertical)).isRequired,
      horizontal: PropTypes.oneOf(Object.values(MenuDirection.Horizontal)).isRequired,
    }).isRequired,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    text: null,
    icon: null,
    inset: false,
    selected: false,
    enabled: true,
    anchorOrigin: {vertical: MenuDirection.Vertical.Top, horizontal: MenuDirection.Horizontal.Right},
    transformOrigin: {vertical: MenuDirection.Vertical.Top, horizontal: MenuDirection.Horizontal.Left},
    onClick: null,
    onClose: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      anchor: null,
    };

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  onClick(event) {
    const anchor = event.currentTarget;

    this.setState((state, props) => {
      return {
        ...state,
        anchor: anchor,
      };
    });

    const props = this.props;
    const propsOnClick = props.onClick;
    const propsOnClickIsFunction = Validation.isFunction(propsOnClick);

    if (propsOnClickIsFunction) {
      propsOnClick();
    }

    const children = props.children;
    const childrenArray = React.Children.toArray(children);
    const childrenArrayIsEmpty = Validation.isEmpty(childrenArray);

    if (childrenArrayIsEmpty) {
      this.onClose();
    }
  }

  onClose(event) {
    this.setState((state, props) => {
      return {
        ...state,
        anchor: null,
      };
    });

    const props = this.props;
    const propsOnClose = props.onClose;
    const propsOnCloseIsFunction = Validation.isFunction(propsOnClose);

    if (propsOnCloseIsFunction) {
      propsOnClose();
    }
  }

  render() {
    const {
      theme,
      classes,
      text,
      icon,
      inset,
      selected,
      enabled,
      anchorOrigin,
      transformOrigin,
      onClick,
      onClose,
      children,
      ...otherProps
    } = this.props;
    const {
      anchor
    } = this.state;

    const disabled = (!(enabled));
    const iconIsDefined = Validation.isDefined(icon);

    return (
      <React.Fragment>
        <MuiMenuItem
          selected={selected}
          disabled={disabled}
          onClick={(event) => {this.onClick(event);}}
          {...otherProps}
        >
          {(iconIsDefined) &&
            <ListItemIcon>
              {icon}
            </ListItemIcon>
          }
          <ListItemText primary={text} inset={inset}/>
        </MuiMenuItem>
        <MenuItemList
          anchor={anchor}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={(event) => {this.onClose();}}
        >
          {children}
        </MenuItemList>
      </React.Fragment>
    );
  }

}

export default MenuItem;
