import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    appLayout: {
      [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexFlow: 'column nowrap',
        backgroundImage: 'url("background.jpg")',
        backgroundSize: 'cover',
      },
      [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexFlow: 'column nowrap',
        backgroundColor: theme.palette.grey[200],
      },
    },
  };
});
*/

class AppLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <React.Fragment>
        <CssBaseline/>
        <Box
          /* className={classes.appLayout} */
          sx={(theme) => ({
            [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexFlow: 'column nowrap',
              backgroundImage: 'url("background.jpg")',
              backgroundSize: 'cover',
            },
            [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexFlow: 'column nowrap',
              backgroundColor: theme.palette.grey[200],
            },
          })}
          {...otherProps}
        >
          {children}
        </Box>
      </React.Fragment>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(AppLayout);
export default AppLayout;
