import React from "react";
import ProgramLayoutWidth from "./ProgramLayoutWidth";
import ProgramLayoutHeight from "./ProgramLayoutHeight";
import Context from "../context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Validation from "../validation/Validation";
import Paper from "@mui/material/Paper";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    programLayout: {
      [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
        maxWidth: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
      },
      [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
        maxWidth: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
        borderRadius: '0px',
        boxShadow: 'none',
      },
    },
  };
});
*/

class ProgramLayout extends React.Component {
  static Width = ProgramLayoutWidth;
  static Height = ProgramLayoutHeight;

  static contextType = Context;

  static propTypes = {
    program: PropTypes.object.isRequired,
    /*
    width: AirbnbPropTypes.or([
      AirbnbPropTypes.stringEndsWith('px'),
      PropTypes.oneOf(Object.values(ProgramLayout.Width)),
    ]),
    height: AirbnbPropTypes.or([
      AirbnbPropTypes.stringEndsWith('px'),
      PropTypes.oneOf(Object.values(ProgramLayout.Height)),
    ]),
    */
    children: PropTypes.node,
  };

  static defaultProps = {
    program: null,
    width: null,
    height: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const program = props.program;

    this.contextValue = {
      ...context,
      program: program,
    };

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      style,
      program,
      width,
      height,
      children,
      ...otherProps
    } = this.props;

    const contextValue = this.contextValue;

    const widthIsMinimum = ((Validation.isUndefined(width)) || (width === ProgramLayout.Width.Minimum));
    const widthIsMaximum = (width === ProgramLayout.Width.Maximum);
    const widthIsFixed = ((Validation.isString(width)) && (width.endsWith('px')));
    const heightIsMinimum = ((Validation.isUndefined(height)) || (height === ProgramLayout.Height.Minimum));
    const heightIsMaximum = (height === ProgramLayout.Height.Maximum);
    const heightIsFixed = ((Validation.isString(height)) && (height.endsWith('px')));

    var styleComposed = {
      ...(widthIsMinimum  ? {width: null}      : {}),
      ...(widthIsMaximum  ? {width: '100%'}    : {}),
      ...(widthIsFixed    ? {width: width}     : {}),
      ...(heightIsMinimum ? {height: null}     : {}),
      ...(heightIsMaximum ? {flex: '1 1 auto'} : {}),
      ...(heightIsFixed   ? {minHeight: height} : {}),
      ...style,
    };

    return (
      <Context.Provider value={contextValue}>
        <Paper
          /* className={classes.programLayout} */
          /* style={styleComposed} */

          sx={(theme) => ({
            [theme.breakpoints.up(theme.breakpoints.values['sm'])]: {
              maxWidth: '100%',
              display: 'flex',
              flexFlow: 'column nowrap',
              padding: theme.spacing(1),
              backgroundColor: theme.palette.grey[200],
            },
            [theme.breakpoints.down(theme.breakpoints.values['sm'])]: {
              maxWidth: '100%',
              display: 'flex',
              flexFlow: 'column nowrap',
              padding: theme.spacing(1),
              backgroundColor: theme.palette.grey[200],
              borderRadius: '0px',
              boxShadow: 'none',
            },
            ...styleComposed,
          })}

          {...otherProps}
        >
          {children}
        </Paper>
      </Context.Provider>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(ProgramLayout);
export default ProgramLayout;
