import React from "react";
import PropTypes from "prop-types";
import TableCellAlign from "./TableCellAlign";
import TableCell from "./TableCell";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    tableCellHeaderCheckBoxLeft: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    tableCellHeaderCheckBoxCenter: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableCellHeaderCheckBoxRight: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    tableCellHeaderCheckBoxIcon: {
      color: theme.palette.text.secondary,
    },
  };
});
*/

class TableCellHeaderCheckBox extends React.Component {
  static propTypes = {
    parent: PropTypes.any.isRequired,
    isVisible: PropTypes.any.isRequired,
    isScrolling: PropTypes.any.isRequired,
    rowIndex: PropTypes.any.isRequired,
    columnIndex: PropTypes.any.isRequired,
    // key: PropTypes.any,
    style: PropTypes.any.isRequired,
    keyValue: PropTypes.any.isRequired,
    table: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    align: PropTypes.oneOf(Object.values(TableCellAlign)).isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    parent: null,
    isVisible: null,
    isScrolling: null,
    rowIndex: null,
    columnIndex: null,
    // key: null,
    style: null,
    keyValue: null,
    table: null,
    onClick: null,
    align: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      parent,
      isVisible,
      isScrolling,
      rowIndex,
      columnIndex,
      // key,
      style,
      keyValue,
      table,
      onClick,
      align,
      children,
      ...otherProps
    } = this.props;

    const props = table.props;
    const recordArray = props.recordArray;
    const selectionArray = table.getSelectionArray();
    var selected = null;

    switch (true) {
      case selectionArray.length === 0:
        selected = false;
        break;
      case selectionArray.length === recordArray.length:
        selected = true;
        break;
      default:
        selected = null;
        break;
    }

    var className = null;

    switch (align) {
      case TableCellAlign.Left:
        // className = classes.tableCellHeaderCheckBoxLeft;
        className = (theme) => ({
          flex: "1 1 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        });
        break;
      case TableCellAlign.Center:
        // className = classes.tableCellHeaderCheckBoxCenter;
        className = (theme) => ({
          flex: "1 1 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        });
        break;
      case TableCellAlign.Right:
        // className = classes.tableCellHeaderCheckBoxRight;
        className = (theme) => ({
          flex: "1 1 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        });
        break;
      default:
        className = null;
        break;
    }

    return (
      <TableCell
        parent={parent}
        isVisible={isVisible}
        isScrolling={isScrolling}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        key={keyValue}
        style={style}
        table={table}
        keyValue={keyValue}
        onClick={onClick}
        {...otherProps}
      >
        <Box
          /* className={className} */
          sx={className}
        >
          {selected === true && (
            <CheckBoxIcon
              /* className={classes.tableCellHeaderCheckBoxIcon} */ sx={(
                theme
              ) => ({ color: theme.palette.text.secondary })}
            />
          )}
          {selected === false && (
            <CheckBoxOutlineBlankIcon
              /* className={classes.tableCellHeaderCheckBoxIcon} */ sx={(
                theme
              ) => ({ color: theme.palette.text.secondary })}
            />
          )}
          {selected === null && (
            <IndeterminateCheckBoxIcon
              /* className={classes.tableCellHeaderCheckBoxIcon} */ sx={(
                theme
              ) => ({ color: theme.palette.text.secondary })}
            />
          )}
        </Box>
      </TableCell>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(TableCellHeaderCheckBox);
export default TableCellHeaderCheckBox;
