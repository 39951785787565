import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      isu_sumula: PropTypes.number,
      isu_entidade: PropTypes.number,
      isu_entidade_nome: PropTypes.string,
      isu_campeonato: PropTypes.number,
      isu_campeonato_nome: PropTypes.string,
      isu_arma: PropTypes.number,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.isu_inscricaoSumula) ? false : true;
    const componentFocused = "et_etapa";

    this.state = {
      ...this.state,
      record: {},
      recordArrayCombo: [],
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onRemover = this.onRemover.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);

    this.onAtualizarCombo = this.onAtualizarCombo.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const parameterMap = props.parameterMap;
      const isu_entidade = parameterMap.isu_entidade;
      const isu_campeonato = parameterMap.isu_campeonato;
      const isu_etapa = parameterMap.isu_etapa;

      if (!(isu_entidade !== null && isu_campeonato !== null && isu_etapa !== null)) {
        this.close();
      }
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/inscricaoSumula/editar/criar" : "/inscricaoSumula/editar/carregar";
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            record: responseRecord,
          };
        },
        async () => {
          await this.onAtualizarCombo();
        }
      );
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onAtualizarCombo() {
    

    const state = this.state;
    const record = state.record;
    const response = await this.fetch("/inscricaoEtapa/referenciar", {
      value: null,
      description: null,
      iet_associado: record.isu_associado,
      iet_etapa: record.isu_etapa,
    });

    const recordArray = response.recordArray.filter((record) => {
      return record.mo_tipoSumula === this.props.parameterMap.isu_tipoSumula;
    });

    this.setState((state, props) => {
      return {
        ...state,
        recordArrayCombo: recordArray,
      };
    });
  }

  async onAtualizar() {
    await this.onAtualizarCombo();
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;
      const url = recordIsNew ? "/inscricaoSumula/editar/inserir" : "/inscricaoSumula/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = recordIsNew ? response.recordArray : response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onRemover() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/inscricaoSumula/editar/remover";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/inscricaoSumula/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;

    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="P" caption={recordIsNew ? "Adicionar Inscrição na Sumula" : "Editar inscrição na Súmula"} description={"Editor de Súmula"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="sumula" caption="Súmula" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/campeonato/referenciar"}
                urlParameterMap={() => {
                  return {
                    ca_entidade: this.context.app.state.vp_entidade,
                  };
                }}
                columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="isu_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                <Field.Input.String name="isu_campeonato_nome" record={record} label="Campeonato " disabled={true} />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/etapa/referenciar"}
                urlParameterMap={() => {
                  return {
                    et_campeonato: record.isu_campeonato,
                  };
                }}
                columnArray={[<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="isu_etapa" record={record} label="Etapa" prefix="" thousandSeparator="" decimalScale={0} disabled={true} />
                <Field.Input.String name="isu_etapa_nome" record={record} label="Nome Etapa " disabled={true} />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/associado/referenciar"}
                urlParameterMap={() => {
                  return {
                    as_entidade: this.context.app.state.vp_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[<Table.ColumnNumber name="as_associado" label="Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Nome Associado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="isu_associado" record={record} label="Associado" prefix="" thousandSeparator="" decimalScale={0} disabled={recordIsNew !== true} />
                <Field.Input.String name="isu_associado_nome" record={record} label="Nome Associado" disabled={recordIsNew !== true} />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Date name="isu_data" record={record} label="Data" prefix="" disabled={recordIsNew !== true} />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Combo name="iet_inscricaoEtapa" caption="description" record={record} recordArray={this.state.recordArrayCombo} label="Modalidade" disabled={recordIsNew !== true} />
            </Field.List.Row>
          </Field.List>
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/arma/referenciar"}
                urlParameterMap={() => {
                  return {
                    ar_associado: record.isu_associado,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  <Table.ColumnNumber name="ar_arma" label="Arma" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                  <Table.ColumnString name="ar_marca" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />,
                  <Table.ColumnString name="ar_numeroSerie" label="Núm. Série" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />
                ]}
              >
                <Field.Input.Number name="isu_arma" record={record} label="Arma" prefix="" thousandSeparator="" decimalScale={0} disabled={recordIsNew !== true} />
                <Field.Input.String name="ar_marcaModeloSerie" record={record} label="Marca Modelo e Serie da Arma" disabled={recordIsNew !== true} />
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="remover" variant="contained" size="small" onClick={this.onRemover} disabled={recordIsNew === true}>
              Remover
            </Button>,
          ]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar} disabled={recordIsNew !== true}>
              Salvar
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
