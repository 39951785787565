import React from "react";
import PropTypes from "prop-types";
// import AirbnbPropTypes from 'airbnb-prop-types';
import Context from "../mui-v4/context/Context";
import ProgramLayout from "../mui-v4/program/ProgramLayout";
import Header from "../mui-v4/header/Header";
import Table from "../mui-v4/table/Table";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';
import Button from "../mui-v4/button/Button";
import Field from "../mui-v4/field/Field";
import Group from "../mui-v4/group/Group";
import Validation from "../mui-v4/validation/Validation";
import ReplayIcon from "@mui/icons-material/Replay";
import Menu from "../mui-v4/menu/Menu";
import Program from "../mui-v4/program/Program";
import CloseIcon from "@mui/icons-material/Close";
import { InscricaoEtapaIcon, InscricaoSumulaIcon, FinanceiroIcon, ResultadoIcon } from "./AppIcon";
import format from "date-fns/format";

import FlagListar from "./flag/Listar";
import SexoListar from "./sexo/Listar";
import UnidadeFederacaoListar from "./unidadeFederacao/Listar";
import MunicipioListar from "./municipio/Listar";
import EntidadeListar from "./entidade/Listar";
import TipoAssociadoListar from "./tipoAssociado/Listar";
import TabelaContribuicaoListar from "./tabelaContribuicao/Listar";
import FormaPagamentoListar from "./formaPagamento/Listar";
import AssociadoListar from "./associado/Listar";
import TipoMovimentoDuplicataListar from "./tipoMovimentoDuplicata/Listar";
import CobradorListar from "./cobrador/Listar";
import BancoListar from "./banco/Listar";
import UsuarioListar from "./usuario/Listar";
import ProgramaListar from "./programa/Listar";
import CategoriaListar from "./categoria/Listar";
import ClasseListar from "./classe/Listar";
import CampeonatoListar from "./campeonato/Listar";
import EquipeListar from "./equipe/Listar";
import TipoSumulaListar from "./tipoSumula/Listar";
import ModalidadeListar from "./modalidade/Listar";
import EtapaListar from "./etapa/Listar";
import ProdutoServicoListar from "./produtoServico/Listar";
import DuplicataListar from "./duplicata/Listar";
import ArmaListar from "./arma/Listar";
import HabitualidadeListar from "./habitualidade/Listar";
import ProgramaUsuarioListar from "./programaUsuario/Listar";
import DeclaracaoHabitualidadeListar from "./declaracaoHabitualidade/Listar";
import DeclaracaoFiliacaoListar from "./declaracaoFiliacao/Listar";
import DeclaracaoCompromissoListar from "./declaracaoCompromisso/Listar";
import DeclaracaoModalidadeProvaListar from "./declaracaoModalidadeProva/Listar";
import GerarDuplicataListar from "./gerarDuplicata/Listar";
import DadosAssociadoEditar from "./dadosAssociado/Editar";
import FichaFinanceiraAssociadoListar from "./fichaFinanceiraAssociado/Listar";
import RelatorioContasReceberListar from "./relatorioContasReceber/Listar";
import RecebimentoDuplicataListar from "./recebimentoDuplicata/Listar";
import AlterarSenhaEditar from "./alterarSenha/Editar";
import EnviarCobrancaPIXListar from "./enviarCobrancaPIX/Listar";
import DadosEntidadeEditar from "./dadosEntidade/Editar";
import RelatorioMovimentoContasReceberListar from "./relatorioMovimentoContasReceber/Listar";
import LoteCobrancaListar from "./loteCobranca/Listar";
import CampeonatoModalidadeListar from "./campeonatoModalidade/Listar";
import CampeonatoClasseListar from "./campeonatoClasse/Listar";
import CampeonatoEquipeListar from "./campeonatoEquipe/Listar";
import ModalidadeEntidadeListar from "./modalidadeEntidade/Listar";
import ProdutoServicoModalidadeListar from "./produtoServicoModalidade/Listar";
import InscricaoCampeonatoListar from "./inscricaoCampeonato/Listar";
import InscricaoEtapaListar from "./inscricaoEtapa/Listar";
import SumulaListar from "./sumula/Listar";
import InscricaoSumulaListar from "./inscricaoSumula/Listar";
import ProdutoServicoAssociadoListar from "./produtoServicoAssociado/Listar";
import InscricaoSumulaResultadoListar from "./inscricaoSumulaResultado/Listar";
import RelatorioInscritoEtapaListar from "./relatorioInscritoEtapa/Listar";
import RelatorioFinanceiroEtapaListar from "./relatorioFinanceiroEtapa/Listar";
import ResultadoCalcularClassificacaoAplicar from "./resultado/calcularClassificacao/Aplicar";
import OutrosCampeonatoListar from "./outrosCampeonato/Listar";
import ProdutoServicoOutrosCampeonatoListar from "./produtoServicoOutrosCampeonato/Listar";
import OutrosCampeonatoEtapaListar from "./outrosCampeonatoEtapa/Listar";
import InscricaoEtapaOutrosCampeonatoListar from "./inscricaoEtapaOutrosCampeonato/Listar";
import ProdutoServicoModalidadeSumulaListar from "./produtoServicoModalidadeSumula/Listar";
import ParticipacaoAssociadoListar from "./participacaoAssociado/Listar";
import ListaAniversarianteListar from "./listaAniversariante/Listar";
import ListaCRListar from "./listaCR/Listar";
import ConsultaCadastroAssociadoListar from "./consultaCadastroAssociado/Listar";
import ListaRegistroHabitualidadeListar from "./listaRegistroHabitualidade/Listar";
import ModalidadeProvaListar from "./modalidadeProva/Listar";
import RelatorioPremiacaoCampeonatoListar from "./relatorioPremiacaoCampeonato/Listar";
import RelatorioPremiacaoFinalCampeonatoListar from "./relatorioPremiacaoFinalCampeonato/Listar";
import PremiacaoCampeonatoListar from "./premiacaoCampeonato/Listar";
import SumulaAvulsaListar from "./sumulaAvulsa/Listar";
import RelatorioParticipantesEtapaListar from "./relatorioParticipantesEtapa/Listar";

const programaElementMap = {
  ProgramaListar: <ProgramaListar />,
  FlagListar: <FlagListar />,
  SexoListar: <SexoListar />,
  UnidadeFederacaoListar: <UnidadeFederacaoListar />,
  MunicipioListar: <MunicipioListar />,
  UsuarioListar: <UsuarioListar />,
  EntidadeListar: <EntidadeListar />,
  TipoAssociadoListar: <TipoAssociadoListar />,
  TabelaContribuicaoListar: <TabelaContribuicaoListar />,
  FormaPagamentoListar: <FormaPagamentoListar />,
  AssociadoListar: <AssociadoListar />,
  TipoMovimentoDuplicataListar: <TipoMovimentoDuplicataListar />,
  CobradorListar: <CobradorListar />,
  BancoListar: <BancoListar />,
  DuplicataListar: <DuplicataListar />,
  CategoriaListar: <CategoriaListar />,
  ClasseListar: <ClasseListar />,
  CampeonatoListar: <CampeonatoListar />,
  EquipeListar: <EquipeListar />,
  TipoSumulaListar: <TipoSumulaListar />,
  ModalidadeListar: <ModalidadeListar />,
  EtapaListar: <EtapaListar />,
  ProdutoServicoListar: <ProdutoServicoListar />,
  ArmaListar: <ArmaListar />,
  HabitualidadeListar: <HabitualidadeListar />,
  ProgramaUsuarioListar: <ProgramaUsuarioListar />,
  DeclaracaoHabitualidadeListar: <DeclaracaoHabitualidadeListar />,
  DeclaracaoFiliacaoListar: <DeclaracaoFiliacaoListar />,
  DeclaracaoCompromissoListar: <DeclaracaoCompromissoListar />,  
  DeclaracaoModalidadeProvaListar: <DeclaracaoModalidadeProvaListar />,
  GerarDuplicataListar: <GerarDuplicataListar />,
  DadosAssociadoEditar: <DadosAssociadoEditar />,
  FichaFinanceiraAssociadoListar: <FichaFinanceiraAssociadoListar />,
  RelatorioContasReceberListar: <RelatorioContasReceberListar />,
  RecebimentoDuplicataListar: <RecebimentoDuplicataListar />,
  AlterarSenhaEditar: <AlterarSenhaEditar />,
  EnviarCobrancaPIXListar: <EnviarCobrancaPIXListar />,
  DadosEntidadeEditar: <DadosEntidadeEditar />,
  RelatorioMovimentoContasReceberListar: <RelatorioMovimentoContasReceberListar />,
  LoteCobrancaListar: <LoteCobrancaListar />,
  CampeonatoModalidadeListar: <CampeonatoModalidadeListar />,
  CampeonatoClasseListar: <CampeonatoClasseListar />,
  CampeonatoEquipeListar: <CampeonatoEquipeListar />,
  ModalidadeEntidadeListar: <ModalidadeEntidadeListar />,
  ProdutoServicoModalidadeListar: <ProdutoServicoModalidadeListar />,
  InscricaoCampeonatoListar: <InscricaoCampeonatoListar />,
  InscricaoEtapaListar: <InscricaoEtapaListar />,
  SumulaListar: <SumulaListar />,
  InscricaoSumulaListar: <InscricaoSumulaListar />,
  ProdutoServicoAssociadoListar: <ProdutoServicoAssociadoListar />,
  InscricaoSumulaResultadoListar: <InscricaoSumulaResultadoListar />,
  RelatorioInscritoEtapaListar: <RelatorioInscritoEtapaListar />,
  RelatorioFinanceiroEtapaListar: <RelatorioFinanceiroEtapaListar />,
  ResultadoCalcularClassificacaoAplicar: <ResultadoCalcularClassificacaoAplicar />,
  OutrosCampeonatoListar: <OutrosCampeonatoListar />,
  ProdutoServicoOutrosCampeonatoListar: <ProdutoServicoOutrosCampeonatoListar />,
  OutrosCampeonatoEtapaListar: <OutrosCampeonatoEtapaListar />,
  InscricaoEtapaOutrosCampeonatoListar: <InscricaoEtapaOutrosCampeonatoListar />,
  ProdutoServicoModalidadeSumulaListar: <ProdutoServicoModalidadeSumulaListar />,
  ParticipacaoAssociadoListar: <ParticipacaoAssociadoListar />,
  ListaAniversarianteListar: <ListaAniversarianteListar />,
  ListaCRListar: <ListaCRListar />,
  ConsultaCadastroAssociadoListar: <ConsultaCadastroAssociadoListar />,
  ListaRegistroHabitualidadeListar: <ListaRegistroHabitualidadeListar />,
  ModalidadeProvaListar: <ModalidadeProvaListar />,
  RelatorioPremiacaoCampeonatoListar: <RelatorioPremiacaoCampeonatoListar />,
  RelatorioPremiacaoFinalCampeonatoListar: <RelatorioPremiacaoFinalCampeonatoListar />,
  PremiacaoCampeonatoListar: <PremiacaoCampeonatoListar />,
  SumulaAvulsaListar: <SumulaAvulsaListar />,
  RelatorioParticipantesEtapaListar: <RelatorioParticipantesEtapaListar />,
};

/*
const styles = (theme) => {
  return {
    programPanel: {},
  };
};
*/

class AppMenuUsuario extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.object.isRequired,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const app = context.app;
    const appState = app.state;

    this.state = {
      ...this.state,
      programaList: [],

      record: {
        en_entidade: appState.vp_entidade,
        en_entidade_nome: appState.vp_entidadeNome,
        us_usuario: appState.vp_usuario,

        ca_campeonato: null,
        ca_campeonato_nome: null,
        et_etapa: null,
        et_etapa_nome: null,
      },
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.updateProgramaList = this.updateProgramaList.bind(this);
    this.onAtualizarSecretaria = this.onAtualizarSecretaria.bind(this);
    this.onLimparSecretaria = this.onLimparSecretaria.bind(this);
    this.onAbrir = this.onAbrir.bind(this);
    this.onAbrirInscricaoEtapaListar = this.onAbrirInscricaoEtapaListar.bind(this);
    this.onAbrirInscricaoSumulaListar = this.onAbrirInscricaoSumulaListar.bind(this);
    this.onAbrirProdutoServicoAtiradorListar = this.onAbrirProdutoServicoAtiradorListar.bind(this);
    this.onAbrirInscricaoSumulaResultadoListar = this.onAbrirInscricaoSumulaResultadoListar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.render = this.render.bind(this);
    this.renderSecretaria = this.renderSecretaria.bind(this);
    this.renderProgramaList = this.renderProgramaList.bind(this);
  }

  onOpen() {
    const props = this.props;
    const parameterMap = props.parameterMap;

    this.setState((state, props) => {
      return {
        ...state,
        programaList: this.updateProgramaList(parameterMap.programaList),
      };
    });
  }

  updateProgramaList(programaList) {
    programaList.forEach((programa, programaIndex) => {
      programa.element = programaElementMap[programa.prg_nomeFrontend];
    });

    return programaList;
  }

  onAtualizarSecretaria() {
    const state = this.state;
    const record = state.record;
    const ca_campeonato = record.ca_campeonato;
    const et_etapa = record.et_etapa;

    if (ca_campeonato !== null && et_etapa !== null) {
      this.setState((state, props) => {
        return {
          ...state,
        };
      });
    }
  }

  onLimparSecretaria() {
    this.setState((state, props) => {
      return {
        ...state,
        record: {
          ...state.record,
          ca_campeonato: null,
          ca_campeonato_nome: null,
          et_etapa: null,
          et_etapa_nome: null,
        },
      };
    });
  }

  onAbrir() {
    const state = this.state;
    const programaList = state.programaList;
    const programaListSelected = programaList.filter((programa, programaIndex) => {
      return programa["bSelected"] === true;
    });

    if (programaListSelected.length === 1) {
      const programa = programaListSelected[0];
      const programaElement = programa.element;

      this.open(programaElement);
    }
  }

  onAbrirInscricaoEtapaListar() {
    const state = this.state;
    const record = state.record;

    this.open(
      <InscricaoEtapaListar
        parameterMap={{
          ca_campeonato: record.ca_campeonato,
          ca_campeonato_nome: record.ca_campeonato_nome,
          et_etapa: record.et_etapa,
          et_etapa_nome: record.et_etapa_nome,
        }}
      />
    );
  }

  onAbrirInscricaoSumulaListar() {
    const state = this.state;
    const record = state.record;

    this.open(
      <InscricaoSumulaListar
        parameterMap={{
          ca_campeonato: record.ca_campeonato,
          ca_campeonato_nome: record.ca_campeonato_nome,
          et_etapa: record.et_etapa,
          et_etapa_nome: record.et_etapa_nome,
          data: format(new Date(), "yyyy-MM-dd"),
        }}
      />
    );
  }

  onAbrirProdutoServicoAtiradorListar() {
    const state = this.state;
    const record = state.record;

    this.open(
      <ProdutoServicoAssociadoListar
        parameterMap={{
          ca_campeonato: record.ca_campeonato,
          ca_campeonato_nome: record.ca_campeonato_nome,
          et_etapa: record.et_etapa,
          et_etapa_nome: record.et_etapa_nome,
        }}
      />
    );
  }

  onAbrirInscricaoSumulaResultadoListar() {
    const state = this.state;
    const record = state.record;

    this.open(
      <InscricaoSumulaListar
        parameterMap={{
          ca_campeonato: record.ca_campeonato,
          ca_campeonato_nome: record.ca_campeonato_nome,
          et_etapa: record.et_etapa,
          et_etapa_nome: record.et_etapa_nome,
          data: format(new Date(), "yyyy-MM-dd"),
        }}
      />
    );
  }

  onFechar() {
    window.location.reload();
  }

  /* prettier-ignore */
  render() {
    return (
      <ProgramLayout
        program={this}
        width="800px"
        height="480px"
      >
        <Header
          avatar={"L"}
          caption={'Lista de Programas'}
          description={null}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        {this.renderSecretaria()}
        {this.renderProgramaList()}
      </ProgramLayout>
    );
  }

  /* prettier-ignore */
  renderSecretaria() {
    /*
    const {
      classes
    } = this.props;
    */

    const state = this.state;
    const record = state.record;
    const ca_campeonato = record.ca_campeonato;
    const et_etapa = record.et_etapa;
    const et_etapa_nome = record.et_etapa_nome;

    const recordArrayAtalhos = [];
    const recordArray = this.state.programaList;
    const recordSecretaria = {};


    recordArray.forEach((record, recordIndex) => {
      switch (record.prg_numero) {
        case 2070: // Inscricao na Etapa
          recordArrayAtalhos.push({
            name: "etapaAtalhoInscricaoEtapa",
            label: "Inscrições\nda Etapa",
            icon: (<InscricaoEtapaIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
            onClick: this.onAbrirInscricaoEtapaListar,
            disabled: false,
          });
          break;
        case 2090: // Inscricao na Sumula
          recordArrayAtalhos.push({
            name: "etapaAtalhoInscricaoSumula",
            label: "Inscrições\ndas Súmulas",
            icon: (<InscricaoSumulaIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
            onClick: this.onAbrirInscricaoSumulaListar,
            disabled: false,
          });
          break;
        case 2100: // Produto/Serviço do Associado
          recordArrayAtalhos.push({
            name: "etapaAtalhoNull2",
            label: "Produto/Serviço\ndo Associado",
            icon: (<FinanceiroIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
            onClick: this.onAbrirProdutoServicoAtiradorListar,
            disabled: false,
          });
          break;
        case 2110: // Lançamento dos Resultados das Súmulas
          recordArrayAtalhos.push({
            name: "etapaAtalhoInscricaoSumulaResultado",
            label: "Lançar Resultados\ndas Súmulas",
            icon: (<ResultadoIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
            onClick: this.onAbrirInscricaoSumulaResultadoListar,
            disabled: false,
          });
          break;
        default:
          break;
      }
    });

    if (recordSecretaria.length === 0) {
      return null;
    } else {
      return (
        <Group
          name="filtro"
          caption={"Secretaria" + (Validation.isDefined(et_etapa_nome) ? " - " + et_etapa_nome : "")}
          expanded={false}
          collapsable={true}
          collapsed={true}
          toolbar={
            <Menu.IconButton name="limpar" icon={<ReplayIcon/>} text="Limpar" onClick={this.onLimparSecretaria}/>
          }
          menu={null}
        >
          <React.Fragment>
            {((ca_campeonato === null) || (et_etapa === null)) && (
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonato/referenciar"}
                    urlParameterMap={() => {
                      return {
                        ca_entidade: record.en_entidade,
                      };
                    }}
                    onChange={this.onAtualizarSecretaria}
                    columnArray={[
                      (<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="ca_campeonato" record={record} label='Campeonato' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="ca_campeonato_nome" record={record} label="Nome Campeonato"/>
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/etapa/referenciarSecretaria"}
                    urlParameterMap={() => {
                      return {
                        "et_campeonato": record.ca_campeonato,
                        "et_usuario": record.us_usuario,
                      };
                    }}
                    onChange={this.onAtualizarSecretaria}
                    columnArray={[
                      (<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="et_etapa" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="et_etapa_nome" record={record} label="Etapa "/>
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
            )}
            {((ca_campeonato !== null) && (et_etapa !== null)) && (
              <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-evenly'}}>
                {recordArrayAtalhos.map((atalho, atalhoIndex) => {
                  return (
                    <div /*className={classes.group}*/ key={"atalho_" + atalhoIndex}>
                      <div /*className={classes.groupLayout}*/ style={{flexFlow: "row wrap"}}>
                        <div /*className={classes.group}*/ style={{width: "150px"}}>
                          <Button disableFocusRipple name={atalho.name} onClick={atalho.onClick} variant="text" size="large" color="primary" fullWidth={true} style={{justifyContent: "flex-start", textTransform: 'none', whiteSpace: "normal"}} disabled={atalho.disabled}>
                            <div style={{flex: "1 0 auto", display: "flex", flexFlow: "column wrap", justifyContent: "center"}}>
                              {atalho.icon}
                              {atalho.label.split("\n").map((line, lineIndex) => {
                                return (
                                  <div key={lineIndex}>
                                    {(Validation.isString(line) && line.length === 0 ? <br/> : line)}
                                  </div>
                                );
                              })}
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  );})}
              </div>
            )}
          </React.Fragment>
        </Group>
      );
    }
  }

  /* prettier-ignore */
  renderProgramaList() {
    const state = this.state;
    const programaList = state.programaList;

    return (
      <React.Fragment>
        <Table
          name='table'
          caption='Menu Princical'
          collapsable={false}
          collapsed={false}
          expanded={true}
          recordArray={programaList}
          selectionType='single'
          selectionColumnName='bSelected'
          toolbar={null}
          menu={null}
          columnArray={[
            (<Table.ColumnNumber name="prg_numero" label="Numero" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
            (<Table.ColumnString name='prg_nome' label='Nome' expanded={false} cellWidth={240} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
            (<Table.ColumnString name='prg_finalidade' label='Descrição' expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
          ]}
          fixedRowCount={1}
          fixedColumnCount={0}
        />
        <div style={{display: 'flex', flexFlow: 'row wrap', margin: '8px'}}>
          <div style={{flex: '1 1 auto'}}/>
          <Button name='abrir' variant="contained" size='small' onClick={this.onAbrir}>Abrir</Button>
        </div>
      </React.Fragment>
    );
  }
}

//export default AppMenuUsuario;
// export default withStyles(styles, {withTheme: true})(AppMenuUsuario);
export default AppMenuUsuario;
