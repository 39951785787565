import React from 'react';
// import AirbnbPropTypes from 'airbnb-prop-types';
import FieldLayout from './FieldLayout';
import FieldWrapper from './FieldWrapper';

class FieldRange extends React.Component {

  static propTypes = {
    // children: AirbnbPropTypes.nChildren(2),
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <FieldLayout {...otherProps}>
        <FieldWrapper variant='range'>
          {children[0]}
        </FieldWrapper>
        <FieldWrapper variant='range'>
          {children[1]}
        </FieldWrapper>
      </FieldLayout>
    );
  }

}

export default FieldRange;
