import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      du_duplicata: PropTypes.number,
      du_entidade: PropTypes.number,
      du_entidade_nome: PropTypes.string,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.du_duplicata) ? false : true;
    const componentFocused = "du_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onCancelar = this.onCancelar.bind(this);
    this.onDeletar = this.onDeletar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/duplicata/editar/criar" : "/duplicata/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  async onSalvar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/duplicata/editar/inserir" : "/duplicata/editar/alterar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onCancelar() {
    try {
      const state = this.state;
      const record = state.record;
      // const recordIsNew = state.recordIsNew;

      const url = "/duplicata/editar/cancelar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const reponseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: reponseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  async onDeletar() {
    try {
      const state = this.state;
      const record = state.record;

      const url = "/duplicata/editar/deletar";
      const parameterMap = record;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState(
        (state, props) => {
          return {
            ...state,
            success: true,
            result: responseRecord,
          };
        },
        () => {
          this.close();
        }
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/duplicata/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;
    const en_permiteDeletarDuplicata = state.record.en_permiteDeletarDuplicata;
    const du_dataCancelamento = state.record.du_dataCancelamento;
    const du_valor = state.record.du_valor;
    const du_saldo = state.record.du_saldo;

console.log("du_dataCancelamento");
console.log(du_dataCancelamento);
    return (
      <ProgramLayout
        program={this}
        width="480px"
        height="minimum"
      >
        <Header
          avatar="P"
          caption={(recordIsNew ? "Adicionar duplicata" : "Editar duplicata")}
          description={"Editor de duplicata"}
          toolbar={
            <Menu.IconButton name='fechar' icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <Group
          name="duplicata"
          caption="duplicata"
          expanded={false}
          collapsable={false}
          collapsed={false}
          toolbar={null}
          menu={null}
        >
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/associado/referenciar"}
                urlParameterMap={() => {
                  return {
                    as_entidade: record.du_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="du_associado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={(recordIsNew === false)}/>
                <Field.Input.String name="du_associado_nome" record={record} label="Associado " disabled={(recordIsNew === false)}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="du_numero" label={(recordIsNew === true ? "Numero (opcional)" : "Numero")}  prefix="" thousandSeparator="" decimalScale={0} disabled={(recordIsNew === false)}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="du_parcela" label={(recordIsNew === true ? "Parcela (opcional)" : "Parcela")}  prefix="" thousandSeparator="" decimalScale={0} disabled={(recordIsNew === false)}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Date name="du_dataEmissao" record={record} label='Emissão' prefix="" onChange={this.onAtualizar}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Date name="du_dataVencimento" record={record} label='Vencimento' prefix="" onChange={this.onAtualizar}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="du_valor" label="Valor" prefix="" thousandSeparator="" decimalScale={2} disabled={false}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="du_observacao" label="Duplicata Referente a ..."/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/banco/referenciar"}
                urlParameterMap={() => {
                  return {
                    ba_entidade: record.du_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="ba_banco" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="ba_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="du_banco" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={false}/>
                <Field.Input.String name="du_banco_nome" record={record} label="Banco" disabled={false}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/cobrador/referenciar"}
                urlParameterMap={() => {
                  return {
                    cob_entidade: record.du_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="cob_cobrador" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="cob_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="du_cobrador" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={false}/>
                <Field.Input.String name="du_cobrador_nome" record={record} label="Cobrador" disabled={false}/>
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[
            <Button name="deletar" variant="contained" size="small" onClick={this.onDeletar} disabled={(recordIsNew === true || en_permiteDeletarDuplicata === 'N') || du_saldo !== du_valor || du_dataCancelamento !== null }>Deletar</Button>,
            <Button name="cancelar" variant="contained" size="small" onClick={this.onCancelar} disabled={(recordIsNew === true || du_saldo !== du_valor || du_dataCancelamento !== null )}>Cancelar Duplicata</Button>,
          ]}
          rightButtonList={[
            <Button name="voltar" variant="contained" size="small" onClick={this.onFechar}>Voltar</Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onSalvar}>Salvar</Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
