import React from "react";
import Component from "../component/Component";
import TableCell from "./TableCell";
import TableCellAlign from "./TableCellAlign";
import TableCellCheckBox from "./TableCellCheckBox";
import TableCellHeaderCheckBox from "./TableCellHeaderCheckBox";
import TableCellHeaderString from "./TableCellHeaderString";
import TableCellRadioButton from "./TableCellRadioButton";
import TableCellString from "./TableCellString";
import TableColumnCheckBox from "./TableColumnCheckBox";
import TableColumnRadioButton from "./TableColumnRadioButton";
import TableColumnString from "./TableColumnString";
import TableColumnNumber from "./TableColumnNumber";
import TableColumnDate from "./TableColumnDate";
import TableSelectionType from "./TableSelectionType";
import TableSortOrder from "./TableSortOrder";
import Context from "../context/Context";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Validation from "../validation/Validation";
import Group from "../group/Group";
import { AutoSizer, ArrowKeyStepper, MultiGrid } from "react-virtualized";
import Chip from "../chip/Chip";
import SearchIcon from "@mui/icons-material/Search";
// import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    tableContainer: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      minHeight: theme.spacing(37),
      margin: theme.spacing(0),
      marginBottom: theme.spacing(-1),
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      whiteSpace: 'nowrap',
    },
    tableToolbarContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    searchChip: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    searchChipLabel: {
      maxWidth: theme.spacing(8),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      direction: 'rtl',
    }
  };
});
*/

class Table extends Component {
  static Cell = TableCell;
  static CellAlign = TableCellAlign;
  static CellCheckBox = TableCellCheckBox;
  static CellHeaderCheckBox = TableCellHeaderCheckBox;
  static CellHeaderString = TableCellHeaderString;
  static CellRadioButton = TableCellRadioButton;
  static CellString = TableCellString;
  static ColumnCheckBox = TableColumnCheckBox;
  static ColumnRadioButton = TableColumnRadioButton;
  static ColumnString = TableColumnString;
  static ColumnNumber = TableColumnNumber;
  static ColumnDate = TableColumnDate;
  static SelectionType = TableSelectionType;
  static SortOrder = TableSortOrder;

  static contextType = Context;

  static propTypes = {
    name: PropTypes.string.isRequired,
    avatar: PropTypes.node,
    title: PropTypes.node,
    caption: PropTypes.node,
    description: PropTypes.node,
    toolbar: PropTypes.node,
    menu: PropTypes.node,
    collapsable: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    recordArray: PropTypes.array.isRequired,
    columnArray: PropTypes.array.isRequired,
    fixedRowCount: PropTypes.number.isRequired,
    fixedColumnCount: PropTypes.number.isRequired,
    selectionType: PropTypes.oneOf(Object.values(TableSelectionType)).isRequired,
    selectionColumnName: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validate: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onActivate: PropTypes.func,
    onChange: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    name: null,
    avatar: null,
    title: null,
    caption: null,
    description: null,
    toolbar: null,
    menu: null,
    collapsable: null,
    collapsed: null,
    expanded: null,
    recordArray: null,
    columnArray: null,
    fixedRowCount: null,
    fixedColumnCount: null,
    selectionType: null,
    selectionColumnName: null,
    required: null,
    validate: null,
    onFocus: null,
    onBlur: null,
    onActivate: null,
    onChange: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const columnElementArray = [...props.columnArray];

    const selectionType = props.selectionType;
    const selectionColumnName = props.selectionColumnName;

    switch (selectionType) {
      case TableSelectionType.None:
        break;
      case TableSelectionType.Single:
        columnElementArray.unshift(<TableColumnRadioButton name={selectionColumnName} label="Sel." expanded={false} cellWidth={64} cellHeight={40} fixedCellWidth={64} fixedCellHeight={56} />);
        break;
      case TableSelectionType.Multiple:
        columnElementArray.unshift(<TableColumnCheckBox name={selectionColumnName} label="Sel." expanded={false} cellWidth={64} cellHeight={40} fixedCellWidth={64} fixedCellHeight={56} />);
        break;
      default:
        break;
    }

    const columnArray = [];

    columnElementArray.forEach((columnElement) => {
      const columnElementProps = columnElement.props;
      const column = columnElementProps;

      columnArray.push(column);
    });

    this.state = {
      width: 0,
      height: 0,
      focused: false,
      enabled: false,

      rowFixedCount: 1,

      columnArray: columnArray,

      cursorRowIndex: -1,
      cursorColumnIndex: -1,

      sortColumnIndex: null,
      sortOrder: TableSortOrder.Ascending,

      searchString: "",

      ...this.state,
    };

    this.reference = {
      multiGrid: null,
      multiGridContainer: null,
    };

    this.initializeSelectionColumnValues = this.initializeSelectionColumnValues.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);

    this.validate = this.validate.bind(this);
    this.validateSelection = this.validateSelection.bind(this);
    this.validateRequired = this.validateRequired.bind(this);
    this.validateFunction = this.validateFunction.bind(this);
    this.focus = this.focus.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
    this.getColumnWidth = this.getColumnWidth.bind(this);
    this.getColumnWidthTotal = this.getColumnWidthTotal.bind(this);
    this.getColumnExpandedCount = this.getColumnExpandedCount.bind(this);
    this.getSelectionArray = this.getSelectionArray.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onScrollToChange = this.onScrollToChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onActivate = this.onActivate.bind(this);
    this.onActivateFixedRow = this.onActivateFixedRow.bind(this);
    this.onActivateFixedRowSelectionColumn = this.onActivateFixedRowSelectionColumn.bind(this);
    this.onActivateRow = this.onActivateRow.bind(this);
    this.onActivateRowSelectionColumn = this.onActivateRowSelectionColumn.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyDownSearch = this.onKeyDownSearch.bind(this);
    this.onKeyDownEnter = this.onKeyDownEnter.bind(this);
    this.onKeyDownEsc = this.onKeyDownEsc.bind(this);
    this.onKeyDownLeft = this.onKeyDownLeft.bind(this);
    this.onKeyDownRight = this.onKeyDownRight.bind(this);
    this.onKeyDownUp = this.onKeyDownUp.bind(this);
    this.onKeyDownDown = this.onKeyDownDown.bind(this);
    this.onKeyDownBackspace = this.onKeyDownBackspace.bind(this);
    this.onKeyDownF3 = this.onKeyDownF3.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchChipClick = this.onSearchChipClick.bind(this);
    this.onSearchChipClose = this.onSearchChipClose.bind(this);
    this.onRef = this.onRef.bind(this);
    this.render = this.render.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderCell = this.renderCell.bind(this);

    this.initializeSelectionColumnValues();
  }

  /* Correção os valores não booleanos na coluna de seleção */

  initializeSelectionColumnValues() {
    const props = this.props;
    const recordArray = props.recordArray;
    const selectionColumnName = props.selectionColumnName;

    recordArray.forEach((record, recordIndex) => {
      record[selectionColumnName] = record[selectionColumnName] === true || record[selectionColumnName] === 1;
    });

    const reference = this.reference;

    if (reference && reference.multiGrid) {
      const multiGrid = reference.multiGrid;

      multiGrid.forceUpdateGrids();
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    const recordArray = props.recordArray;
    const prevPropsRecordArray = prevProps.recordArray;
    const recordArrayIsChanged = prevPropsRecordArray !== recordArray;
    // const selectionColumnName = props.selectionColumnName;

    if (recordArrayIsChanged) {
      this.initializeSelectionColumnValues();
    }
  }

  /* Correção para colunas de seleção com valores inteiros */

  validate(value) {
    const valueIsDefined = typeof value !== "undefined";

    if (!valueIsDefined) {
      value = this.getValue();
    }

    this.validateSelection(value);
    this.validateRequired(value);
    this.validateFunction(value);
  }

  validateSelection(value) {
    const props = this.props;
    const selectionType = props.selectionType;

    if (selectionType !== TableSelectionType.None) {
      const selectionColumnName = props.selectionColumnName;
      var selectionArray = [];

      value.forEach((record) => {
        const recordSelectionColumnValue = record[selectionColumnName];
        const recordIsSelected = recordSelectionColumnValue === true;

        if (recordIsSelected) {
          selectionArray.push(record);
        }
      });

      if (selectionType === TableSelectionType.Single) {
        const selectionArrayLength = selectionArray.length;
        const selectionArrayIsSingle = selectionArrayLength === 1;

        if (!selectionArrayIsSingle) {
          const valueCurrent = this.getValue();
          const error = {
            field: this,
            valueCurrent: valueCurrent,
            value: value,
            message: "Seleção única",
          };

          throw error;
        }
      }
    }
  }

  validateRequired(value) {
    const props = this.props;
    const required = props.required;
    const valueIsRequired = required === true;
    const valueIsDefined = Validation.isDefined(value);
    const valueIsArray = valueIsDefined && Validation.isArray(value);
    const valueIsEmpty = valueIsArray && Validation.isEmpty(value);

    if (valueIsRequired && (!valueIsDefined || (valueIsArray && valueIsEmpty))) {
      const valueCurrent = this.getValue();
      const error = {
        field: this,
        valueCurrent: valueCurrent,
        value: value,
        message: "Seleção obrigatória",
      };

      throw error;
    }
  }

  validateFunction(value) {
    const props = this.props;
    const validate = props.validate;
    const validateIsFunction = Validation.isFunction(validate);

    if (validateIsFunction) {
      const valueCurrent = this.getValue();

      validate(this, valueCurrent, value);
    }
  }

  focus() {
    /* the setTimeout command is a required workaround as of 12/2019 */
    setTimeout(() => {
      const reference = this.reference;
      const multiGrid = reference.multiGrid;

      if (Validation.isDefined(multiGrid) && Validation.isDefined(multiGrid._bottomRightGrid)) {
        const bottomRightGrid = multiGrid._bottomRightGrid;
        const bottomRightGridDOMNode = ReactDOM.findDOMNode(bottomRightGrid);

        bottomRightGridDOMNode.focus();
      }
    }, 0);
  }

  getPosition() {
    const reference = this.reference;
    const multiGridContainer = reference.multiGridContainer;
    const position = multiGridContainer.getBoundingClientRect();

    return position;
  }

  getRowHeight() {
    const state = this.state;
    const columnArray = state.columnArray;
    var rowHeight = 0;
    var fixedRowHeight = 0;

    columnArray.forEach((column) => {
      const cellHeight = column.cellHeight;
      const fixedCellHeight = column.fixedCellHeight;

      rowHeight = Math.max(rowHeight, cellHeight);
      fixedRowHeight = Math.max(fixedRowHeight, fixedCellHeight);
    });

    const props = this.props;
    const fixedRowCount = props.fixedRowCount;

    return (parameters) => {
      const rowIndex = parameters.index;
      const rowIsFixed = rowIndex < fixedRowCount;

      if (rowIsFixed) {
        return fixedRowHeight;
      } else {
        return rowHeight;
      }
    };
  }

  getColumnWidth() {
    const props = this.props;
    const recordArray = props.recordArray;
    const recordArrayLength = recordArray.length;
    const fixedRowCount = props.fixedRowCount;

    const fixedRowHeightParameters = { index: fixedRowCount - 1 };
    const fixedRowHeight = this.getRowHeight()(fixedRowHeightParameters);

    const rowHeightParameters = { index: fixedRowCount };
    const rowHeight = this.getRowHeight()(rowHeightParameters);
    const rowHeightTotal = fixedRowCount * fixedRowHeight + recordArrayLength * rowHeight;

    const state = this.state;
    const height = state.height;
    const width = state.width;

    const scrollbarVerticalVisible = rowHeightTotal > height;
    const scrollbarWidth = 6;

    const columnExpandedCount = this.getColumnExpandedCount();
    const columnWidthTotal = this.getColumnWidthTotal();
    const columnWidthExpandedTotal = width - columnWidthTotal - (scrollbarVerticalVisible ? scrollbarWidth : 0);
    const columnWidthExpanded = columnWidthExpandedTotal > 0 ? columnWidthExpandedTotal / columnExpandedCount : 0;

    return (parameters) => {
      const columnArray = state.columnArray;
      const columnIndex = parameters.index;
      const column = columnArray[columnIndex];
      const columnCellWidth = column.cellWidth;
      const columnFixedCellWidth = column.fixedCellWidth;
      const columnWidth = Math.max(columnCellWidth, columnFixedCellWidth);

      const columnExpanded = column.expanded;
      const columnIsExpanded = columnExpanded === true;

      if (columnIsExpanded) {
        return columnWidth + columnWidthExpanded;
      } else {
        return columnWidth;
      }
    };
  }

  getColumnWidthTotal() {
    const state = this.state;
    const columnArray = state.columnArray;
    var columnWidthTotal = 0;

    columnArray.forEach((column) => {
      const columnCellWidth = column.cellWidth;
      const columnFixedCellWidth = column.fixedCellWidth;
      const columnWidth = Math.max(columnCellWidth, columnFixedCellWidth);

      columnWidthTotal = columnWidthTotal + columnWidth;
    });

    return columnWidthTotal;
  }

  getColumnExpandedCount() {
    const state = this.state;
    const columnArray = state.columnArray;
    var columnExpandedCount = 0;

    columnArray.forEach((column) => {
      const columnExpanded = column.expanded;
      const columnIsExpanded = columnExpanded === true;

      if (columnIsExpanded) {
        columnExpandedCount = columnExpandedCount + 1;
      }
    });

    return columnExpandedCount;
  }

  getSelectionArray() {
    const props = this.props;
    const selectionType = props.selectionType;
    var selectionArray = [];

    if (selectionType !== TableSelectionType.None) {
      const recordArray = props.recordArray;
      const selectionColumnName = props.selectionColumnName;

      recordArray.forEach((record) => {
        const recordSelectionColumnValue = record[selectionColumnName];
        const recordIsSelected = recordSelectionColumnValue === true;

        if (recordIsSelected) {
          selectionArray.push(record);
        }
      });
    }

    return selectionArray;
  }

  getValue() {
    return this.props.recordArray;
  }

  onFocus(event) {
    const props = this.props;
    const name = props.name;
    const context = this.context;
    const program = context.program;

    program.setState(
      (state, props) => {
        return {
          ...state,
          componentFocused: name,
        };
      },
      () => {
        this.setState(
          (state, props) => {
            const fixedRowCount = props.fixedRowCount;
            const recordArray = props.recordArray;
            const recordArrayLength = recordArray.length;
            const columnArray = state.columnArray;
            const columnArrayLength = columnArray.length;
            var cursorRowIndex = state.cursorRowIndex;
            var cursorColumnIndex = state.cursorColumnIndex;

            cursorRowIndex = cursorRowIndex <= fixedRowCount + recordArrayLength - 1 ? cursorRowIndex : fixedRowCount + recordArrayLength - 1;
            cursorRowIndex = cursorRowIndex >= 0 ? cursorRowIndex : 0;
            cursorColumnIndex = cursorColumnIndex <= columnArrayLength - 1 ? cursorColumnIndex : columnArrayLength - 1;
            cursorColumnIndex = cursorColumnIndex >= 0 ? cursorColumnIndex : 0;

            return {
              ...state,
              focused: true,
              cursorRowIndex: cursorRowIndex,
              cursorColumnIndex: cursorColumnIndex,
            };
          },
          () => {
            const onFocus = props.onFocus;
            const onFocusIsFunction = Validation.isFunction(onFocus);

            if (onFocusIsFunction) {
              onFocus(event);
            }
          }
        );
      }
    );
  }

  onBlur(event) {
    this.setState(
      (state, props) => {
        return {
          ...state,
          focused: false,
          enabled: false,
        };
      },
      () => {
        const props = this.props;
        const onBlur = props.onBlur;
        const onBlurIsFunction = Validation.isFunction(onBlur);

        if (onBlurIsFunction) {
          onBlur(event);
        }
      }
    );
  }

  onResize(parameters) {
    const width = parameters.width;
    const height = parameters.height;

    this.setState(
      (state, props) => {
        return {
          width: width,
          height: height,
        };
      },
      () => {
        const reference = this.reference;
        const multiGrid = reference.multiGrid;

        multiGrid.recomputeGridSize();
      }
    );
  }

  onScrollToChange(parameters) {
    const state = this.state;
    const enabled = state.enabled;

    if (enabled) {
      const cursorRowIndex = parameters.scrollToRow;
      const cursorColumnIndex = parameters.scrollToColumn;

      this.setState((state, props) => {
        return {
          ...state,
          cursorRowIndex: cursorRowIndex,
          cursorColumnIndex: cursorColumnIndex,
        };
      });
    }
  }

  onClick(rowIndex, columnIndex) {
    this.setState(
      (state, props) => {
        var cursorRowIndex = rowIndex;
        var cursorColumnIndex = columnIndex;

        return {
          ...state,
          cursorRowIndex: cursorRowIndex,
          cursorColumnIndex: cursorColumnIndex,
          focused: true,
          enabled: true,
        };
      },
      () => {
        this.onActivate();

        const props = this.props;
        const fixedRowCount = props.fixedRowCount;
        const fixedColumnCount = props.fixedColumnCount;
        const rowIsFixed = rowIndex < fixedRowCount;
        const columnIsFixed = columnIndex < fixedColumnCount;

        if (rowIsFixed || columnIsFixed) {
          this.focus();
        }
      }
    );
  }

  onActivate() {
    const props = this.props;
    const fixedRowCount = props.fixedRowCount;
    const selectionColumnName = props.selectionColumnName;

    const state = this.state;
    const cursorRowIndex = state.cursorRowIndex;
    const cursorRowIsFixed = cursorRowIndex < fixedRowCount;

    const cursorColumnIndex = state.cursorColumnIndex;
    const columnArray = state.columnArray;
    const column = columnArray[cursorColumnIndex];
    const columnName = column.name;
    const cursorColumnIsSelectionColumn = selectionColumnName === columnName;

    if (cursorRowIsFixed) {
      if (cursorColumnIsSelectionColumn) {
        this.onActivateFixedRowSelectionColumn();
      } else {
        this.onActivateFixedRow();
      }
    } else {
      /*
      if (cursorColumnIsSelectionColumn) {
        this.onActivateRowSelectionColumn();
      }
      this.onActivateRow();
      */

      const selectionType = props.selectionType;

      if (selectionType !== TableSelectionType.None) {
        this.onActivateRowSelectionColumn();
      }
      this.onActivateRow();
    }

    const reference = this.reference;
    const multiGrid = reference.multiGrid;

    multiGrid.forceUpdateGrids();

    /* added later: clear search */
    this.setState((state, props) => {
      return {
        ...state,
        searchString: "",
      };
    });
  }

  onActivateFixedRow() {
    const props = this.props;
    const recordArray = props.recordArray;

    const state = this.state;
    const cursorColumnIndex = state.cursorColumnIndex;
    const sortColumnIndex = state.sortColumnIndex;
    var sortOrder = state.sortOrder;

    if (cursorColumnIndex === sortColumnIndex) {
      recordArray.reverse();

      sortOrder = sortOrder === TableSortOrder.Ascending ? TableSortOrder.Descending : TableSortOrder.Ascending;
    } else {
      const columnArray = state.columnArray;
      const column = columnArray[cursorColumnIndex];
      const columnSort = column.sort;
      const columnSortIsFunction = Validation.isFunction(columnSort);

      if (columnSortIsFunction) {
        recordArray.sort((record1, record2) => {
          return columnSort({
            column: column,
            record1: record1,
            record2: record2,
          });
        });

        sortOrder = TableSortOrder.Ascending;
      }
    }

    this.setState((state, props) => {
      return {
        ...state,
        sortColumnIndex: cursorColumnIndex,
        sortOrder: sortOrder,
      };
    });
  }

  onActivateFixedRowSelectionColumn() {
    /* a 'this.props.[prop_name]' is a required workaround as of 12/2019 */
    const props = this.props;
    const selectionType = props.selectionType;

    if (selectionType === TableSelectionType.Multiple) {
      const recordArray = this.props.recordArray;
      const recordArrayLength = recordArray.length;
      const selectionArray = this.getSelectionArray();
      const selectionArrayLength = selectionArray.length;
      const selectionIsFull = selectionArrayLength === recordArrayLength ? true : false;
      const selectionColumnName = this.props.selectionColumnName;

      recordArray.forEach((record) => {
        const recordSelectionColumnValue = selectionIsFull === false ? true : false;

        record[selectionColumnName] = recordSelectionColumnValue;
      });

      const props = this.props;
      const onChange = props.onChange;
      const onChangeIsFunction = Validation.isFunction(onChange);

      if (onChangeIsFunction) {
        onChange();
      }
    }
  }

  onActivateRow() {
    const props = this.props;
    const onActivate = props.onActivate;
    const onActivateIsFunction = Validation.isFunction(onActivate);

    if (onActivateIsFunction) {
      onActivate();
    }
  }

  onActivateRowSelectionColumn() {
    const props = this.props;
    const selectionType = props.selectionType;

    if (selectionType === TableSelectionType.Single) {
      const state = this.state;
      const recordArray = props.recordArray;
      const cursorRowIndex = state.cursorRowIndex;
      const fixedRowCount = props.fixedRowCount;
      const selectionColumnName = props.selectionColumnName;

      recordArray.forEach((record, recordIndex) => {
        const recordSelectionColumnValue = recordIndex === cursorRowIndex - fixedRowCount;

        record[selectionColumnName] = recordSelectionColumnValue;
      });

      const onChange = props.onChange;
      const onChangeIsFunction = Validation.isFunction(onChange);

      if (onChangeIsFunction) {
        onChange();
      }
    }

    if (selectionType === TableSelectionType.Multiple) {
      const state = this.state;
      const cursorRowIndex = state.cursorRowIndex;
      const fixedRowCount = props.fixedRowCount;
      const selectionColumnName = props.selectionColumnName;
      const recordArray = props.recordArray;
      const recordIndex = cursorRowIndex - fixedRowCount;
      const record = recordArray[recordIndex];
      const recordSelectionColumnValue = record[selectionColumnName];
      const recordSelectionColumnValueNew = !(recordSelectionColumnValue === true);

      record[selectionColumnName] = recordSelectionColumnValueNew;

      const onChange = props.onChange;
      const onChangeIsFunction = Validation.isFunction(onChange);

      if (onChangeIsFunction) {
        onChange();
      }
    }

    const onActivate = props.onActivate;
    const onActivateIsFunction = Validation.isFunction(onActivate);

    if (onActivateIsFunction) {
      onActivate();
    }
  }

  onKeyDown(event) {
    const keyCode = event.keyCode;

    const key = event.key + "";

    const searchKeyString = " '\"1!2@3#4$5%6^7&8*9(0)-_=+qQwWeErRtTyYuUiIoOpP'`[{ªaAsSdDfFgGhHjJkKlLç~^]}º\\|zZxXcCvVbBnNmM,<.>;:/?°";
    const keyIsSearchKey = searchKeyString.includes(key);

    if (keyIsSearchKey) {
      this.onKeyDownSearch(event);
      return;
    }

    switch (keyCode) {
      case 13:
        this.onKeyDownEnter(event);
        break;
      case 27:
        this.onKeyDownEsc(event);
        break;
      case 37:
        this.onKeyDownLeft(event);
        break;
      case 39:
        this.onKeyDownRight(event);
        break;
      case 38:
        this.onKeyDownUp(event);
        break;
      case 40:
        this.onKeyDownDown(event);
        break;
      case 8:
        this.onKeyDownBackspace(event);
        break;
      case 114:
        this.onKeyDownF3(event);
        break;
      default:
        break;
    }
  }

  onKeyDownSearch(event) {
    event.preventDefault();

    const key = event.key + "";
    const state = this.state;
    const searchString = state.searchString + key;

    this.onSearch(searchString);
  }

  onKeyDownEnter(event) {
    const state = this.state;
    const enabled = state.enabled;

    if (enabled) {
      this.onActivate();
    } else {
      this.setState((state, props) => {
        return {
          ...state,
          enabled: true,
        };
      });
    }
  }

  onKeyDownEsc(event) {
    const state = this.state;
    const enabled = state.enabled;

    if (enabled) {
      this.setState((state, props) => {
        return {
          ...state,
          enabled: false,
        };
      });
    } else {
      const context = this.context;
      const program = context.program;
      const programFocusLeft = program.focusLeft;
      const programFocusLeftResult = programFocusLeft(this);
      const programFocusLeftFailed = programFocusLeftResult === false;

      if (programFocusLeftFailed) {
        const programFocusUp = program.focusUp;
        const programFocusUpResult = programFocusUp(this);
        const programFocusUpFailed = programFocusUpResult === false;

        if (programFocusUpFailed) {
          const programManager = context.programManager;

          programManager.close();
        }
      }
    }
  }

  onKeyDownLeft(event) {
    const state = this.state;
    const enabled = state.enabled;

    if (!enabled) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;

      program.focusLeft(this);
    }
  }

  onKeyDownRight(event) {
    const state = this.state;
    const enabled = state.enabled;

    if (!enabled) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;

      program.focusRight(this);
    }
  }

  onKeyDownUp(event) {
    const state = this.state;
    const enabled = state.enabled;

    if (!enabled) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;

      program.focusUp(this);
    }
  }

  onKeyDownDown(event) {
    const state = this.state;
    const enabled = state.enabled;

    if (!enabled) {
      event.preventDefault();

      const context = this.context;
      const program = context.program;

      program.focusDown(this);
    }
  }

  onKeyDownBackspace(event) {
    event.preventDefault();

    this.setState((state, props) => {
      const searchString = state.searchString;
      const searchStringLength = searchString.length;
      const searchStringNew = searchString.substring(0, searchStringLength - 1);

      return {
        ...state,
        searchString: searchStringNew,
      };
    });
  }

  onKeyDownF3(event) {
    event.preventDefault();

    const state = this.state;
    const searchString = state.searchString;

    this.onSearch(searchString);
  }

  onSearch(searchString) {
    const searchStringIsString = Validation.isString(searchString);

    if (searchStringIsString) {
      const searchStringIsEmpty = Validation.isEmpty(searchString);

      if (searchStringIsEmpty) {
        const state = this.state;
        const stateSeachString = state.searchString;
        const searchStringIsChanged = searchString !== stateSeachString;

        if (searchStringIsChanged) {
          this.setState((state, props) => {
            return {
              ...state,
              searchString: searchString,
            };
          });
        }
      } else {
        const props = this.props;
        const state = this.state;

        const fixedRowCount = props.fixedRowCount;
        const stateSeachString = state.searchString;
        const searchStringIsChanged = searchString !== stateSeachString;

        const cursorRowIndex = state.cursorRowIndex;
        const cursorColumnIndex = state.cursorColumnIndex;

        const recordArray = props.recordArray;
        const recordArrayLength = recordArray.length;

        const columnArray = state.columnArray;
        const columnArrayLength = columnArray.length;

        var recordIndex = !searchStringIsChanged ? Math.max(0, cursorRowIndex - fixedRowCount) : 0;

        for (recordIndex; recordIndex < recordArrayLength; recordIndex++) {
          const record = recordArray[recordIndex];

          var columnIndex = !searchStringIsChanged && recordIndex === cursorRowIndex - fixedRowCount ? cursorColumnIndex : 0;

          for (columnIndex; columnIndex < columnArrayLength; columnIndex++) {
            if (!(!searchStringIsChanged && recordIndex === cursorRowIndex - fixedRowCount && columnIndex === cursorColumnIndex)) {
              const column = columnArray[columnIndex];
              const columnSearch = column.search;
              const columnSearchIsFunction = Validation.isFunction(columnSearch);

              if (columnSearchIsFunction) {
                const columnSearchResult = columnSearch({
                  column: column,
                  record: record,
                  searchString: searchString,
                });

                if (columnSearchResult === true) {
                  const cursorRowIndex = recordIndex + fixedRowCount;
                  const cursorColumnIndex = columnIndex;

                  this.setState((state, props) => {
                    return {
                      ...state,
                      searchString: searchString,
                      cursorRowIndex: cursorRowIndex,
                      cursorColumnIndex: cursorColumnIndex,
                    };
                  });

                  return;
                }
              }
            }
          }
        }

        this.setState((state, props) => {
          return {
            ...state,
            searchString: searchString,
          };
        });
      }
    }
  }

  onSearchChipClick() {
    const state = this.state;
    const searchString = state.searchString;

    this.onSearch(searchString);

    this.setState(
      (state, props) => {
        return {
          ...state,
          focused: true,
          enabled: true,
        };
      },
      () => {
        this.focus();
      }
    );
  }

  onSearchChipClose() {
    this.setState(
      (state, props) => {
        return {
          ...state,
          focused: true,
          enabled: true,
          searchString: "",
        };
      },
      () => {
        this.focus();
      }
    );
  }

  onRef(multiGrid) {
    const reference = this.reference;

    try {
      ReactDOM.findDOMNode(reference.multiGrid._bottomRightGrid).removeEventListener("focus", this.onFocus);
    } catch (error) {}
    try {
      ReactDOM.findDOMNode(reference.multiGrid._bottomRightGrid).removeEventListener("blur", this.onBlur);
    } catch (error) {}
    try {
      ReactDOM.findDOMNode(reference.multiGrid._bottomRightGrid).removeEventListener("keyDown", this.onKeyDown);
    } catch (error) {}

    reference.multiGrid = multiGrid;

    try {
      ReactDOM.findDOMNode(reference.multiGrid._bottomRightGrid).addEventListener("focus", this.onFocus);
    } catch (error) {}
    try {
      ReactDOM.findDOMNode(reference.multiGrid._bottomRightGrid).addEventListener("blur", this.onBlur);
    } catch (error) {}
    try {
      ReactDOM.findDOMNode(reference.multiGrid._bottomRightGrid).addEventListener("keydown", this.onKeyDown);
    } catch (error) {}
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      name,
      avatar,
      title,
      caption,
      description,
      toolbar,
      menu,
      collapsable,
      collapsed,
      expanded,
      recordArray,
      columnArray: propsColumnArray,
      fixedRowCount,
      fixedColumnCount,
      selectionType,
      selectionColumnName,
      required,
      validate,
      onFocus,
      onBlur,
      onActivate,
      onChange,
      children,
      ...otherProps
    } = this.props;

    const {
      columnArray,
      cursorRowIndex,
      cursorColumnIndex,
    } = this.state;

    const recordArrayLength = recordArray.length;
    const columnArrayLength = columnArray.length;

    const rowCount = fixedRowCount + recordArrayLength; //rowArray.length;
    const columnCount = columnArrayLength;

    return (
      <Group
        name={name + '_group'}
        avatar={avatar}
        title={title}
        caption={caption}
        description={description}
        toolbar={this.renderToolbar()}
        menu={this.renderMenu()}
        collapsable={collapsable}
        collapsed={collapsed}
        expanded={expanded}
      >
        <Box
          /* className={classes.tableContainer} */
          sx={(theme) => ({
            flex: '1 1 auto',
            display: 'flex',
            flexFlow: 'column nowrap',
            minHeight: theme.spacing(37),
            margin: theme.spacing(0),
            marginBottom: theme.spacing(-1),
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            whiteSpace: 'nowrap',      
          })}

          ref={(multiGridContainer) => {
            const reference = this.reference;

            reference.multiGridContainer = multiGridContainer;
          }}
        >
          <AutoSizer onResize={this.onResize}>
            {({ height, width }) => (
              <div style={{width: width, height: height}}>
                <ArrowKeyStepper
                  mode={'cells'}
                  isControlled={true}
                  rowCount={rowCount}
                  columnCount={columnCount}
                  scrollToRow={cursorRowIndex}
                  scrollToColumn={cursorColumnIndex}
                  onScrollToChange={this.onScrollToChange}
                >
                  {({onSectionRendered, scrollToColumn, scrollToRow}) => (

                    <MultiGrid
                      width={width}
                      height={height}

                      rowHeight={this.getRowHeight()}
                      rowCount={rowCount}
                      columnWidth={this.getColumnWidth()}
                      columnCount={columnCount}

                      fixedRowCount={fixedRowCount}
                      fixedColumnCount={fixedColumnCount}
                      scrollToRow={cursorRowIndex}
                      scrollToColumn={cursorColumnIndex}

                      cellRenderer={this.renderCell}

                      styleBottomRightGrid={{outline: 'none'}}
                      onSectionRendered={onSectionRendered}

                      ref={(multiGrid) => {this.onRef(multiGrid);}}

                      {...otherProps}
                    />
                  )}
                </ArrowKeyStepper>
              </div>
            )}
          </AutoSizer>
        </Box>
      </Group>
    );
  }

  /* prettier-ignore */
  renderToolbar() {
    const props = this.props;
//    const classes = props.classes;
    const toolbar = props.toolbar;
    const name = props.name;
    const state = this.state;
    const searchString = state.searchString;
    const searchStringIsEmpty = Validation.isEmpty(searchString);
    const searchChipIsVisible = (searchStringIsEmpty === false);
    const searchChip = (
      <Chip
        name={name + '_searchChip'}
        /* className={classes.searchChip} */
        
        sx={(theme) => ({
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),        
        })}


        variant='outlined'
        icon={<SearchIcon/>}
        label={
          <Box
            /* className={classes.searchChipLabel} */
            sx={(theme) => ({
              maxWidth: theme.spacing(8),
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              direction: 'rtl',
            })}
          >
            {searchString}&lrm;
          </Box>
        }
        onClick={this.onSearchChipClick}
        onDelete={this.onSearchChipClose}
      />
    );

    return (
      <Box
        /* className={classes.tableToolbarContainer} */
        sx={(theme) => ({
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        })}
      >
        {(searchChipIsVisible) && (searchChip)}
        {toolbar}
      </Box>
    );
  }

  /* prettier-ignore */
  renderMenu() {
    var menuArray = [
      // <MenuItem enabled={false} icon={<SearchIcon/>} text='Localizar'/>,
    ];

    const props = this.props;
    const menu = props.menu;
    const menuIsDefined = Validation.isDefined(menu);

    if (menuIsDefined) {
      const menuIsArray = Validation.isArray(menu);

      menuArray = [
        ...menuArray,
        ...(menuIsArray ? menu : [menu]),
      ];
    }

    return menuArray;
  }

  /* prettier-ignore */
  renderCell(parameters) {
    const {
      key,
      rowIndex,
      columnIndex,
      isVisible,
    } = parameters;

    if (isVisible) {
      const state = this.state;
      const columnArray = state.columnArray;
      const column = columnArray[columnIndex];

      const props = this.props;
      const fixedRowCount = props.fixedRowCount;
      const rowIsFixed = (rowIndex < fixedRowCount);

      if (rowIsFixed) {
        const props = {
          ...parameters,
          table: this,
          column: column,
          keyValue: key,
          onClick: ((event) => {this.onClick(rowIndex, columnIndex);}),
        }

        const columnFixedCellRenderer = column.fixedCellRenderer;
        const columnFixedCellRendererIsFunction = Validation.isFunction(columnFixedCellRenderer);

        if (columnFixedCellRendererIsFunction) {
          return columnFixedCellRenderer(props);
        }
      } else {
        const recordIndex = rowIndex - fixedRowCount;
        const props = {
          ...parameters,
          keyValue: key,
          table: this,
          column: column,
          recordIndex: recordIndex,
          onClick: ((event) => {this.onClick(rowIndex, columnIndex);}),
        }

        const columnCellRenderer = column.cellRenderer;
        const columnCellRendererIsFunction = Validation.isFunction(columnCellRenderer);

        if (columnCellRendererIsFunction) {
          return columnCellRenderer(props);
        }
      }
    }

    return null;
  }
}

// export default withStyles(styles, {withTheme: true})(Table);
export default Table;
