import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import Validation from "../../mui-v4/validation/Validation";
import { showError } from "../AppMessage";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      as_associado: PropTypes.number,
      as_entidade: PropTypes.number,
      as_entidade_nome: PropTypes.string,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const recordIsNew = Validation.isDefined(parameterMap.as_associado) ? false : true;
    const componentFocused = "as_nome";

    this.state = {
      ...this.state,
      record: {},
      recordIsNew: recordIsNew,
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onClose = this.onClose.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    try {
      super.onOpen();

      const props = this.props;
      const state = this.state;
      const recordIsNew = state.recordIsNew;

      const url = recordIsNew ? "/associado/editar/criar" : "/associado/editar/carregar";
      const parameterMap = props.parameterMap;
      const response = await this.fetch(url, parameterMap);
      const responseRecord = response.record;

      this.setState((state, props) => {
        return {
          ...state,
          record: responseRecord,
        };
      });
    } catch (error) {
      showError(this, error, (appMessage) => {
        this.close();
      });
    }
  }

  onFechar() {
    this.close();
  }

  async onClose() {
    try {
      super.onClose();

      const url = "/associado/editar/encerrar";
      const parameterMap = {};

      await this.fetch(url, parameterMap);

      return true;
    } catch (error) {
      showError(this, error);

      return false;
    }
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordIsNew = state.recordIsNew;

    return (
      <ProgramLayout
        program={this}
        width="480px"
        height="minimum"
      >
        <Header
          avatar="P"
          caption={(recordIsNew ? "Adicionar associado" : "Editar associado")}
          description={"Editor de associado"}
          toolbar={
            <Menu.IconButton name='fechar' icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <Group
          name="associado"
          caption="associado"
          expanded={false}
          collapsable={false}
          collapsed={false}
          toolbar={null}
          menu={null}
        >
          <Field.List>
            <Field.List.Row>
              <Field.Number record={record} name="as_associado" label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_nome" label="*Nome do Associado" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_matricula" label="Matricula" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_CPFcnpj" label="*CPF e/ou cnpj" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_email" label="*Email" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Password record={record} name="as_senha" label="*Senha" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Date name="as_validadeSenha" record={record} label='*Validade da Senha' prefix="" onChange={this.onAtualizar} disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_RG" label="C.Identidade" disabled={true}/>
              <Field.Date name="as_dataNascimento" record={record} label='D. Nascimento' prefix="" onChange={this.onAtualizar} disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/sexo/referenciar"}
                urlParameterMap={{}}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="sx_sexo" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="sx_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_sexo" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_sexo_descricao" record={record} label="*Sexo" disabled={true}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/unidadeFederacao/referenciar"}
                urlParameterMap={{}}
                onChange={() => {
                  this.setState((state, props) => {
                    return ({
                      ...state,
                      record: {
                        ...state.record,
                        as_municipio: null,
                        as_municipio_nome: null,
                      },
                    });
                  });
                }}
                columnArray={[
                  (<Table.ColumnNumber name="uf_unidadeFederacao" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="uf_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_unidadeFederacao" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_unidadeFederacao_nome" record={record} label="Unidade da Federação " disabled={true}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/municipio/referenciar"}
                urlParameterMap={() => {
                  return {
                    mu_unidadeFederacao: record.as_unidadeFederacao,
                  };
                }}
                onChange={null}
                columnArray={[
                  (<Table.ColumnNumber name="mu_municipio" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="mu_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_municipio" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_municipio_nome" record={record} label="*Município " disabled={true}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_endereco" label="Endereço" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_enderecoNumero" label="Numero" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_bairro" label="Bairro" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_CEP" label="CEP" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_telefoneFixo" label="Telefone Fixo" disabled={true}/>
              <Field.String record={record} name="as_telefoneCelular" label="Telefone Celular" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Number record={record} name="as_CR" label="Numero CR" prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
              <Field.Date name="as_validadeCR" record={record} label='Validade do CR' prefix="" onChange={this.onAtualizar} disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_SFPC" label="SFPC" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Date name="as_dataFiliacao" record={record} label='*Filiação' prefix="" onChange={this.onAtualizar} disabled={true}/>
              <Field.Date name="as_validadeFiliacao" record={record} label='*Validade da Filiação' prefix="" onChange={this.onAtualizar} disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.String record={record} name="as_observacao" label="Observações" disabled={true}/>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/tipoAssociado/referenciar"}
                urlParameterMap={{}}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="tas_tipoAssociado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="tas_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_tipoAssociado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_tipoAssociado_descricao" record={record} label="*Tipo do Associado " disabled={true}/>
              </Field.Reference>
            </Field.List.Row>

            <Field.List.Row>
              <Field.Reference
                url={"/tabelaContribuicao/referenciar"}
                urlParameterMap={() => {
                  return {
                    tbc_entidade: this.context.app.state.vp_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="tbc_tabelaContribuicao" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="tbc_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_tabelaContribuicao" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_tabelaContribuicao_descricao" record={record} label="*Tabela de Contribuição " disabled={true}/>
              </Field.Reference>
            </Field.List.Row>

            <Field.List.Row>
              <Field.Reference
                url={"/formaPagamento/referenciar"}
                urlParameterMap={{}}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="fp_formaPagamento" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="fp_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_formaPagamento" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_formaPagamento_descricao" record={record} label="*Forma de Pagamento " disabled={true}/>
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/associado/referenciar"}
                urlParameterMap={() => {
                  return {
                    as_entidade: record.as_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[
                  (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                  (<Table.ColumnString name="as_nome" label="Associado Agrupador" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                ]}
              >
                <Field.Input.Number name="as_associadoAgrupador" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0} disabled={true}/>
                <Field.Input.String name="as_associadoAgrupador_nome" record={record} label="Associado Agrupador " disabled={true}/>
              </Field.Reference>
            </Field.List.Row>

          </Field.List>
          <Field.List.Row>
            <Field.String record={record} name="as_ativo_descricao" label="Ativo" disabled={true}/>
          </Field.List.Row>
        </Group>
        <ButtonBar
          rightButtonList={[
            <Button name="voltar" variant="contained" size="small" onClick={this.onFechar}>Voltar</Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
