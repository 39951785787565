import React from 'react';
import Validation from '../validation/Validation';
import TableCellHeaderString from './TableCellHeaderString';
import TableCellDate from './TableCellDate';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';
import format from 'date-fns/format';
import parse from 'date-fns/parse'

const cellRenderer = ((props) => {
  return (
    <TableCellDate align='left'{...props}/>
  );
});

const fixedCellRenderer = ((props) => {
  return (
    <TableCellHeaderString align='left' {...props}/>
  );
});

const sort = (({
    column,
    record1,
    record2,
  }) => {

  const row1 = record1;
  const row2 = record2;

  const columnName = column.name;
  const value1 = row1[columnName];
  const value2 = row2[columnName];
  const value1IsDateString = Validation.isDateString(value1);
  const value2IsDateString = Validation.isDateString(value2);

  if (!(value1IsDateString)) {
    return -1;
  }
  if (!(value2IsDateString)) {
    return 1;
  }

  return value1.localeCompare(value2, 'pt-br');
});

const search = (({
    column,
    record,
    searchString,
  }) => {

  const row = record;
  const columnName = column.name;
  const value = row[columnName];

  const valueIsDateString = Validation.isDateString(value);
  const valueParsed = (valueIsDateString ? parse(value, 'yyyy-MM-dd', new Date()) : null);
  const valueFormatted = (valueIsDateString ? format(valueParsed, 'dd/MM/yyyy') : '');
  const valueString = valueFormatted;

  const valueStringLowerCase = valueString.toLowerCase();
  const searchStringLowerCase = searchString.toLowerCase();

  return (valueStringLowerCase.indexOf(searchStringLowerCase) !== -1);
});

class TableColumnDate extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    cellWidth: PropTypes.number.isRequired,
    cellHeight: PropTypes.number.isRequired,
    cellRenderer: PropTypes.func.isRequired,
    fixedCellWidth: PropTypes.number.isRequired,
    fixedCellHeight: PropTypes.number.isRequired,
    fixedCellRenderer: PropTypes.func.isRequired,
    sort: PropTypes.func,
    search: PropTypes.func,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    label: null,
    expanded: null,
    cellWidth: null,
    cellHeight: null,
    cellRenderer: cellRenderer,
    fixedCellWidth: null,
    fixedCellHeight: null,
    fixedCellRenderer: fixedCellRenderer,
    sort: sort,
    search: search,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  render() {
    return null;
  }

}

export default TableColumnDate;
