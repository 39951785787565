import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { showError } from "../AppMessage";
import AppMessage from "../AppMessage";
import format from "date-fns/format";
import addDays from "date-fns/addDays";

const Modo = {
  consulta: "consulta",
  alteracao: "alteracao",
};

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const vencimentoPara = format(addDays(new Date(), +7), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        du_entidade: this.context.app.state.vp_entidade,
        du_entidade_nome: this.context.app.state.vp_entidadeNome,
        du_associado: null,
        du_associado_nome: null,
        du_tipoAssociado: null,
        du_tipoAssociado_nome: null,
        du_tabelaContribuicao: null,
        du_tabelaContribuicao_nome: null,
        du_valor: null,
        du_observacao: null,
        du_vencimentoPara: vencimentoPara,
        du_banco: null,
        du_banco_nome: null,
      },
      recordArray: [],
      modo: Modo.consulta,
      componentFocused: "null",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onCancelar = this.onCancelar.bind(this);
    this.onSalvar = this.onSalvar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.du_entidade !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  onEditar() {
    this.setState((state, props) => {
      return {
        ...state,
        modo: Modo.alteracao,
      };
    });
  }

  onCancelar() {
    this.setState((state, props) => {
      return {
        ...state,
        modo: Modo.consulta,
      };
    });
  }

  async onSalvar() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;
      const recordArraySelected = recordArray.filter((record) => {
        return record.bSelected === true;
      });

      const parameterMap = { recordArray: recordArraySelected };
      await this.fetch("/gerarDuplicata/listar/alterar", parameterMap);

      // this.showSnack("As alterações foram salvas", 2000);

      this.openDialog(
        <AppMessage
          caption="Mensagem"
          messageList={["As Duplicatas foram criadas."]}
          defaultButtonLabel="Ok"
          leftButtonLabelList={[]}
          rightButtonLabelList={["Ok"]}
          onClose={() => {
            this.close();
          }}
        />
      );
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/gerarDuplicata/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="E" caption={"Gerar Duplicata do Associado"} description={"Gera Duplicata do Associado"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="as_associado" label="Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="as_nome" label="Nome Associado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="du_associado" record={record} label="Associado" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="du_associado_nome" record={record} label="Nome Associado" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tipoAssociado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="tas_tipoAssociado" label="Tipo Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="tas_nome" label="Tipo do Associado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="du_tipoAssociado" record={record} label="Tipo Associado" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="du_tipoAssociado_nome" record={record} label="Tipo do Associado" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tabelaContribuicao/referenciar"}
                    urlParameterMap={() => {
                      return {
                        tbc_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="tbc_tabelaContribuicao" label="Codigo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="tbc_nome" label="Tabela Contribuição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="du_tabelaContribuicao" record={record} label="Codigo" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="du_tabelaContribuicao_nome" record={record} label="Tabela Contribuição" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/banco/referenciar"}
                    urlParameterMap={() => {
                      return {
                        ba_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="ba_banco" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ba_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="du_banco" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={false} />
                    <Field.Input.String name="du_banco_nome" record={record} label="Banco de Cobrança" disabled={false} />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.String record={record} name="du_observacao" label="Observções Para Novas Duplicatas ..." onChange={this.onAtualizar} />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Date name="du_vencimentoPara" record={record} label="Data Vencimento P/ Novas Duplicatas..." prefix="" onChange={this.onAtualizar} />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            {this.state.modo === Modo.consulta && (
              <Table
                name="tableConsulta"
                caption="Lista das Duplicatas"
                collapsable={false}
                collapsed={false}
                expanded={true}
                recordArray={recordArray}
                selectionType={this.state.modo === Modo.consulta ? "none" : "multiple"}
                selectionColumnName="bSelected"
                toolbar={
                  recordArray.length === 0 ? null : (
                    <React.Fragment>
                      <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                    </React.Fragment>
                  )
                }
                menu={null}
                columnArray={[
                  <Table.ColumnNumber name="du_associado" label="Associado" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                  <Table.ColumnString name="du_associado_nome" label="Nome Associado" expanded={true} cellWidth={250} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_tipoAssociado_nome" label="Tipo Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_tabelaContribuicao_nome" label="Tabela Contribuição" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnNumber name="du_valor" label="Valor" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                  <Table.ColumnDate name="du_vencimentoPara" label="Vencimento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_observacao" label="Observacao" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_banco_nome" label="Banco de Cobrança" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                ]}
                fixedRowCount={1}
                fixedColumnCount={1}
              />
            )}
            {this.state.modo === Modo.alteracao && (
              <Table
                name="table"
                caption="Lista das Duplicatas"
                collapsable={false}
                collapsed={false}
                expanded={true}
                recordArray={recordArray}
                selectionType="multiple"
                selectionColumnName="bSelected"
                toolbar={
                  <React.Fragment>
                    <Menu.IconButton name="salvar" icon={<SaveIcon />} text="Salvar" onClick={this.onSalvar} />
                    <Menu.IconButton name="cancelar" icon={<CloseIcon />} text="Cancelar" onClick={this.onCancelar} />
                  </React.Fragment>
                }
                menu={null}
                columnArray={[
                  <Table.ColumnNumber name="du_associado" label="Associado" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                  <Table.ColumnString name="du_associado_nome" label="Nome Associado" expanded={true} cellWidth={250} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_tipoAssociado_nome" label="Tipo Associado" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_tabelaContribuicao_nome" label="Tabela Contribuição" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnNumber name="du_valor" label="Valor" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={70} fixedCellHeight={56} thousandSeparator="" decimalScale={2} />,
                  <Table.ColumnDate name="du_vencimentoPara" label="Vencimento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_observacao" label="Observacao" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                  <Table.ColumnString name="du_banco_nome" label="Banco de Cobrança" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                ]}
                fixedRowCount={1}
                fixedColumnCount={1}
              />
            )}
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
