import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import { showError } from "../AppMessage";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        pca_entidade: this.context.app.state.vp_entidade,
        pca_entidade_nome: this.context.app.state.vp_entidadeNome,
        pca_campeonato: null,
        pca_campeonato_nome: null,
        pca_campeonatoModalidade: null,
        pca_modalidade_nome: null,
        pca_campeonatoClasse: null,
        pca_classe_nome: null,
        pca_premiacaoCampeonato: null,
        pca_nome: null,
        pca_numero: null,
        pca_premiacaoEtapaOuFinal: null,
        pca_premiacaoIndividualOuEquipe: null,
        pca_ativo: "S",
        pca_ativo_descricao: "Sim",
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onAdicionar = this.onAdicionar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.pca_entidade !== null && record.pca_campeonato !== null && record.pca_campeonatoModalidade !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onAdicionar() {
    const state = this.state;
    const stateRecord = state.record;
    const record = {
      pca_premiacaoCampeonato: null,
      pca_entidade: stateRecord.pca_entidade,
      pca_entidade_nome: stateRecord.pca_entidade_nome,
      pca_campeonato: stateRecord.pca_campeonato,
      pca_campeonato_nome: stateRecord.pca_campeonato_nome,
      pca_campeonatoModalidade: stateRecord.pca_campeonatoModalidade,
      pca_modalidade_nome: stateRecord.pca_modalidade_nome,
      pca_campeonatoClasse: stateRecord.pca_campeonatoClasse,
      pca_classe_nome: stateRecord.pca_classe_nome,
    };
    this.openDialog(
      <Editar
        parameterMap={record}
        onClose={async (editar) => {
          try {
            const editarState = editar.state;
            const editarSuccess = editarState.success;
            const editarResult = editarState.result;

            if (editarSuccess === true) {
              const editarRecord = await this.getRecord(editarResult);
              const state = this.state;
              const recordArray = state.recordArray;

              recordArray.splice(0, 0, editarRecord);

              this.setState((state, props) => {
                return {
                  ...state,
                  recordArray: recordArray,
                };
              });
            }
          } catch (error) {
            showError(this, error);
          }
        }}
      />
    );
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const record = tableSelectionArray[0];

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;
              const editarResult = editarState.result;

              if (editarSuccess === true) {
                const state = this.state;
                const recordArray = state.recordArray;
                let recordArrayNew = [];

                if (editarResult === null) {
                  recordArrayNew = recordArray.filter((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record !== recordArrayRecord;
                  });
                } else {
                  const editarRecord = await this.getRecord(editarResult);
                  const recordNew = {
                    ...record,
                    ...editarRecord,
                  };

                  recordArrayNew = recordArray.map((recordArrayRecord, recordArrayIndex, recordArray) => {
                    return record === recordArrayRecord ? recordNew : recordArrayRecord;
                  });
                }

                this.setState((state, props) => {
                  return {
                    ...state,
                    recordArray: recordArrayNew,
                  };
                });
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/premiacaoCampeonato/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length > 0) {
      const record = recordArray[0];

      return record;
    } else {
      throw new Error("Não foi possível carregar o registro");
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout program={this} width="1280px" height="maximum">
        <Header avatar="P" caption={"Cadastro de Etapa"} description={"Adiciona, edita e exclui Premiação Campeonato "} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonato/referenciar"}
                    urlParameterMap={() => {
                      return {
                        ca_entidade: this.context.app.state.vp_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="pca_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="pca_campeonato_nome" record={record} label="Campeonato " />
                  </Field.Reference>
                </Field.List.Row>

                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonatoModalidade/referenciar"}
                    urlParameterMap={() => {
                      return {
                        cam_entidade: record.pca_entidade,
                        cam_campeonato: record.pca_campeonato,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="cam_campeonatoModalidade" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="mo_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="pca_campeonatoModalidade" record={record} label="Modalidade" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="pca_modalidade_nome" record={record} label="Nome Modalidade" />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/campeonatoClasse/referenciar"}
                    urlParameterMap={() => {
                      return {
                        cac_entidade: record.pca_entidade,
                        cac_campeonato: record.pca_campeonato,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[<Table.ColumnNumber name="cac_campeonatoClasse" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="cs_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
                  >
                    <Field.Input.Number name="pca_campeonatoClasse" record={record} label="Classe" prefix="" thousandSeparator="" decimalScale={0} />
                    <Field.Input.String name="pca_classe_nome" record={record} label="Nome Classe" />
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="pca_premiacaoEtapaOuFinal"
                    caption="pca_premiacaoEtapaOuFinal_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "premiacaoCampeonato",
                      flg_campo: "pca_premiacaoEtapaOuFinal",
                    }}
                    label="Premiação (Etapa ou Final)"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="pca_premiacaoIndividualOuEquipe"
                    caption="pca_premiacaoIndividualOuEquipe_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "premiacaoCampeonato",
                      flg_campo: "pca_premiacaoIndividualOuEquipe",
                    }}
                    label="Premiação (Individual ou Equipe)"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="pca_ativo"
                    caption="pca_ativo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "premiacaoCampeonato",
                      flg_campo: "pca_ativo",
                    }}
                    label="Ativo"
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista Premiação Campeonato"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="adicionar" icon={<AddIcon />} text="Adicionar" onClick={this.onAdicionar} />
                  <Menu.IconButton name="editar" icon={<EditIcon />} text="Editar" onClick={this.onEditar} />
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                <Table.ColumnString name="pca_campeonato_abreviacao" label="Campeonato" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="pca_modalidade_abreviacao" label="Modalidade" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="pca_classe_nome" label="Classe" expanded={true} cellWidth={60} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnNumber name="pca_classificacao" label="Classif" expanded={false} cellWidth={60} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />,
                <Table.ColumnString name="pca_nome" label="Premio" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />,
                <Table.ColumnString name="pca_premiacaoEtapaOuFinal_descricao" label="Premiação Etapa ou Final" expanded={true} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="pca_premiacaoIndividualOuEquipe_descricao" label="Premiação Individual ou Equipe" expanded={true} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
                <Table.ColumnString name="pca_ativo_descricao" label="Ativo" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} />,
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
