import React from 'react';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';
import ProgramManager from '../programManager/ProgramManager';

class AppBody extends React.Component {

  static propTypes = {
    programList: PropTypes.arrayOf(PropTypes.element),
    programDialogList: PropTypes.arrayOf(PropTypes.element),
    // children: AirbnbPropTypes.empty(),
  }

  static defaultProps = {
    programList: null,
    programDialogList: null,
    children: null,
  }

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <ProgramManager {...otherProps}/>
    );
  }

}

export default AppBody;
