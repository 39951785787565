import React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    headerAvatar: {
      backgroundColor: theme.palette.primary.light,
    },
  };
});
*/

class HeaderAvatar extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      children,
      ...otherProps
    } = this.props;

    return (
      <Avatar
        /* className={classes.headerAvatar} */
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.light,
        })}
        {...otherProps}
      >
        {children}
      </Avatar>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(HeaderAvatar);
export default HeaderAvatar;
