import React from "react";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    groupBody: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
  };
});
*/

class GroupBody extends React.Component {
  static propTypes = {
    collapsed: PropTypes.bool.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    collapsed: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      collapsed,
      children,
      ...otherProps
    } = this.props;

    return (
      <Collapse
        // className={classes.groupBody}
        sx={(theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'column nowrap',    

          "& .MuiCollapse-wrapper": {
            flex: '1 1 auto',
            display: 'flex',
            flexFlow: 'column nowrap',
          },

          ".MuiCollapse-wrapperInner": {
            flex: '1 1 auto',
            display: 'flex',
            flexFlow: 'column nowrap',
          },
        })}
        /*
        classes={{
          wrapper: classes.groupBody,
          wrapperInner: classes.groupBody,
        }}
        */
        in={(!(collapsed))}
        {...otherProps}
      >
        {children}
      </Collapse>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(GroupBody);
export default GroupBody;
