import React from "react";
import PropTypes from "prop-types";
import TableCellAlign from "../../mui-v4/table/TableCellAlign";
import TableCell from "./TableCell";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
//import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    tableCellCheckBoxLeft: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    tableCellCheckBoxCenter: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableCellCheckBoxRight: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    tableCellCheckBoxIcon: {
      color: theme.palette.text.secondary,
    },
  };
});
*/

class TableCellCheckBox extends React.Component {
  static propTypes = {
    parent: PropTypes.any.isRequired,
    isVisible: PropTypes.any.isRequired,
    isScrolling: PropTypes.any.isRequired,
    rowIndex: PropTypes.any.isRequired,
    columnIndex: PropTypes.any.isRequired,
    key: PropTypes.any,
    style: PropTypes.any.isRequired,
    keyValue: PropTypes.any.isRequired,
    table: PropTypes.object.isRequired,
    recordIndex: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    align: PropTypes.oneOf(Object.values(TableCellAlign)).isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    parent: null,
    isVisible: null,
    isScrolling: null,
    rowIndex: null,
    columnIndex: null,
    key: null,
    style: null,
    keyValue: null,
    table: null,
    recordIndex: null,
    onClick: null,
    align: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      parent,
      isVisible,
      isScrolling,
      rowIndex,
      columnIndex,
      key,
      style,
      keyValue,
      table,
      recordIndex,
      onClick,
      align,
      children,
      ...otherProps
    } = this.props;

    const props = table.props;
    const recordArray = props.recordArray;
    const record = recordArray[recordIndex];
    const state = table.state;
    const columnArray = state.columnArray;
    const column = columnArray[columnIndex];
    const columnName = column.name;
    const value = record[columnName]
    const valueBoolean = (value === true ? true : false);

    var className = null;

    switch (align) {
      case TableCellAlign.Left:
        // className = classes.tableCellCheckBoxLeft;
        className = (theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        });
        break;
      case TableCellAlign.Center:
        // className = classes.tableCellCheckBoxCenter;
        className = (theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        });
        break;
      case TableCellAlign.Right:
        // className = classes.tableCellCheckBoxRight;
          className = (theme) => ({
          flex: '1 1 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        });
        break;
      default:
        className = null;
        break;
    }

    return (
      <TableCell
        parent={parent}
        isVisible={isVisible}
        isScrolling={isScrolling}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        key={keyValue}
        style={style}
        table={table}
        keyValue={keyValue}
        onClick={onClick}
        {...otherProps}
      >
        <Box className={className}>
          {valueBoolean === true && <CheckBoxIcon             /* className={classes.tableCellCheckBoxIcon} */ sx={(theme) => ({color: theme.palette.text.secondary,})}/>}
          {valueBoolean !== true && <CheckBoxOutlineBlankIcon /* className={classes.tableCellCheckBoxIcon} */ sx={(theme) => ({color: theme.palette.text.secondary,})}/>}
        </Box>
      </TableCell>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(TableCellCheckBox);
export default TableCellCheckBox;
