import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Validation from "../../mui-v4/validation/Validation";
import Table from "../../mui-v4/table/Table";
import format from "date-fns/format";
// import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import print from "print-js";

import { showError } from "../AppMessage";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const dataDiaSeguinte = format(addDays(new Date(), +1), "yyyy-MM-dd");
    // const dataInicial = format(addMonths(new Date(), -1), "yyyy-MM-dd");
    // const dataFinal = format(addMonths(new Date(), +1), "yyyy-MM-dd");

    this.state = {
      ...this.state,
      record: {
        du_duplicata: null,
        du_entidade: this.context.app.state.vp_entidade,
        du_entidade_nome: this.context.app.state.vp_entidadeNome,
        du_associado: null,
        du_associado_nome: null,
        as_ativo: "S",
        as_ativo_descricao: "Sim",
        as_formaPagamento: 4,
        as_formaPagamento_nome: "PIX",
        du_dataEmissaoInicial: null,
        du_dataEmissaoFinal: null,
        du_dataVencimentoInicial: null,
        du_dataVencimentoFinal: null,
        du_valorInicial: null,
        du_valorFinal: null,
        du_calcularJuros: "S",
        du_calcularJuros_descricao: "Sim",
        du_novoVencimento: dataDiaSeguinte,
        du_reenviarPix: "N",
        du_reenviarPix_descricao: "Não",
      },
      recordArray: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.onEnviar = this.onEnviar.bind(this);
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArrayNew = [];

      if (record.du_entidade !== null) {
        recordArrayNew = await this.getRecordArray(record);
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArrayNew,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  onFechar() {
    this.close();
  }

  async onImprimir() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray)) {
        const recordArraySelected = recordArray.filter((record) => {
          return record.bSelected === true;
        });
        const parameterMap = { recordList: recordArraySelected };
        const response = await this.fetch("/enviarCobrancaPIX/listar/carregarPDF", parameterMap);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecione um Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  //
  //  async onEnviar() {
  //    try {
  //      const state = this.state;
  //      const record = state.record;
  //      const recordArray = state.recordArray;

  //      if (Validation.isArray(recordArray) && recordArray.length > 0) {
  //        const response = await this.fetch("/enviarCobrancaPIX/listar/enviarPDF", record);

  //        this.showSnack("E-mail enviado com sucesso", 2000);
  //      } else {
  //        showError(this, "Selecione um Associado");
  //      }
  //    } catch (error) {
  //      showError(this, error, (appMessage) => {});
  //    }
  //  }
  async onEnviar() {
    try {
      const state = this.state;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray)) {
        const recordArraySelected = recordArray.filter((record) => {
          return record.bSelected === true;
        });
        const parameterMap = { recordList: recordArraySelected };

        this.showSnack("Aguarde a finalização do processo", 2000);

        await this.fetch("/enviarCobrancaPIX/listar/enviarPDF", parameterMap);

        showError(this, "E-mail enviado com sucesso", null);
      } else {
        showError(this, "Selecione um Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  async getRecordArray(parameterMap) {
    const response = await this.fetch("/enviarCobrancaPIX/listar/carregar", parameterMap);
    const recordArray = response.recordArray;

    return recordArray;
  }

  async getRecord(parameterMap) {
    const recordArray = await this.getRecordArray(parameterMap);

    if (recordArray.length === 1) {
      const record = recordArray[0];

      return record;
    } else {
      if (recordArray.length > 0) {
        throw new Error("Foi encontrado mais de 1 registro");
      } else {
        throw new Error("Não foi possível carregar o registro");
      }
    }
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;

    return (
      <ProgramLayout
        program={this}
        width="maximum"
        height="maximum"
      >
        <Header
          avatar="P"
          caption={"Enviar Cobrança via PIX"}
          description={"Enviar Cobrança via PIX "}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="filtro"
              caption="Filtro de pesquisa"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List.Row>
                <Field.Reference
                  url={"/formaPagamento/referenciar"}
                  urlParameterMap={{}}
                  onChange={this.onAtualizar}
                  columnArray={[
                    (<Table.ColumnNumber name="fp_formaPagamento" label="Codigo" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                    (<Table.ColumnString name="fp_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                  ]}
                  >
                  <Field.Input.Number name="as_formaPagamento" record={record} label='Codigo' prefix="" thousandSeparator="" decimalScale={0}/>
                  <Field.Input.String name="as_formaPagamento_nome" record={record} label="Forma de Pagamento"/>
                </Field.Reference>
              </Field.List.Row>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="as_ativo"
                    caption="as_ativo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "associado",
                      flg_campo: "as_ativo",
                    }}
                    label='Associado Ativo'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={() => {
                      return {
                        as_entidade: record.du_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="as_associado" label="Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="as_nome" label="Nome Associado" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="du_associado" record={record} label='Associado' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="du_associado_nome" record={record} label="Nome Associado"/>
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Date name='du_dataEmissaoInicial' record={record} label='Emissão Inicial' onChange={this.onAtualizar} disabled={false}/>
                    <Field.Input.Date name='du_dataEmissaoFinal' record={record} label='Emissão Final' onChange={this.onAtualizar} disabled={false}/>
                  </Field.Range>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Date name='du_dataVencimentoInicial' record={record} label='Vencto Inicial' onChange={this.onAtualizar} disabled={false}/>
                    <Field.Input.Date name='du_dataVencimentoFinal' record={record} label='Vencto Final' onChange={this.onAtualizar} disabled={false}/>
                  </Field.Range>
                </Field.List.Row>

                <Field.List.Row>
                  <Field.Range>
                    <Field.Input.Number name="du_valorInicial" record={record} label='Valor Inicial' prefix="" thousandSeparator="." decimalScale={2} onChange={this.onAtualizar}/>
                    <Field.Input.Number name="du_valorFinal" record={record} label='Valor Final' prefix="" thousandSeparator="." decimalScale={2} onChange={this.onAtualizar}/>
                  </Field.Range>
                </Field.List.Row>
              </Field.List>                
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="du_reenviarPix"
                    caption="du_reenviarPix_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "todas",
                      flg_campo: "simNao",
                    }}
                    label='ReEnviar QRcode'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="du_calcularJuros"
                    caption="du_calcularJuros_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "todas",
                      flg_campo: "simNao",
                    }}
                    label='Calcular Juros Duplicata Vencida'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Date name="du_novoVencimento" record={record} label='Novo Vencimento para Titulos Vencidos' prefix="" onChange={this.onAtualizar}/>
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Enviar Cobrança via PIX"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="multiple"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="enviar" icon={<ShareIcon/>} text="Enviar via e-Mail" onClick={this.onEnviar}/>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>
                </React.Fragment>
              }
              menu={null}
              columnArray={[
                (<Table.ColumnString name="du_associado_nome" label="Nome Associado" expanded={true} cellWidth={200} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="du_numero" label="Duplicata" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnNumber name="du_parcela" label="Parcela" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnDate name="du_dataEmissao" label="Emissão" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnDate name="du_dataVencimento" label="Vencimento" expanded={false} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="du_saldoLiquido" label="Valor" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnNumber name="du_jurosMulta" label="Multa e Juros" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnNumber name="du_saldo" label="Saldo Corrigido" expanded={false} cellWidth={130} cellHeight={40} fixedCellWidth={130} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnString name="du_situacaoDuplicata" label="Situacão" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="du_chavePIX" label="Chave PIX" expanded={true} cellWidth={120} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={3}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    )
  }
}

export default Listar;
