import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ProgramRow from "../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../mui-v4/program/ProgramColumn";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Editar from "./Editar";
import { showError } from "../AppMessage";
import print from "print-js";
import Validation from "../../mui-v4/validation/Validation";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        as_entidade: this.context.app.state.vp_entidade,
        as_entidade_nome: this.context.app.state.vp_entidadeNome,
        as_associado: null,
        as_nome: null,
        as_matricula: null,
        as_tipoAssociado: null,
        as_tipoAssociado_descricao: null,
        as_associadoAgrupador: null,
        as_associadoAgrupador_nome: null,
        as_tabelaContribuicao: null,
        as_tabelaContribuicao_descricao: null,
        as_ativo: "S",
        as_ativo_descricao: "Sim",
      },
      recordArray: [],
      recordArrayResumo: [],
      componentFocused: "table",
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onAdicionar = this.onAdicionar.bind(this);
    this.onEditar = this.onEditar.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.getResponse = this.getResponse.bind(this);
    /*
    this.getRecordArray = this.getRecordArray.bind(this);
    this.getRecord = this.getRecord.bind(this);
    */
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      let recordArray = [];
      let recordArrayResumo = [];

      if (record.as_entidade !== null) {
        const response = await this.getResponse();

        recordArray = response.recordArray;
        recordArrayResumo = response.recordArrayResumo;
      }

      this.setState((state, props) => {
        return {
          ...state,
          recordArray: recordArray,
          recordArrayResumo: recordArrayResumo,
        };
      });
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordArray = state.recordArray;

      if (Validation.isArray(recordArray) && recordArray.length > 0) {
        const response = await this.fetch("/associado/listar/carregarPDF", record);
        const pdfContent = response.pdfContent;

        print({
          printable: pdfContent,
          type: "pdf",
          base64: true,
        });
      } else {
        showError(this, "Selecionar Associado");
      }
    } catch (error) {
      showError(this, error, (appMessage) => {});
    }
  }

  async onAdicionar() {
    const state = this.state;
    const stateRecord = state.record;
    const record = {
      as_associado: null,
      as_entidade: stateRecord.as_entidade,
      as_entidade_nome: stateRecord.as_entidade_nome,
    };

    this.openDialog(
      <Editar
        parameterMap={record}
        onClose={async (editar) => {
          try {
            const editarState = editar.state;
            const editarSuccess = editarState.success;
            // const editarResult = editarState.result;

            if (editarSuccess === true) {
              await this.onAtualizar();
            }
          } catch (error) {
            showError(this, error);
          }
        }}
      />
    );
  }

  async onEditar() {
    const table = this.getComponent("table");
    const tableSelectionArray = table.getSelectionArray();

    if (tableSelectionArray.length === 1) {
      const record = tableSelectionArray[0];

      this.openDialog(
        <Editar
          parameterMap={record}
          onClose={async (editar) => {
            try {
              const editarState = editar.state;
              const editarSuccess = editarState.success;
              // const editarResult = editarState.result;

              if (editarSuccess === true) {
                await this.onAtualizar();
              }
            } catch (error) {
              showError(this, error);
            }
          }}
        />
      );
    }
  }

  onFechar() {
    this.close();
  }

  async getResponse() {
    const state = this.state;
    const record = state.record;
    const response = await this.fetch("/associado/listar/carregar", record);

    return response;
  }

  /* prettier-ignore */
  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const recordArrayResumo = state.recordArrayResumo;

    return (
      <ProgramLayout
        program={this}
        width="1280px"
        height="maximum"
      >
        <Header
          avatar="P"
          caption={"Cadastro de associado"}
          description={"Adiciona, edita e exclui associado "}
          toolbar={
            <Menu.IconButton name="fechar" icon={<CloseIcon/>} text="Fechar" onClick={this.onFechar}/>
          }
        />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group
              name="filtro"
              caption="Filtro de pesquisa"
              expanded={false}
              collapsable={false}
              collapsed={false}
              toolbar={null}
              menu={null}
            >
              <Field.List>
                <Field.List.Row>
                  <Field.String name="as_nome" record={record} label="Nome" onChange={this.onAtualizar}/>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.String name="as_matricula" record={record} label="Matricula" onChange={this.onAtualizar}/>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tipoAssociado/referenciar"}
                    urlParameterMap={{}}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="tas_tipoAssociado" label="Tipo do Associado" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="tas_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="as_tipoAssociado" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="as_tipoAssociado_descricao" record={record} label="Tipo do Associado"/>
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/associado/referenciar"}
                    urlParameterMap={{}}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="as_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="as_associadoAgrupador" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="as_associadoAgrupador_nome" record={record} label="Associado Agrupador"/>
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Reference
                    url={"/tabelaContribuicao/referenciar"}
                    urlParameterMap={() => {
                      return {
                        tbc_entidade: this.context.app.state.vp_entidade,
                      };
                    }}
                    onChange={this.onAtualizar}
                    columnArray={[
                      (<Table.ColumnNumber name="tbc_tabelaContribuicao" label="Tabela de Contribuicao" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                      (<Table.ColumnString name="tbc_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56}/>),
                    ]}
                  >
                    <Field.Input.Number name="as_tabelaContribuicao" record={record} label='Código' prefix="" thousandSeparator="" decimalScale={0}/>
                    <Field.Input.String name="as_tabelaContribuicao_descricao" record={record} label="Tabela Contribuicao"/>
                  </Field.Reference>
                </Field.List.Row>
              </Field.List>
              <Field.List>
                <Field.List.Row>
                  <Field.Combo
                    name="as_ativo"
                    caption="as_ativo_descricao"
                    record={record}
                    url="/flag/referenciar"
                    urlParameterMap={{
                      flg_tabela: "associado",
                      flg_campo: "as_ativo",
                    }}
                    label='Ativo'
                    onChange={this.onAtualizar}
                  />
                </Field.List.Row>
              </Field.List>
            </Group>
            <Table
              name="tableResumo"
              caption="Resumo"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArrayResumo}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={null}
              menu={null}

              columnArray={[
                (<Table.ColumnNumber name="tas_tipoAssociado" label="Código" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnString name="tas_nome" label="Nome" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="quantidadeAssociado" label="Quantidade" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Lista de associado "
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="single"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="adicionar" icon={<AddIcon/>} text="Adicionar" onClick={this.onAdicionar}/>
                  <Menu.IconButton name="editar" icon={<EditIcon/>} text="Editar" onClick={this.onEditar}/>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon/>} text="Imprimir" onClick={this.onImprimir}/>
                </React.Fragment>
              }
              menu={null}

              columnArray={[
                (<Table.ColumnNumber name="as_associado" label="Código" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0}/>),
                (<Table.ColumnString name="as_nome" label="Nome" expanded={true} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_matricula" label="Matricula" expanded={true} cellWidth={48} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_tabelaContribuicao_descricao" label="Tabela Contribuição" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnNumber name="as_valor" label="Valor" expanded={false} cellWidth={80} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={2}/>),
                (<Table.ColumnString name="as_tipoAssociado_string" label="Tipo Associado " expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_associadoAgrupador_nome" label="Associado Agrupador" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
                (<Table.ColumnString name="as_ativo_descricao" label="Ativo" expanded={false} cellWidth={150} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
              ]}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
