import React from "react";
import PropTypes from "prop-types";
import Validation from "../validation/Validation";
import Paper from "@mui/material/Paper";
// import withStyles from '@mui/styles/withStyles';
// import Box from '@mui/material/Box';

/*
const styles = ((theme) => {
  return {
    groupLayout: {
      flex: '0 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  };
});
*/

class GroupLayout extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    expanded: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  /* prettier-ignore */
  render() {
    const {
      theme,
      classes,
      style,
      expanded,
      children,
      ...otherProps
    } = this.props;

    const childrenArray = React.Children.toArray(children);
    var   childrenCollapsed = true;

    childrenArray.forEach((child) => {
      const childIsValidElement = React.isValidElement(child);

      if (childIsValidElement) {
        const childProps = child.props;
        const childCollapsed = childProps.collapsed;
        const childCollapsedIsDefined = Validation.isDefined(childCollapsed);

        childrenCollapsed = (childrenCollapsed && (!(childCollapsedIsDefined) || (childCollapsed === true)));
      }
    });

    var styleComposed = {
      ...((expanded) && (!(childrenCollapsed)) ? {flex: '1 1 auto'} : {flex: '0 1 auto'}),
      ...style,
    };

    return (
      <Paper
        /* className={classes.groupLayout} */
        /* style={styleComposed} */

        sx={(theme) => ({
          flex: '0 1 auto',
          display: 'flex',
          flexFlow: 'column nowrap',
          margin: theme.spacing(1),
          padding: theme.spacing(1),
          ...styleComposed,
        })}

        {...otherProps}
      >
        {children}
      </Paper>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(GroupLayout);
export default GroupLayout;
