// import AppMenu from "./AppMenu";
import AppMenu from "./AppMenu";

import React from "react";
import Context from "../mui-v4/context/Context";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";

//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";
import AppBase from "../mui-v4/app/App";
//import { withSnackbar } from "notistack";
import { enqueueSnackbar /*, closeSnackbar*/ } from "notistack";

class App extends AppBase {
  constructor(props, context) {
    super(props, context);

    const isDevelopment = window.location.href.startsWith("http://localhost:3000");
    const path = isDevelopment ? "http://localhost:8080/tiro" : "https://www.portaldoatirador.com.br";
    const onBeforeUnloadWarning = isDevelopment ? false : true;

    this.state = {
      ...this.state,
      isDevelopment: isDevelopment,
      path: path,
      onBeforeUnloadWarning: onBeforeUnloadWarning,
      name: "Portal do Atirador",
      version: "",
      mounted: false,

      programList: [<AppMenu />],
      programDialogList: [],
    };

    //this.componentDidMount = this.componentDidMount.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.showSnack = this.showSnack.bind(this);
    this.fetch = this.fetch.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    const state = this.state;
    const isDevelopment = state.isDevelopment;
    const location = window.location;
    const protocol = location.protocol;

    if (isDevelopment === false && protocol !== "https:") {
      console.log("Reloading to use the SSL protocol");

      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    } else {
      this.setState((state, props) => {
        return {
          ...state,
          mounted: true,
        };
      });
    }
  }

  async onBeforeUnload(event) {
    await this.fetch("/session/logout", {});

    super.onBeforeUnload(event);
  }

  showSnack(message, duration = 3000) {
    const parameters = {
      autoHideDuration: duration,
    };
    /*this.props.*/ enqueueSnackbar(message, parameters);
  }

  render() {
    /* avoids render before the https check */
    const state = this.state;
    const mounted = state.mounted;

    if (mounted === false) {
      return null;
    } else {
      const { theme, classes, children, enqueueSnackbar, closeSnackbar, ...otherProps } = this.props;

      const contextValue = this.contextValue;
      const programList = this.state.programList; //[<AppMenu />];
      const programDialogList = this.state.programDialogList; //[];

      return (
        <Context.Provider value={contextValue}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
            <App.Layout {...otherProps}>
              <App.Header />
              <App.Body programList={programList} programDialogList={programDialogList} />
            </App.Layout>
          </LocalizationProvider>
        </Context.Provider>
      );
    }
  }
}

export default App;
// export default withSnackbar(App);
