import React from "react";
import Program from "../../mui-v4/program/Program";
import Context from "../../mui-v4/context/Context";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../mui-v4/program/ProgramLayout";
import Header from "../../mui-v4/header/Header";
import Menu from "../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Group from "../../mui-v4/group/Group";
import Field from "../../mui-v4/field/Field";
import Table from "../../mui-v4/table/Table";
import ButtonBar from "../../mui-v4/button/ButtonBar";
import Button from "../../mui-v4/button/Button";
import { showError } from "../AppMessage";
import print from "print-js";

class Editar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      et_etapa: PropTypes.number,
      et_nome: PropTypes.number,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      record: {
        en_entidade: this.context.app.state.vp_entidade,
        en_entidade_nome: this.context.app.state.vp_entidadeNome,
        us_usuario: this.context.app.state.vp_usuario,
        et_campeonato: null,
        et_campeonato_nome: null,
        et_etapa: null,
        et_etapa_nome: null,
      },
      componentFocused: "et_campeonato",
      success: false,
      result: null,
      ...props.state,
    };

    this.onFechar = this.onFechar.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  onFechar() {
    this.close();
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const response = await this.fetch("/relatorioInscritoEtapa/listar/carregar", { ...record, as_associado: null });
      const pdfContent = response.pdfContent;

      print({
        printable: pdfContent,
        type: "pdf",
        base64: true,
      });

      this.close();
    } catch (error) {
      showError(this, error);
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    console.log(record);
    return (
      <ProgramLayout program={this} width="480px" height="minimum">
        <Header avatar="P" caption={"Relatório das Inscriçoes da Etapa"} description={"Editor de Relatório"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <Group name="Inscritos da Etapa" caption="Relatorio" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
          <Field.List>
            <Field.List.Row>
              <Field.Reference
                url={"/campeonato/referenciar"}
                urlParameterMap={() => {
                  return {
                    ca_entidade: record.en_entidade,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="et_campeonato" record={record} label="Campeonato" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="et_campeonato_nome" record={record} label="Nome Campeonato" />
              </Field.Reference>
            </Field.List.Row>
            <Field.List.Row>
              <Field.Reference
                url={"/etapa/referenciarSecretaria"}
                urlParameterMap={() => {
                  return {
                    et_campeonato: record.et_campeonato,
                    et_usuario: record.us_usuario,
                  };
                }}
                onChange={this.onAtualizar}
                columnArray={[<Table.ColumnNumber name="et_etapa" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="et_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}
              >
                <Field.Input.Number name="et_etapa" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} />
                <Field.Input.String name="et_etapa_nome" record={record} label="Etapa " />
              </Field.Reference>
            </Field.List.Row>
          </Field.List>
        </Group>
        <ButtonBar
          leftButtonList={[]}
          rightButtonList={[
            <Button name="cancelar" variant="contained" size="small" onClick={this.onFechar}>
              Cancelar
            </Button>,
            <Button name="salvar" variant="contained" size="small" onClick={this.onImprimir}>
              Gerar Relatório
            </Button>,
          ]}
        />
      </ProgramLayout>
    );
  }
}

export default Editar;
